import React, { useState, useEffect } from "react";

export default function AddTable(props) {
    const [seats, setSeats] = useState(5);
    const [xSeats, setxSeats] = useState(4);
    const [ySeats, setySeats] = useState(2);
    const [seatsType, setSeatsType] = useState('');
    const [tableName, setTableName] = useState(`Table 1`);
    const [tableType, setTableType] = useState(`rectangular`);

    useEffect(() => {
        if (tableName.slice(0,6) === 'Table ' && props.plan.objects.tables.length > 0) {
            setTableName(`Table `+(props.plan.objects.tables.length+1));
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible]);

    let classes = "hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
    if (props && props.visible) {
        classes = "flex items-center justify-center h-screen overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full bg-violet-940/20"
    }

    const addTable = () => {
        return props.makeTable({
            tableName: tableName,
            tableType: tableType,
            seatsType: seatsType,
            seats: tableType === 'round' ? seats : [xSeats, ySeats],
          });
    }

    const close = () => {
        return props.close(false)
    }

    return (
        <div id="modalAddTable" tabIndex="-1" aria-hidden="true" className={classes}  style={{maxHeight: '100svh'}}>
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto ">
                <div className="relative rounded-lg shadow bg-white ">
                    <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-violet-950">
                        <h3 className="text-xl font-semibold text-gray-900">
                            Add a table
                        </h3>
                        <button type="button" className="text-violet-940 bg-transparent hover:bg-violet-940 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-violet-940 dark:hover:text-white" onClick={close}>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>  
                        </button>
                    </div>
                    <div className="grid grid-cols-1 gap-6 p-4">
                        <label className="block">
                            <span className="text-gray-700">Name of the table</span>
                            <input type="text" className="
                                mt-1
                                block
                                w-full
                                rounded-md
                                border-gray-300
                                shadow-sm
                                focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                value={tableName}
                                onChange={(e) => setTableName(e.target.value)} />
                        </label>
                        <label className="block">
                            <span className="text-gray-700">Table type</span>
                            <select className="
                                block
                                w-full
                                mt-1
                                rounded-md
                                border-gray-300
                                shadow-sm
                                focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                            "
                            name={`tableType`}
                            defaultValue={tableType}
                            onChange={(e) => setTableType(e.target.value)}>
                                <option value={`rectangular`}>Rectangular</option>
                                <option value={`round`}>Round</option>
                            </select>
                        </label>
                        {props.types && props.types.length > 0 && (
                        <label className="block mr-1">
                                <span className="text-gray-700">Seats type</span>
                                <select className="
                                    block
                                    w-full
                                    mt-1
                                    rounded-md
                                    border-gray-300
                                    shadow-sm
                                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                                "
                                name={`seatsType`}
                                defaultValue={''}
                                onChange={(e) => setSeatsType(e.target.value)}>
                                    <option value={``}>-</option>
                                    {props.types.map((type, index) => (
                                        <option key={`type_${index}`} value={type}>{type}</option>
                                    ))}
                                </select>
                            </label>
                        )}
                        {tableType === 'round' && 
                        <label className="block">
                            <span className="text-gray-700">Seats</span>
                            <input type="number" className="
                                mt-1
                                block
                                w-full
                                rounded-md
                                border-gray-300
                                shadow-sm
                                focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                min={1}
                                max={20}
                                defaultValue={seats}
                                onChange={(e) => setSeats(e.target.value)} />
                        </label>}
                        {tableType === 'rectangular' && 
                        <label className="block">
                            <span className="text-gray-700">{`Seats (horizontal)`}</span>
                            <input type="number" className="
                                mt-1
                                block
                                w-full
                                rounded-md
                                border-gray-300
                                shadow-sm
                                focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                min={0}
                                max={20}
                                defaultValue={xSeats}
                                onChange={(e) => setxSeats(e.target.value)} />
                        </label>}
                        {tableType === 'rectangular' && 
                        <label className="block">
                            <span className="text-gray-700">{`Seats (vertical)`}</span>
                            <input type="number" className="
                                mt-1
                                block
                                w-full
                                rounded-md
                                border-gray-300
                                shadow-sm
                                focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                min={0}
                                max={20}
                                defaultValue={ySeats}
                                onChange={(e) => setySeats(e.target.value)} />
                        </label>}
                    </div>
                    <div className="flex items-center p-6 space-x-2 rounded-b border-t border-violet-950">
                        <button 
                            type="button" 
                            className="text-white bg-violet-940 hover:bg-violet-940 focus:ring-4 focus:outline-none focus:ring-violet-950 font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={addTable}>Create the table</button>
                    </div>
                </div>
            </div>
        </div>
    )

}