import React, { useState, useEffect } from "react";
import { TwitterPicker } from 'react-color';


export default function SeatInfos(props) {
    const [showFillColor, setShowFillColor] = useState(false);
    const [formValues, setFormValues] = useState(props.infos);

    useEffect(() => {
        setFormValues(props.infos)
    }, [props.infos]);

    if (props.infos.type !== 'seat') {
        return null
    }

    const seatInfoUpdate = (data) => {
        return props.onUpdate(data);
    }

    return (
        <div className="overflow-y-auto text-sm overflow-x-hidden absolute w-80 z-40 p-4 bottom-0 left-0 m-10 bg-white rounded-xl border border-gray-300" style={props.styles || {top:0}}>
            <p className="text-center mb-3">
                <strong className="text-4xl">{props.infos.place}</strong>
            </p>
            <ul>
                {props.infos.id &&
                    <li>Id: {props.infos.id}</li>
                }
            </ul>
            <form className="mt-3">
                <label className="block relative mt-2">
                    <span className="absolute block text-gray-700 translate-y-2 pl-1 text-xs">Row name</span>
                    <input type="text" className="
                        pt-7
                        block
                        w-full
                        rounded-md
                        border-gray-300
                        shadow-sm
                        focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        value={formValues.rowName || ''}
                        onChange={(e) => seatInfoUpdate({rowName: e.target.value})} />
                </label>
                <label className="block relative mt-2">
                    <span className="absolute block text-gray-700 translate-y-2 pl-3 text-xs">Col name</span>
                    <input type="text" className="
                        pt-7
                        block
                        w-full
                        rounded-md
                        border-gray-300
                        shadow-sm
                        focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        value={formValues.colName || ''}
                        onChange={(e) => seatInfoUpdate({colName: e.target.value})} />
                </label>
                {props.types && props.types.length > 0 &&
                    <label className="block relative mt-2">
                        <span className="absolute block text-gray-700 translate-y-2 pl-3 text-xs">Type</span>
                        <select className="block
                            pt-7
                            w-full
                            rounded-md
                            border-gray-300
                            shadow-sm
                            focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            name={`type`}
                            value={formValues.typeSeat || ''}
                            onChange={(e) => seatInfoUpdate({typeSeat: e.target.value})}>
                                <option value={''}>-</option>
                            {props.types.map((type, index) => 
                                 (<option key={index} value={type}>{type}</option>))
                            }
                        </select>
                    </label>
                }
                <div className={props.infos.seatStatus && props.infos.seatStatus === 'reserved' ? 'flex mt-2' : 'mt-2'}>
                    <label className="block relative">
                        <span className="absolute block text-gray-700 translate-y-2 pl-3 text-xs">Status</span>
                        <select className="block
                            pt-7
                            w-full
                            rounded-md
                            border-gray-300
                            shadow-sm
                            focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        name={`seatStatus`}
                        value={formValues.seatStatus || 'active'}
                        onChange={(e) => seatInfoUpdate({seatStatus: e.target.value})}>
                            <option value={`active`}>Active</option>
                            <option value={`reserved`}>Reserved</option>
                        </select>
                    </label>
                    {formValues.seatStatus && formValues.seatStatus === 'reserved' && 
                        <div className="pl-2">
                            <span className="absolute block text-gray-700 translate-y-2 pl-3 text-xs">Reason of reservation</span>
                            <input type="text" className="
                                pt-7
                                block
                                w-full
                                rounded-md
                                border-gray-300
                                shadow-sm
                                focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                value={formValues.reservedReason || ''}
                                onChange={(e) => seatInfoUpdate({reservedReason: e.target.value})} />
                        </div>
                    }
                </div>
                {formValues.seatStatus && formValues.seatStatus === 'reserved' && 
                <label className="block relative mt-2">
                    <div className="flex">
                        <div className="h-16 w-20 rounded-lg cursor-pointer border border-gray-300" style={{backgroundColor: props.infos.fillColor || '#d40000'}} onClick={(e) => setShowFillColor(!showFillColor)}></div>
                        <div className="pl-2 w-full">
                            <span className="absolute block text-gray-700 translate-y-2 pl-3 text-xs">Main color</span>
                            <input type="text" className="
                                pt-7
                                block
                                w-full
                                rounded-md
                                border-gray-300
                                shadow-sm
                                focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                defaultValue={formValues.fillColor || '#d40000'}
                                onChange={(e) => e.target.value !== formValues.fillColor ? seatInfoUpdate({fillColor: e.target.value}): false} />
                        </div>
                    </div>
                    <div className={showFillColor ? 'block mt-2' : 'hidden'}>
                        <TwitterPicker color={ formValues.fillColor || '#d40000' } onChange={ (color, event) => seatInfoUpdate({fillColor: color.hex}) } />
                    </div>
                </label>}
            </form>
        </div>
    )

}