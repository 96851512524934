import React, { useState, useEffect, useRef } from "react";
import { Stage, Layer, Group, Image, Rect, Transformer} from 'react-konva';
import Konva from 'konva';
import { zoomHigh, zoomLow, zoomIncrement, seatWidth, seatHeight } from 'constants.js';
import { jsPDF } from "jspdf";
import MarkusDropZone from "components/forms/dropZone";
import Loading from "components/loading";
import HistoryLoading from "components/historyloading";
import AddStand from "components/forms/addStand";
import AddTable from "components/forms/addTable";
import EditTitle from "components/forms/editTitle";
import ShowHelp from "components/infos/showHelp";
import SeatInfo from "components/infos/seatInfos";
import ShowError from "components/infos/showError";
import SeatMultipleInfo from "components/infos/seatMultipleInfo";
import BlockSelected from "components/forms/blockSelected";
import EditPencil from 'assets/editPencil.svg';
import Hand from 'assets/hand.svg';
import StandPlus from 'assets/addStand.svg';
import TablePlus from 'assets/addTable.svg';
import Pencil from 'assets/pencil.png';
import Trash from 'assets/trash.png';
import tableNb from 'assets/tableNb.svg';
import seatNb from 'assets/seatNb.svg';
import Print from 'assets/print.svg';
import ExternalLink from 'assets/externalLink.svg';
import Import from 'assets/import.svg';
import TrashIcon from 'assets/trash.svg';
import BackLinkButton from 'assets/backLinkButton.svg';
import BackButton from 'assets/backButton.svg';
import ForwardButton from 'assets/forwardButton.svg';
import ZoomIn from 'assets/zoomIn.svg';
import ZoomOut from 'assets/zoomOut.svg';
import Center from 'assets/center.svg';
import Stand from 'components/konva/Stand';
import Table from 'components/konva/Table';

import "./App.css";

const canvasContainerId = "#canvas-container";

export default function Editor(props) {
  
  const [ isLoading, setIsLoading ] = useState(true);
  const [ AddStandVisible, setAddStandVisible ] = useState(false);
  const [ AddTableVisible, setAddTableVisible ] = useState(false);
  const [ EditTitleVisible, setEditTitleVisible ] = useState(false);
  const [ errorApi, setErrorApi] = useState(props.errorMessage || false);
  const [ seatEditing, setSeatEditing ] = useState([]);
  const [ seatInfo, setSeatInfo ] = useState(false);
  const [ selectedSeats, setSelectedSeats ] = useState([]);
  const [ groupIdCounter, setGroupIdCounter ] = useState(0);
  const [ documentName, setDocumentName ] = useState('My new plan');
  const [ help, setHelp ] = useState('');
  const [ nbTables, setnbTables ] = useState(0);
  const [ nbSeats, setnbSeats ] = useState(0);
  const [ nbSeatsReserved, setnbSeatsReserved ] = useState(0);
  const [ nbStands, setnbStands ] = useState(0);
  const [ blocksSelected, setBlocksSelected ] = useState(false);
  const [ blockSelected, setBlockSelected ] = useState(false);
  const [ multipleSeatSelected, setMultipleSeatsSelected ] = useState(false);
  const [ multipleSeatDatas, setMultipleSeatsDatas ] = useState({});
  const [ historyBack, setHistoryBack ] = useState([]);
  const [ historyRedo, setHistoryRedo ] = useState([]);
  const [ isHistorying, setIsHistorying ] = useState(false);
  const [ historyMessage, setHistoryMessage ] = useState('');
  const [ zoomLevel, setZoomLevel ] = useState(1);
  const [ tkrefBBox, setTkrefBBox ] = useState(false);
  const [ plan, setPlan ] = useState({objects: {stands: [], tables: []}, title: 'My new plan'});

  var editIcon = Pencil;
  var deleteIcon = Trash;
  var cloneIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAWQAAAFkBqp2phgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAC6SURBVDiN3dMxagJBGAXgT1mwCsRW0qWNmNZC0NpzWOVG6XOAINYRcoEtLLyChegBVrTY2bCZrOOm9cHjhzf//3gzzM9vvKLA+Qq3GNQHupHBI77RaeAMT/iqm2Sh9rHAszR2IeEaY+yrBCO84SExnOMDS/QwrB9OQ7Sq3kLV+3OFJszxEmkbrOpCyiBTvk2yP2VQ4NCgtTa4gwSfgUnEX/nfiBMcMVEuTgqn0PvHIG/QWuHWGld8jwcvV/Es3+ygdpMAAAAASUVORK5CYII=";

  var slugify = require('slugify')
  var lastDist = 0;

  const TextStyle = {
    fontSize: 14,
    lineHeight: 2,
    backgroundColor: '#7151F2',
    textAlign: 'center',
    fill: 'white',
    height: 18,
    fontFamily: 'sans-serif',
    originX: 'center', 
    originY: 'top',
    hasControls: false,
    editable: false
  };
  const GroupStyle = {
    lockScalingX: true,
    lockScalingY: true,
    padding: 10
  };

  var editImg = document.createElement('img');
  editImg.src = editIcon;
  var deleteImg = document.createElement('img');
  deleteImg.src = deleteIcon;
  var cloneImg = document.createElement('img');
  cloneImg.src = cloneIcon;

  const stageRef = useRef();
  const layerRef = useRef();
  const groupRef = useRef();
  const trRef = useRef();

  const handleResize = () => {
    if (stageRef && stageRef.current) {
      const nodeFinal = document.querySelector(canvasContainerId);
      stageRef.current.width(nodeFinal.offsetWidth);
      stageRef.current.height(nodeFinal.offsetHeight);
    }
  }

  useEffect(() => {
    if (!isHistorying && !isLoading) {
      savePlan(plan);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentName]);


  useEffect(() => {
    Konva.hitOnDragEnabled = true;
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const reCenter = (pointToObject, zoomLevel=1) => {
    setZoomLevel(zoomLevel);
    stageRef.current.scale({ x: zoomLevel, y: zoomLevel});
    if (stageRef && stageRef.current) {
      const nodeFinal = document.querySelector(canvasContainerId);
      stageRef.current.width(nodeFinal.offsetWidth);
      stageRef.current.height(nodeFinal.offsetHeight);
    }
    
    let groupPosition = groupRef.current.getClientRect();
    if (pointToObject && pointToObject.markus && pointToObject.markus.id) {
      const object = stageRef.current.find('#'+pointToObject.markus.id);
      if (object && object.length > 0) {
        groupPosition = object[0].getClientRect();
      }
    }
    var pointTo = {
      x: (stageRef.current.x() - groupPosition.x + (getWidth()/2) - (groupPosition.width / 2)),
      y: (stageRef.current.y() - groupPosition.y+ (getHeight()/2) - (groupPosition.height / 2))
    };
    stageRef.current.position(pointTo);
  }

  const getWidth = () => {
    return window.innerWidth;
  }

  const getHeight = () => {
    return window.innerHeight;
  }
  useEffect(() => {
    // INIT
    _onReady()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const regroupEdit = () => {
    setSeatEditing(false);
    setSelectedSeats([])
    setSeatInfo(false);
    setMultipleSeatsSelected(false);
    setMultipleSeatsDatas({});
    setTkrefBBox({})
    setHelp('')
    countElements(plan, true);
  }

  const countElements = (newPlan, force) => {
    if (!seatEditing || seatEditing.length === 0 || force === true) {
      let seats = 0;
      let seatsReserved = 0;
      const tables = newPlan.objects.tables.length;
      const stands = newPlan.objects.stands.length;
      newPlan.objects.tables.concat(newPlan.objects.stands).map((object) => {
        seats += object.items.filter((subobject) => subobject.typeObject && subobject.typeObject === 'seat').length;
        seatsReserved += object.items.filter((subobject) => subobject.typeObject && subobject.typeObject === 'seat' && subobject.markus.seatStatus && subobject.markus.seatStatus === 'reserved').length;
        return true;
      })
      setnbStands(stands)
      setnbTables(tables)
      setnbSeats(seats)
      setnbSeatsReserved(seatsReserved)
    }
  }

  const _onReady = () => {
    var planToLoad = localStorage.getItem('plan_konva');
    if (props.planToLoad) {
      planToLoad = props.planToLoad;
    }
    if (planToLoad !== null) {
      try {
        if (typeof planToLoad !== 'object') {
          planToLoad = JSON.parse(planToLoad);
        }
        if (!planToLoad.objects || !planToLoad.objects.stands) {
          planToLoad = {objects: {stands: [], tables: []}, title: 'My new plan'};
        }
        setPlan(planToLoad);
        countElements(planToLoad);
        if (planToLoad.title) {
          setDocumentName(planToLoad.title)
        }
        setTimeout(function(){setIsLoading(false)}, 500);
      } catch(e) {
        console.log(e);
        setTimeout(function(){setIsLoading(false)}, 500);
      }
    } else {
      setTimeout(function(){setIsLoading(false)}, 500);
    }
  }
  
  const editSeating = (obj=false) => {
    var selectedGroup = obj || blockSelected;
    if (selectedGroup != null && (selectedGroup.typeObject === 'stand' || selectedGroup.typeObject === 'table')){
      setBlockSelected(false);
      setBlocksSelected(false);
      /**
       * Enregistrer :
       * - Les objets du groupe en édition
       * - Les metas du groupe pour pouvoir les restaurer post edition
       */
      setSeatEditing(selectedGroup);
      let seatInfoToPush = {...selectedGroup.items.find((object) => object.markus && object.typeObject === 'seat')};
      const seatkonva = stageRef.current.find('#'+seatInfoToPush.markus.id);
      if (seatkonva.length === 1) {
        setSelectedSeats(stageRef.current.find('#'+seatInfoToPush.markus.id))
      }
      setSeatInfo(seatInfoToPush);
      setHelp('"Edit" mode: to quit this mode, use the ESC key of your keyboard or select an other stand or table.')
    }
  }

  const save = async(planToSave) => {
    if (!planToSave) {
      planToSave = plan;
    }
    if (planToSave) {
      if (props.onSave && typeof props.onSave === 'function' && !errorApi) {
        const saveOk = await props.onSave(planToSave);
        if (saveOk && saveOk.status && saveOk.status > 400) {
          setErrorApi('Une erreur est survenue lors de la sauvegarde. Merci de recharger votre page.');
        } else {
          if (errorApi) {
            setErrorApi(false);
          }
        }
        if (props.forceToLocalStorage) {
          localStorage.setItem('plan_konva', JSON.stringify(planToSave));
        }
      } else if (!errorApi){
        localStorage.setItem('plan_konva', JSON.stringify(planToSave));
      }
    }
  }
  
  const get_all_objects_code = (newPlan=false) => {
    const allCodes = [];
    const planToValidate = newPlan || plan;
    planToValidate.objects.stands.map((group) => {
      if (group.markus && group.markus.code) {
        allCodes.push(group.markus.code.toLowerCase())
      }
      return group;
    });
    planToValidate.objects.tables.map((group) => {
      if (group.markus && group.markus.code) {
        allCodes.push(group.markus.code.toLowerCase())
      }
      return group;
    });
    return allCodes;
  }

  const get_unique_code_name = (code, newPlan) => {
    const allCodes = get_all_objects_code(newPlan)
    if (allCodes.includes(code.toLowerCase())) {
      for (let i = 1; i < 10000; i++) {
        if (!allCodes.includes(code.toLowerCase()+'_'+i)){
          code = code +'_'+i;
          break;
        }
      }
    }
    return code;
  }

  const redrawTable = async(infos) => {
    if (!blockSelected.markus) {
      return
    }

    let oldSeats = blockSelected.markus.seats;
    if (blockSelected.markus.tableType === 'rectangular') {
      const [oldXSeats, oldYSeats] = blockSelected.markus.seats;
      oldSeats = oldXSeats + oldYSeats;
    }

    const seats = (infos && infos.nbSeats) ? infos.nbSeats : oldSeats;

    const code = (infos && infos.code) ? infos.code : blockSelected.markus.code;
    let name = 'Stand 1';
    const text = blockSelected.items.find((object) => object.typeObject === 'text');
    if (text) {
      name = text.text;
    }
    let newTable = false;
    let options = {};
    const typeTable = (infos && infos.tableType) ? infos.tableType : blockSelected.markus.tableType;
    if (typeTable === 'round') {
      options = {
        x: blockSelected.x,
        y: blockSelected.y,
        tableName: name,
        tableType: typeTable,
        seats: seats || 4,
        name: name,
        code: code,
        id: blockSelected.markus.id,
        deleted: blockSelected.markus.deleted || [],
        cb: false,
        onlyReturn: true
      };
    } else if (typeTable === 'rectangular') {
      let xySeats = [blockSelected.markus.xSeats || 2, blockSelected.markus.ySeats || 2];
      if (infos.nbSeatX || infos.nbSeatY) {
        if (infos.nbSeatX) {
          xySeats[0] = infos.nbSeatX;
        }
        if (infos.nbSeatY) {
          xySeats[1] = infos.nbSeatY;
        }
      }
      else if (seats > 8) {
        xySeats = [Math.round((seats - 4)/2), 2];
      }

      options = {
        x: blockSelected.x,
        y: blockSelected.y,
        tableName: name,
        tableType: typeTable,
        seats: xySeats,
        name: name,
        code: code,
        id: blockSelected.markus.id,
        deleted: blockSelected.markus.deleted || [],
        cb: false,
        onlyReturn: true,
        forceCode: true
      };
    }
    newTable = await makeTable(options);
    if (newTable) {
      let nextTables = [...plan.objects.tables];
      nextTables = nextTables.map((table) => {
        if (newTable.markus.id === table.markus.id) {
          return newTable;
        }
        return table;
      })
      setBlocksSelected(false);
      setBlockSelected(newTable);
      savePlan({...plan, objects: {...plan.objects, tables: nextTables}});
    }
  }

  const makeTable = async(opts = {}) => {
    const defaultOptions = {
      posX: 200, 
      posY: 200,
      tableName: 'Table',
      tableType: 'round',
      seats: 6,
      cb: false,
    }

    const options = Object.assign({}, defaultOptions, opts);
    if (!options.code) {
      options.code = slugify(options.tableName).toLowerCase();
    }
    if (!options.forceCode) {
      options.code = get_unique_code_name(options.code).toLowerCase();
    }
    regroupEdit();
    const newgroupIdCounter = groupIdCounter+1;
    setGroupIdCounter(newgroupIdCounter);

    var table = {};
    var items = [];

    let xSeats = 0, ySeats = 0;

    if (options.tableType === 'rectangular') {
      [xSeats, ySeats] = options.seats;
      xSeats = parseInt(xSeats, 10);
      ySeats = parseInt(ySeats, 10);
    }

    // seat size
    var rad = seatWidth/2,// Rayon d'un siège, moitié de la largeur
      dia = seatWidth, // Largeur d'un siège
      topdia = seatHeight, // Largeur d'un siège
      gaph = 12, // espacement des sièges
      gapv = 12, // espacement des sièges
      sideBuff = 8,
      topBuff = 8;

    const TextObject = {...TextStyle, ...{
      left: sideBuff,
      top: topBuff,
      width: options.tableName.length *8,
      originX: 'left',
      groupId: newgroupIdCounter,
      selectable: false,
      evented: false,
      hasControls: false,
      hasBorders: false,
      markus: {
        type: 'titletable',
        tableType: options.tableType,
        groupId: newgroupIdCounter
      }
    }}

    var text = {
      typeObject  : 'text',
      text: options.tableName,
      ...TextObject
    }

    var optionsDefault = {
      lockScalingX: true,
      lockScalingY: true,
      originX: 'center',
      originY: 'center',
      groupId: newgroupIdCounter,
      typeObject: 'seat'
    };

    const markusSeatDefault = {
      type: 'seat', 
      groupId: newgroupIdCounter,
      deleted: false,
      seatStatus: 'active',
      tableName: options.tableName,
      tableCode: slugify(options.code || options.tableName),
      typeSeat: options.seatsType || ''
    }
    
    if (options.tableType === 'rectangular') {
        // calculate height and width of table
        var tableWidth = (1*dia) + (2*gaph);
        var tableHeight = tableWidth;
        if (xSeats >= 1) {
          tableWidth = (xSeats*dia) + ((xSeats+1)*gaph);
        }
        if (ySeats >= 1) {
          tableHeight = (ySeats*topdia) + ((ySeats+1)*gapv);
        }

        var wholeWidth = tableWidth;
        if (ySeats > 0)
            wholeWidth = wholeWidth + dia*2 + gaph*2;
        
        var wholeHeight = tableHeight;
        if (xSeats > 0)
            wholeHeight = wholeHeight + topdia*2 + gapv*2;

        var contWidth = 0;
        if (text.width > wholeWidth) {
            contWidth = sideBuff*2 + text.width;
        } else {
            contWidth = sideBuff*2 + wholeWidth;
        }

        // position text in middle of box
        text.x = options.posX + contWidth/2;
        // build table object
        table = {
            stroke: '#7151F2',
            fill: '#D9D0FF',
            width: tableWidth,
            height: tableHeight,
            left: (ySeats > 0 ? gaph*2 + dia : 0), 
            top: (text.top + text.height + topBuff) + topdia + gapv,
            originX: 'center',
            originY: 'top',
            lockMovementX: true,
            lockMovementY: true,
            typeObject: 'table',
            tableType: 'rectangular',
            markus: {
              groupId: newgroupIdCounter,
              type: 'table',
              tableType: 'rectangular',
              id: 'table_rect_'+new Date().getTime()
            },
            groupId: newgroupIdCounter
        };
        items.push(text);
        items.push(table);

        var topPos = text.top + text.height + topBuff; // position du premier élément hors texte (sous l'étiquette du texte + marge basse)

        /**
         * Faire les chaises alignées sur l'axe Y
         */
        if (xSeats > 0) {
          var leftStart = table.left + gaph;
          var bottomPos = (text.top + text.height + topBuff) + topdia + gapv + tableHeight + gapv;
          for (var i = 0; i < xSeats; i++) {
            /**
             * Siège du haut
             */
            items.push({
              ...optionsDefault,
              ...{
                top: topPos,
                left: leftStart + (dia*i) + (gaph*i), //  position de la table à gauche + largeur de siège fois position du siège + espacement entre les sièges
                markus: {
                  ...markusSeatDefault,
                  ...{
                    id: 'seat_'+new Date().getTime()+'_x_'+(i+1)+'_top',
                    place: 'x'+(i+1)+'t',
                  }
                },
              }
            });
            /**
             * Siège du bas
             */
            items.push({
              ...optionsDefault, 
              ...{
                top: bottomPos,
                left: leftStart + dia*i + gaph*i,
                markus: {
                  ...markusSeatDefault,
                  ...{
                    id: 'seat_'+new Date().getTime()+'_x_'+(i+1)+'_bottom',
                    place: 'x'+(i+1)+'b',
                  }
                },
              }
            });
          }
        }

        /**
         * Faire les chaises alignées sur l'axe X
         */
        if (ySeats > 0) {
          var topStart = (text.top + text.height + topBuff) + (wholeHeight-tableHeight)/2 + gapv;
          for (var j = 0; j < ySeats; j++) {
            topPos = topStart + topdia*j + gapv*j;
            /**
             * Coté gauche de la table
             */
            items.push({
              ...optionsDefault, 
              ...{
                top: topPos,
                left: gaph,
                markus: {
                  ...markusSeatDefault,
                  ...{
                    id: 'seat_'+new Date().getTime()+'_y_'+(j+1)+'_left',
                    place: 'y'+(j+1)+'l',
                  }
                }
              }
            });
            /**
             * Coté droit de la table
             */
            items.push({
              ...optionsDefault, 
              ...{
                top: topPos,
                left: table.left + tableWidth + gaph,
                markus: {
                  ...markusSeatDefault,
                  ...{
                    id: 'seat_'+new Date().getTime()+'_y_'+(j+1)+'_right',
                    place: 'y'+(j+1)+'r',
                  }
                }
              }
            });
          }
        }
    }

    if (options.tableType === 'round') {
        // calculate the size of the table
        var tableRad = rad + gapv;
        if (options.seats >= 4 && options.seats < 6)
            tableRad = (rad)*1.5;
        else if (options.seats >= 6 && options.seats < 9)
            tableRad = rad*2;
        else
          tableRad = rad * (Math.round(options.seats/4)*1.5);

        // largeur totale = diamètre de la table + 2 chaises en hauteur + 2 espace entre les chaises + 2 padding du bloc
        var wholeDia = tableRad * 2 + dia*2 + gaph*2 + sideBuff*2;
        if (text.width > wholeDia) {
            contWidth = sideBuff*2 + text.width;
        } else {
            contWidth = sideBuff*2 + wholeDia;
        }

        // build table object
        table = {
            radius: tableRad, 
            stroke: '#7151F2', 
            fill: '#D9D0FF', 
            left: tableRad + topdia + sideBuff, 
            top: text.top + text.height + topBuff + topdia + tableRad,
            lockMovementX: true,
            lockMovementY: true,
            typeObject: 'table',
            tableType: 'round',
            markus: {
              groupId: newgroupIdCounter,
              type: 'table',
              tableType: 'round',
              id: 'table_round_'+new Date().getTime(),
            },
            groupId: newgroupIdCounter
        };
        // push initial objects
        items.push(text);
        items.push(table);

        // build chairs
        var deg = (2*Math.PI)/options.seats; // uses radians
        for (var k = 0; k < options.seats; k++) {
          var angle = deg*k;
          var top = Math.sin(angle)*(tableRad + topdia) + (table.top + (tableRad/2)) - (gapv*3);
          var left = Math.cos(angle)*(tableRad + topdia) + table.left + (tableRad/2) - (gaph*2);
          
          var seat = {
            ...optionsDefault,
            ...{
              left: left,
              top: top,
              markus: {
                type: 'seat', 
                id: 'seat_'+new Date().getTime()+'_'+k,
                groupId: groupIdCounter,
                tableName: options.name,
                tableCode: slugify(options.code || options.tableName),
                deleted: false,
                place: k+1,
                seatStatus: 'active',
                typeSeat: options.seatsType || ''
              },
            }
          }
          items.push(seat);
        }
    }
    const stage = document.querySelector(canvasContainerId);

    var optionsGroup = { ...GroupStyle, ...{
      x: options.x || (stage.offsetWidth/2),
      y: options.y || (stage.offsetHeight/2),
      groupId: newgroupIdCounter,
      typeObject: 'table',
      markus: {
        typeObject: 'table',
        tableType: options.tableType,
        name: options.tableName,
        code: slugify(options.code || options.tableName),
        seats: options.seats,
        xSeats: xSeats,
        ySeats: ySeats,
        groupId: newgroupIdCounter,
        deleted: options.deleted,
        typeSeat: options.seatsType || '',
        id: options.id || 'table'+groupIdCounter+'_'+new Date().getTime()
    }}};

    table = {...optionsGroup, items};
    setAddTableVisible(false);
    if (options.cb && 'function' === typeof options.cb) {
      options.cb(table);
    }
    if (!opts.onlyReturn) {
      savePlan({...plan, objects: { ...plan.objects, tables: [...plan.objects.tables, table]}});
      setBlockSelected(table);
      setTimeout(function() {
        reCenter(table);
      }, 200);
    }
    
    return table;
  }

  const makeStand = async (opts = {})  => {
    const defaultOptions = {
      cols: 10,
      rows: 5,
      name: 'My new stand',
      code: 'my_new_stand',
      lineranking: 'alphabetical',
      colranking: 'alphanumerical',
      rowStart: 'A',
      colStart: 1,
      deleted: [],
      switchRows: false,
      switchCols: false,
      cb: false,
    }

    const options = Object.assign({}, defaultOptions, opts);
    
    if (!options.forceCode) {
      options.code = get_unique_code_name(options.code).toLowerCase();
    }

    regroupEdit();
    const newgroupIdCounter = groupIdCounter+1;
    setGroupIdCounter(newgroupIdCounter);
    var dia = seatWidth,
      topdia = seatHeight,
      gapRight = 12,
      gapTop = 12,
      sideBuff = 12;

    var currentCol = options.colranking === 'alphabetical' ? 
      (options.colStart && options.colStart.length === 1 && options.colStart.match(/[a-zA-Z]/i) ? options.colStart : 'A') : 
      (parseInt(options.colStart) > 0 ? parseInt(options.colStart): 1);
    var currentRow = options.lineranking === 'alphabetical' ? 
      (options.rowStart && options.rowStart.length === 1 && options.rowStart.match(/[a-zA-Z]/i) ? options.rowStart : 'A') : 
      (parseInt(options.rowStart) > 0 ? parseInt(options.rowStart): 1);

    var stand = {};
    var items = [];

    const attributes = {
      rows: options.rows,
      cols: options.cols,
      typeObject: "seat",
      colStart: options.colStart,
      rowStart: options.rowStart,
      groupId: groupIdCounter,
    };
    
    const TextObject = {...TextStyle, ...{
      width: options.name.length *8,
      left: sideBuff,
      top: sideBuff,
      originX: 'left',
      groupId: groupIdCounter,
      hasControls: false,
      hasBorders: false,
      selectable: false,
      evented: false,
    }}
    
    var text = {
      typeObject  : 'text',
      text: options.name,
      ...TextObject
    }
    items.push(text);

    const seatBaseOptions = {
      lockScalingX: true,
      lockScalingY: true,
      originX: 'center',
      originY: 'center',
      groupId: groupIdCounter,
    }

    for (var i = 0; i < options.rows; i++) {
      let colNumber = currentCol;
      for (var j = 0; j < options.cols; j++) {
        const seatgroupAttributes = {
          left: sideBuff + (j * dia)+ (j * gapRight),
          top: (text.top + text.height) + sideBuff + gapTop + (i * topdia) + (i * gapTop),
          markus : {
            type: 'seat',
            id: 'seat_'+new Date().getTime()+'_'+i+'_'+j,
            groupId: groupIdCounter,
            deleted: false,
            rowName: currentRow,
            colName: colNumber,
            standName: options.name,
            standCode: slugify(options.code),
            place: currentRow+(options.lineranking === 'alphabetical' ? '':'.')+colNumber,
            seatStatus: 'active',
            typeSeat: options.seatsType || ''
          },
          ...seatBaseOptions
        }
        if (options.switchRows) {
          seatgroupAttributes.top = (text.top + text.height) + sideBuff + ((options.rows-i-1) * topdia) + ((options.rows-i) * gapTop);
        }
        if (options.switchCols) {
          seatgroupAttributes.left = ((options.cols-j-1) * dia) + ((options.cols-j) * gapRight);
        }
        if (!options.deleted || !options.deleted.includes(seatgroupAttributes.markus.place)) {
          const seat = {...attributes, ...seatgroupAttributes};
          items.push(seat);
        }
        colNumber = options.colranking === 'alphabetical' ? String.fromCharCode(colNumber.charCodeAt() + 1) : (colNumber+1);
      }
      currentRow = options.lineranking === 'alphabetical' ? String.fromCharCode(currentRow.charCodeAt() + 1) : (currentRow+1);
    }

    const stage = document.querySelector(canvasContainerId);

    var groupOptions = {...GroupStyle, ...{
      x: options.x || (stage.offsetWidth/2),
      y: options.y || (stage.offsetHeight/2),
      rows: options.rows,
      cols: options.cols,
      typeObject: "stand",
      groupId: groupIdCounter,
      markus: {
        rows: options.rows,
        cols: options.cols,
        typeObject: "stand",
        lineranking: options.lineranking,
        colranking: options.colranking,
        rowStart: options.rowStart,
        colStart: options.colStart,
        name: options.name,
        code: slugify(options.code),
        groupId: groupIdCounter,
        switchCols: options.switchCols,
        switchRows: options.switchRows,
        deleted: options.deleted,
        id: options.id || 'stand_'+groupIdCounter+'_'+new Date().getTime(),
        typeSeat: options.seatsType || ''
      }}};

    stand = {...groupOptions, items};
    setAddStandVisible(false);
    if (options.cb && 'function' === typeof options.cb) {
      options.cb(stand);
    }
    if (!opts.onlyReturn) {
      savePlan({...plan, objects: { ...plan.objects, stands: [...plan.objects.stands, stand]}});
      setBlockSelected(stand);
      setTimeout(function() {
        reCenter(stand);
      }, 200);
    }

    return stand;
  }

  const redrawStand = async(infos) => {
    if (!blockSelected.markus || !blockSelected.markus.cols || !blockSelected.markus.rows) {
      return
    }
    const cols = (infos && infos.colNumber) ? infos.colNumber : blockSelected.markus.cols;
    const rows = (infos && infos.rowNumber) ? infos.rowNumber : blockSelected.markus.rows
    const code = (infos && infos.code) ? infos.code : blockSelected.markus.code;
    let name = 'Stand 1';
    const text = blockSelected.items.find((object) => object.typeObject === 'text');
    if (text) {
      name = text.text;
    }
    let newStand = false;
    const options = {
      x: blockSelected.x,
      y: blockSelected.y,
      cols: cols,
      rows: rows,
      name: name,
      code: code,
      id: blockSelected.markus.id,
      lineranking: blockSelected.markus.lineranking || 'alphabetical',
      colranking: blockSelected.markus.colranking || 'alphanumerical',
      rowStart: blockSelected.markus.rowStart || 'A',
      colStart: blockSelected.markus.colStart || 1,
      switchRows: blockSelected.markus.switchRows || false,
      switchCols: blockSelected.markus.switchCols || false,
      deleted: blockSelected.markus.deleted || [],
      cb: false,
      onlyReturn: true,
      seatsType: blockSelected.markus.typeSeat || '',
      forceCode: true
    }
    newStand = await makeStand(options);
    if (newStand) {
      let nextStands = [...plan.objects.stands];
      nextStands = nextStands.map((stand) => {
        if (newStand.markus.id === stand.markus.id) {
          return newStand;
        }
        return stand;
      })
      setBlocksSelected(false);
      setBlockSelected(newStand);
      savePlan({...plan, objects: {...plan.objects, stands: nextStands}});
    }
  }

  const editTitle = () => {
    setEditTitleVisible(true);
  }

  const editTitleFinished = () => {
    setEditTitleVisible(false);
  }

  const schemaUndo = () => {
    if (historyBack && historyBack.length > 0) {
      const back = [...historyBack];
      const newplan = back.slice(-1).pop();
      setBlockSelected(false);
      setBlocksSelected(false);
      setIsHistorying(true);
      setHistoryMessage('Undoing action');
      setHistoryRedo([...historyRedo, plan])
      setHistoryBack(back.slice(0, -1));
      if (newplan.title !== documentName) {
        setDocumentName(newplan.title)
      }
      setPlan({...newplan})
      setTimeout(function() {
          setIsHistorying(false);
          setHistoryMessage('');
          countElements({...newplan});
        }, 200);
    }
  }

  const schemaRedo = () => {
    if (historyRedo && historyRedo.length > 0) {
      setBlockSelected(false);
      setBlocksSelected(false);
      setIsHistorying(true);
      setHistoryMessage('Redoing action');
      const newplan = historyRedo.slice(-1).pop();
      if (newplan.title !== documentName) {
        setDocumentName(newplan.title)
      }
      setHistoryBack([...historyBack, plan])
      setHistoryRedo(historyRedo.slice(0, -1));
      setPlan({...newplan})
      setTimeout(function() {
          setIsHistorying(false);
          setHistoryMessage('');
          countElements({...newplan});
      }, 200);
    }
  }

  const savePlan = (json) => {
    json.type = 'konva';
    setHistoryRedo([]);
    setHistoryBack([...historyBack, plan]);
    if (json.title !== documentName) {
      json.title = documentName;
    }
    setPlan({...json});
    countElements(json);
    save(json);
  }

  const readFileOnUpload = (uploadedFile) => {
    setIsLoading(true);
    const fileReader = new FileReader();
    fileReader.onloadend = ()=>{
      try {
        const planJson = JSON.parse(fileReader.result);
        if (planJson.title) {
          setDocumentName(planJson.title)
        }
        setPlan(planJson);
        setTimeout(function(){setIsLoading(false)}, 500);
      } catch(e) {
        setTimeout(function(){setIsLoading(false)}, 500);
        alert("**Not valid JSON file!**");
      }
    }
    if (uploadedFile !== undefined) {
      fileReader.readAsText(uploadedFile);
    }
  }

  const deleteAllElement = () => {
    setBlockSelected(false);
    savePlan({...plan, objects: { stands: [], tables: [] } });
  }

  const deleteElement = (id = false) => {
    let nextStands = [...plan.objects.stands];
    let nextTables = [...plan.objects.tables];
    if (id || blockSelected) {
      if (window.confirm('Are you sure you wish to delete the selected object ?' )) {
        if (id) {
          nextStands = nextStands.filter((stand) => stand.markus.id !== id);
          nextTables = nextTables.filter((table) => table.markus.id !== id);
        }
        if (blockSelected) {
          nextStands = nextStands.filter((stand) => stand.markus.id !== blockSelected.markus.id);
          nextTables = nextTables.filter((table) => table.markus.id !== blockSelected.markus.id);
        }
        savePlan({...plan, objects: { stands: nextStands, tables: nextTables } });
      }
    } else if (selectedSeats.length > 0) {
      if(window.confirm(selectedSeats.length > 1 ? 'Are you sure you wish to delete the '+ selectedSeats.length+' selected objects?' : 'Are you sure you wish to delete the selected object?' )) {
        const selectedSeatsIds = selectedSeats.map((seat) => seat.attrs.id);
        nextStands.map((stand) => {
          /**
           * Si on supprime un siège particulier, c'est que l'on est dans l'édition d'un élément parent (table ou tribune)
           * on ajoute dans un élément deleted un tableau des sièges supprimés, pour par la suite
           * ne pas les regénérer lors d'un changement de configuration de l'élément parent
           */
          const deleted = stand.items
            .filter((item) => item.typeObject === 'seat' && selectedSeatsIds.includes(item.markus.id))
            .map((item) => item.markus.place);
          stand.markus.deleted = (stand.markus.deleted || []).concat(deleted);
          stand.items = stand.items.filter((item) => item.typeObject !== 'seat' || !selectedSeatsIds.includes(item.markus.id))
          return stand
        });
        nextTables.map((table) => {
          /**
           * Si on supprime un siège particulier, c'est que l'on est dans l'édition d'un élément parent (table ou tribune)
           * on ajoute dans un élément deleted un tableau des sièges supprimés, pour par la suite
           * ne pas les regénérer lors d'un changement de configuration de l'élément parent
           */
          const deleted = table.items
            .filter((item) => item.typeObject === 'seat' && selectedSeatsIds.includes(item.markus.id))
            .map((item) => item.markus.place);
          table.markus.deleted = (table.markus.deleted || []).concat(deleted);
          table.items = table.items.filter((item) => item.typeObject !== 'seat' || !selectedSeatsIds.includes(item.markus.id))
          return table
        });
        setSelectedSeats([]);
        savePlan({...plan, objects: { stands: nextStands, tables: nextTables } });
      }
    }
    setBlocksSelected(false);
  }

  const setZoom = (stage, increment, zoomToPointer = false, evt) => {
    var scaleBy = zoomIncrement;
    var oldScale = stage.scaleX();
  
    // how to scale? Zoom in? Or zoom out?
    let direction = increment > 0 ? 1 : -1;

    // when we zoom on trackpad, e.evt.ctrlKey is true
    // in that case lets revert direction
    if (evt && evt.ctrlKey) {
      direction = -direction;
    }

    var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    if (newScale > zoomHigh || newScale < zoomLow)
      return;

    stage.scale({ x: newScale, y: newScale });

    if (zoomToPointer) {
      var pointer = stage.getPointerPosition();

      var mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };
      var newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      stage.position(newPos);
    }
    setZoomLevel(newScale);
  }
  const zoomPlus = () => {
    setZoom(stageRef.current, 10, false)
  }

  const zoomMoins = () => {
    setZoom(stageRef.current, -10, false)
  }

  const addStand = () => {
    setAddStandVisible(true);
  }

  const addTable = () => {
    setAddTableVisible(true)
  }
  
  const blockSelectedUpdate = async(data) => {
    const keys = Object.keys(data);

    const selected = {...blockSelected};
    let items = [...selected.items];
    if (data.title) {
      items = items.map((object, i) => {
        let newObject = {...object};
        if (object.typeObject === 'text') {
          newObject.text = data.title;
        }
        else {
          newObject.markus.standName = data.title;
        }
        return newObject;
      })
    }
    if (keys.includes('code')) {
      selected.markus.code = slugify(data.code);
      items.map((object) => {
        let newObject = {...object};
        if (newObject.markus) {
          newObject.markus.standCode = slugify(data.code);
        }
        return newObject;
      })
    }

    if (keys.includes('seatsType')) {
      selected.markus.typeSeat = data.seatsType;
      items.map((object) => {
        let newObject = {...object};
        if (newObject.markus) {
          newObject.markus.typeSeat = data.seatsType;
        }
        return newObject;
      })
    }
    let nextStands = [...plan.objects.stands.map((stand) => {
        if (stand.markus.id === blockSelected.markus.id) {
          return {...selected, items};
        }
        return stand;
      })];
    let nextTables = [...plan.objects.tables.map((stand) => {
      if (stand.markus.id === blockSelected.markus.id) {
        return blockSelected;
      }
      return stand;
    })];

    if (data.title || keys.includes('code') || keys.includes('seatsType')) {
      savePlan({...plan, objects: { tables: nextTables, stands: nextStands }});
    }

    if (data.typeTable) {
      if (data.typeTable === selected.markus.typeTable) {
        return;
      }
      redrawTable({tableType: data.typeTable })
    }

    /**
     * Table ronde, augmentation du nombre de sièges
     */
    if (data.seatNumber && parseInt(data.seatNumber, 10) > 0 && parseInt(data.seatNumber, 10) <= 20) {
      if (selected.markus.tableType !== 'round') {
        return;
      }
      redrawTable({nbSeats: parseInt(data.seatNumber, 10), tableType: selected.markus.tableType})
    }

    /**
     * Table rectangulaire, augmentation du nombre de sièges sur l'axe X
     */
    else if (data.seatNumberX && parseInt(data.seatNumberX, 10) > 0 && parseInt(data.seatNumberX, 10) <= 20) {
      if (selected.markus.tableType !== 'rectangular') {
        return;
      }
      redrawTable({nbSeatX: parseInt(data.seatNumberX, 10), tableType: selected.markus.tableType})
    }

    /**
     * Table rectangulaire, augmentation du nombre de sièges sur l'axe Y
     */
    else if (data.seatNumberY && parseInt(data.seatNumberY, 10) > 0 && parseInt(data.seatNumberY, 10) <= 20) {
      if (selected.markus.tableType !== 'rectangular') {
        return;
      }
      redrawTable({nbSeatY: parseInt(data.seatNumberY, 10), tableType: selected.markus.tableType})
    }

    /**
     * Tribune, ajout de colonne ou de ligne
     */
    else if (data.rowNumber && parseInt(data.rowNumber, 10) > 0 && parseInt(data.rowNumber, 10) <= 20){
      if (!selected.markus.typeObject || selected.markus.typeObject !== 'stand') {
        return;
      }
      redrawStand({rowNumber: parseInt(data.rowNumber, 10)})
    }
    else if (data.colNumber && parseInt(data.colNumber, 10) > 0 && parseInt(data.colNumber, 10) <= 20){
      if (!selected.markus.typeObject || selected.markus.typeObject !== 'stand') {
        return;
      }
      redrawStand({colNumber: parseInt(data.colNumber, 10)})
    }
  }

  const exportPlan = () => {
    regroupEdit();
    const data = {...plan};
    data.title = documentName || 'My new plan';
    const dataStr = JSON.stringify(data);
    const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
    const exportFileDefaultName = slugify(documentName || 'my new plan')+'.json';

    let linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();

  }

  const printPlan = () => {
    regroupEdit();
    setIsHistorying(true);
    setHistoryMessage('Exporting PDF');
    setTimeout(function() {
      const stage = groupRef.current;
      let width = stage.getClientRect().width+100;
      let height = stage.getClientRect().height+150;
      let pdf;
      if (width > height){
        pdf = new jsPDF('l', 'px', [width, height]);
      }
      else {
        pdf = new jsPDF('p', 'px', [height, width]);
      }
      pdf.setProperties({
        title: documentName,
        subject: 'Plan for mark.us ('+documentName+')',
        author: 'Mark.us feat Apsulis',
        keywords: 'plan, mark.us',
        creator: 'Markus Seat Planner'
      });

      width = pdf.internal.pageSize.getWidth();
      height = pdf.internal.pageSize.getHeight();

      const logo = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGoAAAAWCAIAAACno556AAAFbUlEQVR4Ae2Yg5skWQzA9+9Y27Zt27Zt2xrbtm3btu02qy+zfd/rdDVOde76spgkT79JKnk1QPYPflgs1pAhg4i8fPnin7ZDHT4dPh0+HT4dPqYeHT6RSKTJJBQKf+Xs4h+Pdh/Nq/+z8FGUjMOiJGJlfOfPnyMSHh7GZrM/fHg/f/5c2O7o0SMPHTqYnp4md83Ozj5x4vjYsaPBNHnyxNu3b7W1takuw+Px7O3tjh49PHXqZPmxJ0wYf+TIIV9fH6lUij0bGxvv3btLpKampqqq6tSpk7Du0KGDgZ0WfLD0pUsX8eajo6O1HJ7Lpmw/8YhkxYuw1VmfT0yx/kJs4nGoEGfB+yuc23tYN3b0y9uLHC9zflujtB8f3t/nz5/Wrl2NNSAjRgyLjIzw9PSA/9BMc+bMAgR4sdDQkOnTp2EfLOfOnZFIJMQ5Pz8PW93cXAlxEAhbTfjAtHv3Tmw6fPig9jDv7aLg5EQCHQXY+uAQm5jsPvOIHhi9PMMhJiy3drEiPIVK+CBM1B4bTjVq1Ai1ptOnT5HFenp6pk+fCkotYmNjrQkfHgsxriV5nz9/hvXLly/r6+sDPbP4IFs/XqezI3JzJyspRKTAR2TevDmzZ8/EGmyaOFEJ8bBhQ3AK5+XlTZgwDvSLFi2Al4CTk+Pbt2+mTJlE/JcuXawJH5ZZs2ZowgcBDqmNf7WQ9aBnHF9NiQTHGgwpTBcnhojeXe5nGuUtpCcviLm5GWghxa5evUwzubu7yRPn4MEDWB8TE4O3kpSU9P37N5xKmZmZ2L+pqUkTvoULF0REhLe2tra0tKjFV1lZCSmCXyywHFmIWXyZsSKifHWeQ5xFQlleyo/TEXwkNCiKkhsKCvKxaevWzWQ8vAqxycHBXu2OgQIASkiIDw4Oxu/N+Pg4jA/HUUdHh5bGBcrLypUrsMbJyYk4M46vJFuMhxg94ULodbej6kfDd/HiBWKAk2DTo0cPiam4uJgesOjJysq6du0KLgI08fPzVYsP0lx730erXU+ePEa+zOMTi2Rq68anGxwITIpSwQe9CJmxq6tL09kqKsrV4oO+5Nmzp5qo4QpL8GkOJYJPY2Vbt24NrQNlvPI2VElen1dfPSxe86QSRvE5Ojpg/apVK16/fgVKLy/PBQvmE72rq4tafM7OztrxPX36ZPPmjVgDJfj34fOxUuCDVurWbpYqPhKDyWEik+c80vcRiQ8UMolv48YNRHnr1k3c4s2YMf2P44PSAUtDU000UIKh1NBR9TTJCkNl+UGyjmqi43OV8Jm/4imOUyBBJjo+PENmnOjJcUWcGj/lMokPtzs4E+GEoGEEHyj19fVo3aLS5ae7XhalJ4v8/rO0lhPLwyOKk9/ezSrOEoOyvVn6/ipHE76WeqnpC15PJ0U0bkZ84qn3gFF8OLPmzp3t4uIcFRUJN5kxY0YxiA9aIloKw82SNAyyXH8FO5AsTzIbBAst++4fJEDV4OtqlT47xZa7uZvwoez62Qju7VcMcTXiM4kPbhRYj4VBfPDgFKZX/2xvJXwZ7mQ2aNY03R8eH6WXDgGfen0BRyVd4HVZVyFhEh+87KD1UeV15swpuEoziA8eIyNDWk+Tk5PTb2gtVcLXVIAn9DTjq7KL9RPqP+CqRl9OovjxMXp4ktxPixKBzwDgQiQoKJCsxOVysQl/z4CWEJvS0lLxFsPCQuH7x/r1a5ctWwLfXaDsQkNjbW1F/OFiJ/eEqwWeJzc3l4ZPIBBgB3gV4E9hX79+wVbyW5G1lffHIKRtcxGejUAxfsaFrISOxPYjr7pYAkq4vQbYC+SCP8YIBVRKhMjmA+/lWc6dvSyg+fkmx9tC0N4klTv8BH+rZ/K8ZeFRAAAAAElFTkSuQmCC`;
      pdf.addImage(logo, 'PNG', pdf.internal.pageSize.width-(30+53), 10, 53, 11, 'NONE', 0);
      
      const data = stage.toDataURL({
        mimeType: 'image/png',
        pixelRatio: 2
      });
      pdf.addImage(data, 'PNG', 50, 75, width-100, height-150);
      pdf.setFontSize(50);
      pdf.text(50, 60, documentName);
      setTimeout(
        function() {
          pdf.save(slugify(documentName || 'my new plan')+'.pdf');
          setIsHistorying(false);
          setHistoryMessage('');
        }, 
      300);
    },10);
  }

  const seatsSelectedUpdate = (data) => {
    if (!selectedSeats || selectedSeats.length === 0) {
      return;
    }

    const selectedIds = [...selectedSeats.map((seat) => seat.attrs.id)];
    let nextStands = [...plan.objects.stands];
    let nextTables = [...plan.objects.tables];
    nextStands.map((stand) => {
      const items = stand.items;
      stand.items = [...items].map((item) => {
        const options = {...item};
        if (item.typeObject === 'seat' && selectedIds.includes(item.markus.id)) {
          if (data.seatStatus) {
            options.markus.seatStatus = data.seatStatus;
            if (data.seatStatus === 'reserved') {
              options.markus.lineColor =  options.markus.lineColor || '#ededed';
              options.markus.fillColor =  options.markus.fillColor || '#d40000';
            } else {
              options.markus.lineColor = '';
              options.markus.fillColor = '';
            }
          }
      
          if (data.fillColor) {
            options.markus.fillColor = data.fillColor;
          }
      
          if (data.lineColor) {
            options.markus.lineColor = data.lineColor;
          }
      
          if (data.reservedReason) {
            options.markus.reservedReason = data.reservedReason;
          }
      
          if (data.colName || data.rowName) {
            if (data.colName) {
              options.markus.colName = data.colName;
            }
            if (data.rowName) {
              options.markus.rowName = data.rowName;
            }
            const rowIsint = !isNaN(options.markus.rowName) && !isNaN(parseFloat(options.markus.rowName));
            options.markus.place = options.markus.rowName + (rowIsint ? '.' : '') + options.markus.colName;
          }

          if (data.typeSeat || data.typeSeat === '') {
            options.markus.typeSeat = data.typeSeat;
          }
        }
        return {...options};
      });

      return {...stand};
    })
    nextTables.map((table) => {
      const items = table.items;
      table.items = [...items].map((item) => {
        if (item.typeObject === 'seat' && selectedIds.includes(item.markus.id)) {
          const options = {...item};
          if (data.seatStatus) {
            options.markus.seatStatus = data.seatStatus;
            if (data.seatStatus === 'reserved') {
              options.markus.lineColor =  options.markus.lineColor || '#ededed';
              options.markus.fillColor =  options.markus.fillColor || '#d40000';
            }
          }
      
          if (data.fillColor) {
            options.markus.fillColor = data.fillColor;
          }
      
          if (data.lineColor) {
            options.markus.lineColor = data.lineColor;
          }
      
          if (data.reservedReason) {
            options.markus.reservedReason = data.reservedReason;
          }
      
          if (data.colName || data.rowName) {
            if (data.colName) {
              options.markus.colName = data.colName;
            }
            if (data.rowName) {
              options.markus.rowName = data.rowName;
            }
            const rowIsint = !isNaN(options.markus.rowName) && !isNaN(parseFloat(options.markus.rowName));
            options.markus.place = options.markus.rowName + (rowIsint ? '.' : '') + options.markus.colName;
          }
          if (data.typeSeat || data.typeSeat === '') {
            options.markus.typeSeat = data.typeSeat;
          }
        }
        return {...item};
      });

      return {...table};
    })
    
    const newMultipleSeatsDatas = multipleSeatDatas;

    if (data.seatStatus) {
      newMultipleSeatsDatas.seatStatuses = [data.seatStatus];
    }
    if (data.fillColor) {
      newMultipleSeatsDatas.fillColors = [data.fillColor];
    }
    if (data.lineColor) {
      newMultipleSeatsDatas.lineColors = [data.lineColor];
    }
    if (data.reservedReason) {
      newMultipleSeatsDatas.reservedReasons = [data.reservedReason];
    }

    setMultipleSeatsDatas(newMultipleSeatsDatas);
    savePlan({...plan, objects: { stands: nextStands, tables: nextTables }});
  }

  
  const seatSelectedUpdate = (data) => {
    if (!selectedSeats || selectedSeats.length === 0 || !seatInfo) {
      return;
    }
    const newSeat = { ...seatInfo }
    if (data.seatStatus) {
      newSeat.markus.seatStatus = data.seatStatus;
      if (data.seatStatus === 'reserved') {
        newSeat.markus.lineColor =  newSeat.markus.lineColor || '#ededed';
        newSeat.markus.fillColor =  newSeat.markus.fillColor || '#d40000';
      }
    }

    if (data.fillColor) {
      newSeat.markus.fillColor = data.fillColor;
    }

    if (data.lineColor) {
      newSeat.markus.lineColor = data.lineColor;
    }

    if (data.reservedReason) {
      newSeat.markus.reservedReason = data.reservedReason;
    }

    if (data.typeSeat) {
      newSeat.markus.typeSeat = data.typeSeat;
    }
    if (data.colName || data.rowName) {
      if (data.colName) {
        newSeat.markus.colName = data.colName;
      }
      if (data.rowName) {
        newSeat.markus.rowName = data.rowName;
      }
      const rowIsint = !isNaN(newSeat.markus.rowName) && !isNaN(parseFloat(newSeat.markus.rowName));
      newSeat.markus.place = newSeat.markus.rowName + (rowIsint ? '.' : '') + newSeat.markus.colName;
    }

    const newid = newSeat.markus && newSeat.markus.id;
    let nextStands = [...plan.objects.stands];
    let nextTables = [...plan.objects.tables];
    
    nextStands = nextStands.map((stand, i) => {
      stand.items = stand.items.map((item) => {
        if (item.typeObject === 'seat' && item.markus.id === newid) {
          return newSeat;
        }
        return item;
      });
      return stand;
    });
    nextTables = nextTables.map((table) => {
      table.items = table.items.map((item) => {
        if (item.typeObject === 'seat' && item.markus.id === newid) {
          return newSeat;
        }
        return item;
      });
      return table;
    })
    savePlan({...plan, objects: { stands: nextStands, tables: nextTables}});
  }

  const onKeyDown = (e) => {
    if (e.target && e.target.nodeName === 'INPUT') {
      return
    }
    if (AddStandVisible || AddTableVisible || EditTitleVisible) {
      // Echap
      if (e.keyCode === 27) {
        setAddStandVisible(false);
        setAddTableVisible(false);
        setEditTitleVisible(false);
      }
      return false;
    } else if (blockSelected || blocksSelected) {
      if (e.keyCode === 27) {
        setBlockSelected(false);
        setBlocksSelected(false);
        return false;
      }
    }
    if (e.key === '+') {
      zoomPlus()
      return false;
    }
    if (e.key === '-') {
      zoomMoins()
      return false;
    }
    // e => Edit the group
    if (e.keyCode === 69) { 
      if (!seatEditing || seatEditing.length === 0) {
        editSeating();
      } else {
        alert('You are already editing something');
      }
    }
    if (e.keyCode === 68) {
      //duplicateElement();
    }
    // echap
    if (e.keyCode === 27) {
      regroupEdit();
      if (trRef.current)
        trRef.current.nodes([]);
    }
    // Backspace : supprimer le ou les éléments sélectionnés
    if (e.keyCode === 8) {
      deleteElement();
    };
  }

  const handleWheel = (e) => {
    e.evt.preventDefault();
    setBlockSelected(false);
    setZoom(e.target.getStage(), e.evt.deltaY, true, e.evt)
  }
  const handleTouchEnd = (e) => {
    lastDist = 0;
  }
  const handleTouchMove = (e) => {
    var touch1 = e.evt.touches[0];
    var touch2 = e.evt.touches[1];
    if (touch1 && touch2) {
      e.evt.preventDefault();
      const stage = e.target.getStage();
      if (stage.isDragging()) {
        stage.stopDrag();
      }
      var dist = touch1.clientX - touch2.clientX;
      if (touch1.clientX < touch2.clientX) {
        dist = touch2.clientX - touch1.clientX;
      }
      if (!lastDist) {
        lastDist = dist;
      }
      if (dist === lastDist )
        return;
      setZoom(e.target.getStage(), dist > lastDist ? 1 : 0, true, e.evt);
      lastDist = dist;
    } else {
      lastDist = 0;
    }
  }

  const selectionRectRef = React.useRef();

  const onMouseDown = (e) => {
    return;
  };

  const onMouseMove = (e) => {
    return;
  };

  const onMouseUp = () => {
    return;
  };

  const deleteVisible = !blockSelected && tkrefBBox && tkrefBBox.width && tkrefBBox.width > 0 ? true : false;

  return (
    <div className="seat-planner-app text-sm 2xl:text-base height-full width-full fixed" id={canvasContainerId.replace('#', '')} tabIndex={0} onKeyDown={onKeyDown}>
      <Loading visible={isLoading} />
      <HistoryLoading visible={isHistorying} message={historyMessage} />
      <MarkusDropZone onChangeUpload={readFileOnUpload}>
        <div>
          <h1>Drag your file here...</h1>
        </div>
      </MarkusDropZone>
      <header className="absolute z-10 flex bg-white mt-10 p-2 border border-gray-200 rounded-md shadow-gray-500 shadow-2xl w-11/12 left-1/2 -translate-x-1/2">
        <div className="w-5/12" style={{lineHeight: 2.5}}>
          <div className="mr-3 inline-block">
            {props.backLink &&
            <a
              href={props.backLink}
              onClick={(e) => save(plan)}
              rel="noreferer"
              className="inline-block ml-2 mr-6 rounded-md border border-gray-300 shadow-sm p-3 leading-4 bg-white text-gray-700 hover:bg-violet-940  hover:text-white focus:outline-none align-bottom">
              <img src={BackLinkButton} alt="Back" />
            </a>}
          </div>
          <div className="mr-3 inline-block">
            <span className="font-bold">
              {documentName}
            </span>
            <button 
              type="button"
              onClick={editTitle}
              className="mt-3 ml-3 inline-block m-1 text-violet-940 sm:mt-0 sm:m-1 sm:w-auto sm:text-sm">
                <img src={EditPencil} alt="" className="inline-block" /> Edit
            </button>
          </div>
        </div>
        <div className="counts w-7/12 text-right whitespace-nowrap">
          <div className="mr-3 inline-block">
            {nbStands > 0 &&
              <span>
                <img src={seatNb} alt="Seats" className="inline-block align-text-top ml-3" /> { nbStands > 1 ? nbStands + ' stands' : '1 stand' }
              </span>
            }
            {nbTables > 0 &&
              <span>
                <img src={tableNb} alt="Tables" className="inline-block align-text-top ml-3" /> { nbTables > 1 ? nbTables + ' tables' : '1 table' }
              </span>
            }
            {nbSeats > 0 &&
              <span>
                <img src={seatNb} alt="Seats" className="inline-block align-text-top ml-3" /> { nbSeats > 1 ? nbSeats + ' seats' : '1 seat' }
                {nbSeatsReserved > 0 && 
                  (nbSeatsReserved > 1 ? ' ('+ nbSeatsReserved + ' reserved)' : ' (1 reserved)')
                }
              </span>
            }
          </div>
          <button 
            type="button"
            onClick={printPlan}
            className="inline-block ml-2 rounded-md border border-gray-300 shadow-sm p-3 leading-4 bg-white text-gray-700 hover:bg-violet-940  hover:text-white focus:outline-none align-bottom">
            <img src={Print} alt="Print" />
          </button>
          <button
            type="button"
            onClick={exportPlan}
            className="inline-block ml-2 rounded-md border border-gray-300 shadow-sm p-3 leading-4 bg-violet-940 font-medium text-white hover:bg-violet-940/80 focus:outline-none  align-bottom">
            <img src={ExternalLink} alt="Export" className="inline-block" /> Export
          </button> 
          <label htmlFor="jsonFile" className="inline-block ml-2 rounded-md border border-gray-300 shadow-sm p-3 leading-4 bg-violet-940 font-medium text-white hover:bg-violet-940/80 focus:outline-none align-bottom cursor-pointer">
            <img src={Import} alt="Import" className="inline-block" /> Import <input type="file" className="hidden" id="jsonFile" onChange={(e)=>readFileOnUpload(e.target.files[0])} />
          </label>
          <button
            type="button"
            title="Clean the plan"
            onClick={deleteAllElement}
            className="inline-block ml-2 rounded-md shadow-sm p-3 leading-4 bg-red-600 font-medium text-white hover:bg-red-700 focus:outline-none">
              <img src={TrashIcon} alt="Clean the plan" className="inline-block" />
          </button>
        </div>
      </header>
      <div className="fixed z-10 bg-white m-6 p-2 bottom-0 left-1/2 -translate-x-1/2 border border-gray-200 rounded-md shadow-gray-500 shadow-2xl">
        <div className="inline-block">
          <button 
            type="button"
            onClick={(e) => save(plan)}
            className="inline-block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium text-gray-700 hover:bg-violet-940 hover:border-violet-940 hover:text-white focus:outline-none mt-0 sm:m-1 sm:w-auto sm:text-sm">
            <img src={Hand} alt="Save" />
          </button>
          <button 
            type="button"
            onClick={addStand}
            className="inline-block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium text-gray-700 hover:bg-violet-940 hover:border-violet-940 hover:text-white mt-0 sm:m-1 sm:w-auto sm:text-sm">
              <img src={StandPlus} alt="Add a stand" />
          </button>
          <button 
            type="button"
            onClick={addTable}
            className="inline-block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white ffont-medium text-gray-700 hover:bg-violet-940 hover:border-violet-940 hover:text-white mt-0 sm:m-1 sm:w-auto sm:text-sm">
              <img src={TablePlus} alt="Add a table" />
          </button>
        </div>
        <div className="inline-block border-l border-gray-300 pl-2 ml-2">
          {(historyBack.length > 0 || historyRedo.length > 0) && 
          <button 
            type="button"
            onClick={schemaUndo}
            className={historyBack.length > 0 ? "inline-block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium text-gray-700 hover:bg-violet-940 hover:border-violet-940 hover:text-white mt-0 sm:m-1 sm:w-auto sm:text-sm" : "inline-block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium cursor-default mt-0"}>
              <img src={BackButton} alt="Go back" className={historyBack.length > 0 ? '' : 'opacity-25'} />
          </button>}
          {(historyBack.length > 0 || historyRedo.length > 0) && 
          <button 
            type="button"
            onClick={schemaRedo}
            className={historyRedo.length > 0 ? "inline-block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium text-gray-700 hover:bg-violet-940 hover:border-violet-940 hover:text-white mt-0 sm:m-1 sm:w-auto sm:text-sm" : "inline-block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium cursor-default mt-0"}>
              <img src={ForwardButton} alt="Go forward" className={historyRedo.length > 0 ? '' : 'opacity-25'} />
          </button>}
        </div>
      </div>
      <div className="fixed z-10 bg-white m-6 p-2 bottom-0 right-0  border border-gray-200 rounded-md shadow-gray-500 shadow-2xl">
          <div
            title="Actual zoom level"
            className={"block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium cursor-default text-gray-700 text-center text-xs mt-0 sm:m-1 sm:w-auto sm:text-xs"}>
            {Math.round((zoomLevel)*10)/10}
          </div>
          <button 
            type="button"
            onClick={reCenter}
            className={"block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium text-gray-700 hover:bg-violet-940 hover:border-violet-940 hover:text-white mt-0 sm:m-1 sm:w-auto sm:text-sm"}>
              <img src={Center} alt="Recenter" />
          </button>
          <button 
            type="button"
            onClick={zoomPlus}
            className={zoomLevel < zoomHigh ? "block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium text-gray-700 hover:bg-violet-940 hover:border-violet-940 hover:text-white mt-0 sm:m-1 sm:w-auto sm:text-sm" : "block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium cursor-default mt-0"}>
              <img src={ZoomIn} alt="Zoom in" className={zoomLevel < zoomHigh ? '' : 'opacity-25'} />
          </button>
          <button 
            type="button"
            onClick={zoomMoins}
            className={zoomLevel > zoomLow ? "block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium text-gray-700 hover:bg-violet-940 hover:border-violet-940 hover:text-white mt-0 sm:m-1 sm:w-auto sm:text-sm" : "block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium cursor-default mt-0"}>
              <img src={ZoomOut} alt="Zoom out" className={zoomLevel > zoomLow ? '' : 'opacity-25'} />
          </button>
      </div>
      <AddStand visible={AddStandVisible} makeStand={makeStand} plan={plan} close={setAddStandVisible} types={props.types} />
      <AddTable visible={AddTableVisible} makeTable={makeTable} plan={plan} close={setAddTableVisible} types={props.types} />
      <EditTitle visible={EditTitleVisible} actualTitle={documentName} editTitle={setDocumentName} close={editTitleFinished} />
      <div className="relative">
        {multipleSeatSelected && <SeatMultipleInfo onUpdate={seatsSelectedUpdate} styles={{}} multipleSeatDatas={multipleSeatDatas} types={props.types} />}
        {seatInfo && seatInfo.markus && <SeatInfo infos={seatInfo.markus} styles={{}} onUpdate={seatSelectedUpdate} types={props.types} />}
        {blockSelected && <BlockSelected infos={blockSelected} onUpdate={blockSelectedUpdate} types={props.types} />}
        {help && <ShowHelp help={help} />}
        {errorApi && <ShowError message={errorApi} backLink={props.backLink} />}
        <Stage width={window.innerWidth} height={window.innerHeight} style={{cursor: 'grab'}}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onWheel={handleWheel}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onClick={(e) => {
            setBlockSelected(false);
            setSeatInfo(false);
          }}
          draggable={true}
          onDragStart={(e) => {
            stageRef.current.container().style.cursor = 'grabbing';
          }}
          onDragEnd={(e) => {
            stageRef.current.container().style.cursor = 'grab';
          }}
          ref={stageRef}>
          {!isLoading && plan &&
          <Layer ref={layerRef}>
            <Group ref={groupRef}>
            {plan.objects.stands.map((stand, index) => {
              return (
                <Stand
                  x={stand.x || 200}
                  y={stand.y || 200}
                  key={'stand_'+index}
                  mode={'creation'}
                  data={stand}
                  onDragStand={(e) => {}}
                  onDragSeat={(e) => {}}
                  onUpdateStand={(newStand) => {
                    let nextStands = [...plan.objects.stands];
                    nextStands[index] = {...newStand};
                    savePlan({...plan, objects: {...plan.objects, stands: nextStands }});
                  }}
                  onUpdateSeat={(newSeat, i) => {
                    let nextStands = [...plan.objects.stands];
                    const items = [...plan.objects.stands[index].items]
                    items[i] = {...newSeat};
                    nextStands[index] = {...plan.objects.stands[index], items: [...items]};
                    savePlan({...plan, objects: {...plan.objects, stands: nextStands}});
                  }}
                  onEdit={(e, stand) => {
                    editSeating(stand);
                  }}
                  onSelect={(e, stand) => {
                    regroupEdit();
                    setBlockSelected(stand);
                    setHelp('Element selected, to switch to the "Edit" mode, clic on the pencil on top of the element, double clic on the element or press the "E" key of your keyboard');
                  }}
                  onDelete={(id) => {
                    deleteElement(id);
                  }}
                  scale={zoomLevel}
                  isEditing={seatEditing && seatEditing.markus && stand.markus.id === seatEditing.markus.id}
                  isSelected={blockSelected && blockSelected.markus && stand.markus.id === blockSelected.markus.id}
                  selectedSeats={selectedSeats}
                  selectedSeatsIds={selectedSeats.length === 0 ? [] : selectedSeats.map((seat) => seat.attrs.id)}
                  onSelectSeat={(seats, seatsIds) => {
                    const stands = [...plan.objects.stands];
                    setSelectedSeats(seats);
                    if (seats.length === 1) {
                      const newseat = {...stands[index].items.find((seat) => seat.typeObject === 'seat' && seat.markus.id === seats[0].attrs.id)};
                      setSeatInfo(newseat);
                      setMultipleSeatsDatas(false)
                      setMultipleSeatsSelected(false);
                    } else {
                      setSeatInfo(false);
                      const seatsIds = [...seats.map((seat) => seat.attrs.id)];
                      const seatsInfos = [...stands[index].items.filter((seat) => seat.typeObject === 'seat' && seatsIds.includes(seat.markus.id))];

                      const fillColors = [];
                      const seatStatuses = [];
                      const typesSeats = [];
                      const lineColors = [];
                      const reservedReasons = [];
                      seatsInfos.map((e) => {
                        if (e.markus.fillColor && !fillColors.includes(e.markus.fillColor)){
                          fillColors.push(e.markus.fillColor);
                        }
                        if (e.markus.seatStatus && !seatStatuses.includes(e.markus.seatStatus)){
                          seatStatuses.push(e.markus.seatStatus);
                        }
                        if (e.markus.lineColor && !lineColors.includes(e.markus.lineColor)){
                          lineColors.push(e.markus.lineColor);
                        }
                        if (!reservedReasons.includes(e.markus.reservedReason || ' ')){
                          reservedReasons.push(e.markus.reservedReason || ' ');
                        }
                        if (e.markus.typeSeat && !typesSeats.includes(e.markus.typeSeat)){
                          typesSeats.push(e.markus.typeSeat);
                        }
                        return e;
                      });
                      setMultipleSeatsDatas({fillColors, seatStatuses, lineColors, reservedReasons, typesSeats});
                      setMultipleSeatsSelected(true);
                    }
                  }}
                />
              );
          })}
          {plan.objects.tables.map((table, index) => {
              return (
                <Table
                  x={table.x || 200}
                  y={table.y || 200}
                  key={'table_'+index}
                  mode={'creation'}
                  data={table}
                  onDragTable={(e) => {}}
                  onDragSeat={(e) => {}}
                  onUpdateTable={(newTable) => {
                    let nextTables = [...plan.objects.tables];
                    nextTables[index] = newTable;
                    savePlan({...plan, objects: {...plan.objects, tables: nextTables }});
                  }}
                  onUpdateSeat={(newSeat, i) => {
                    let nextTables = [...plan.objects.tables];
                    const items = [...plan.objects.tables[index].items]
                    items[i] = {...newSeat};
                    nextTables[index] = {...plan.objects.tables[index], items: [...items]};
                    savePlan({...plan, objects: {...plan.objects, tables: nextTables}});
                  }}
                  onEdit={(e, table) => {
                    editSeating(table);
                  }}
                  onSelect={(e, table) => {
                    regroupEdit();
                    setBlockSelected(table);
                    setHelp('Element selected, to switch to the "Edit" mode, clic on the pencil on top of the element, double clic on the element or press the "E" key of your keyboard');
                  }}
                  onDelete={(id) => {
                    deleteElement(id);
                  }}
                  scale={zoomLevel}
                  isEditing={seatEditing && seatEditing.markus && table.markus.id === seatEditing.markus.id}
                  isSelected={blockSelected && blockSelected.markus && table.markus.id === blockSelected.markus.id}
                  selectedSeats={selectedSeats}
                  selectedSeatsIds={selectedSeats.length === 0 ? [] : selectedSeats.map((seat) => seat.attrs.id)}
                  onSelectSeat={(seats, seatsIds) => {
                    setSelectedSeats(seats);
                    const tables = [...plan.objects.tables];
                    if (seats.length === 1) {
                      const seat = {...tables[index].items.find((seat) => seat.typeObject === 'seat' && seat.markus.id === seats[0].attrs.id)};
                      setSeatInfo(seat);
                      setMultipleSeatsDatas(false)
                      setMultipleSeatsSelected(false);
                    } else {
                      setSeatInfo(false);
                      const seatsIds = [...seats.map((seat) => seat.attrs.id)];
                      const seatsInfos = [...tables[index].items.filter((seat) => seat.typeObject === 'seat' && seatsIds.includes(seat.markus.id))];

                      const fillColors = [];
                      const seatStatuses = [];
                      const lineColors = [];
                      const reservedReasons = [];
                      seatsInfos.map((e) => {
                        if (e.markus.fillColor && !fillColors.includes(e.markus.fillColor)){
                          fillColors.push(e.markus.fillColor);
                        }
                        if (e.markus.seatStatus && !seatStatuses.includes(e.markus.seatStatus)){
                          seatStatuses.push(e.markus.seatStatus);
                        }
                        if (e.markus.lineColor && !lineColors.includes(e.markus.lineColor)){
                          lineColors.push(e.markus.lineColor);
                        }
                        if (!reservedReasons.includes(e.markus.reservedReason || ' ')){
                          reservedReasons.push(e.markus.reservedReason || ' ');
                        }
                        return e;
                      });
                      setMultipleSeatsDatas({fillColors, seatStatuses, lineColors, reservedReasons});
                      setMultipleSeatsSelected(true);
                    }
                  }}
                />
              );
          })}

          {selectedSeats.length === 0 && 
            <>
              {deleteVisible &&
                <Group x={tkrefBBox.x + (tkrefBBox.width/2)} y={tkrefBBox.y - 35} >
                  <Image image={deleteImg} listening onClick={(e) => { deleteElement()}} style={{cursor: 'pointer'}} />
                </Group>}
                <Transformer
                  ref={trRef}
                  resizeEnabled={false}
                  rotateEnabled={false}
                  borderStroke={'#7151F2'}
                  borderDash={[3, 3]}
                  boundBoxFunc={(oldBox, newBox) => {
                    // limit resize
                    if (newBox.width < 5 || newBox.height < 5) {
                      return oldBox;
                    }
                    return newBox;
                  }}
                />
              <Rect fill="rgba(113, 81, 242,0.5)" ref={selectionRectRef} />
            </>
          }
          </Group>
          </Layer>}
        </Stage>
        </div>
    </div>
  );
}
