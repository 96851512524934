import React, { useState, useEffect } from "react";

export default function SeatInfoAttendee(props) {
    const [formValues, setFormValues] = useState(props.infos);

    useEffect(() => {
        setFormValues(props.infos)
    }, [props.infos]);

    if (props.infos.type !== 'seat') {
        return null
    }

    let cat = false;
    if (props.infoPerson && props.attendantsCategories) {
        cat = props.attendantsCategories.filter((cat) => cat.id === props.infoPerson.category_id );
        if (cat.length > 0) {
            cat = cat[0];
        } else {
            cat = {id: 0, name: 'N/A', alias: 'N/A', color: '#ccc'};
        }
    }
    return (
        <div className="overflow-y-auto text-sm overflow-x-hidden absolute w-80 z-40 p-4 -translate-x-1/2 -translate-y-full bg-white rounded-xl border border-gray-300" style={props.styles || {top:0}}>
            <div className="text-center mb-3 bg-violet-940/10 rounded p-3 text-violet-940">
                <div className="mb-2">
                    {props.infos.rowName &&
                        `Row `+props.infos.rowName
                    }
                    {props.infos.colName &&
                        ` - Column `+props.infos.colName
                    }
                </div>
                <strong className="text-4xl text-violet-940">{props.infos.place}</strong>
            </div>
            {formValues.seatStatus && formValues.seatStatus === 'reserved' &&
            <div>
                <p>Reserved{formValues.reservedReason ? ': '+formValues.reservedReason : ''}</p>
                <p className="mt-4 text-red-400">This place is reserved and is not available to link to any attendant.</p>
            </div>}
            {props.infoPerson && props.infoPerson.name && props.infoPerson.id !== props.attendeeSelected &&
                <div className="text-center">
                    <p className="mb-2"><strong>{props.infoPerson.firstname+' '+props.infoPerson.name}</strong></p>
                    {cat && 
                    <p className="mb-2 whitespace-nowrap">
                        <span className="rounded-full inline-block mr-2" style={{backgroundColor: cat.color, width: '10px', height: '10px'}}></span>{cat.name.replace('_', ' ')}
                    </p>}
                </div>
            }
            {props.infoPerson && props.infoPerson.name && props.infoPerson.id === props.attendeeSelected &&
                <div className="text-center">
                    <p className="mb-2"><strong>{props.infoPerson.firstname+' '+props.infoPerson.name}</strong></p>
                    {cat && 
                    <p className="mb-2 whitespace-nowrap">
                        <span className="rounded-full inline-block mr-2" style={{backgroundColor: cat.color, width: '10px', height: '10px'}}></span>{cat.name.replace('_', ' ')}
                    </p>}
                </div>
            }
        </div>
    )

}