import React, { useState, useEffect, useCallback, useRef } from "react";
import DropZone from "react-dropzone";

export default function MarkusDropZone(props) {
  const [isDragging, setIsDragging] = useState(false);
  const dragCounter = useRef(0);

  const handleDrag = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDragIn = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter.current++;
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  }, []);

  const handleDragOut = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current > 0) return;
    setIsDragging(false);
  }, []);

  const dropFiles = (files) => {
    dragCounter.current = 0;
    setIsDragging(false);
    props.onChangeUpload(files[0])
  };

  useEffect(() => {
    window.addEventListener("dragenter", handleDragIn);
    window.addEventListener("dragleave", handleDragOut);
    window.addEventListener("dragover", handleDrag);
    return function cleanUp() {
      window.removeEventListener("dragenter", handleDragIn);
      window.removeEventListener("dragleave", handleDragOut);
      window.removeEventListener("dragover", handleDrag);
    };
  });

    let classes= 'hidden';
    if (isDragging) {
        classes = 'flex items-center fixed w-full h-full z-50 justify-center border-8 border-white border-dashed bg-violet-940 text-center text-white text-2xl font-bold m-auto';
    }
    
    return (
        <DropZone
            disableClick
            onDrop={files => {dropFiles(files)}}
            multiple={false}
        >
        {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
                <div className={classes}>
                    <input {...getInputProps()} hidden />
                    {props.children}
                </div>
            </div>
        )}
        </DropZone>
    )
}
  