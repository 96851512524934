import React from "react";

export default function Loading(props) {
    let classes = "hidden items-center justify-center h-screen overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full bg-white"
    if (props && props.visible) {
        classes = "flex items-center justify-center h-screen overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full bg-violet-940"
    }
    return (
        <div id="modalLoading" tabIndex="-1" aria-hidden="true" className={classes}>
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto ">
                <div className="relative">
                    <h3 className="animate-spin text-l text-center font-semibold text-white">
                    Loading ...
                    </h3>
                </div>
            </div>
        </div>
    )

}