import React from "react";
import BackLinkButton from 'assets/backLinkButton.svg';

export default function SeatInfos(props) {
    return (
        <div className="overflow-y-auto text-sm bg-opacity-60 text-white bg-violet-940/80 overflow-x-hidden absolute w-100 h-100 z-40 p-4 bottom-0 left-0">
            <div className="absolute top-1/2 translate-y-1/2 m-auto w-100 text-center">
                <h3 className="text-xl text-center font-semibold text-white max-w-2x m-auto">
                    {props.message || `Une erreur est survenue`}
                </h3>
                {props.backLink &&
                <a
                    href={props.backLink}
                    rel="noreferer"
                    className="inline-block mt-6 rounded-md border border-gray-300 shadow-sm p-3 leading-4 bg-white text-gray-700 hover:bg-violet-940  hover:text-white focus:outline-none align-bottom">
                    <img src={BackLinkButton} alt="Back" />
                </a>}
            </div>
        </div>
    )

}