import React, { useState, useEffect, useRef} from "react";
import { Group, Label, Text, Tag, Rect, Image } from "react-konva";
import Pencil from '../../assets/pencil.png';
import Trash from '../../assets/trash.png';
import Seat from "./Seat";

const Stand = props =>  {
  const {
    x,
    y,
    scale,
    data,
    noControl,
    //seatInfos,
    isSelected,
    isEditing,
    selectedSeats,
    selectedSeatsIds,
    onUpdateStand,
    onUpdateSeat,
    onDragStand,
    onDragSeat,
    onSelect,
    onDelete,
    onEdit,
    onHoverSeat,
    onSelectSeat,
    onDeselectSeat,
    uniqueSeat,
    mode
  } = props;

  const shapeRef = useRef();
  const [ standWidth, setStandWidth ] = useState(1);
  const [ standHeight, setStandHeight ] = useState(1);
  var editIcon = Pencil;
  var deleteIcon = Trash;
  var cloneIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAWQAAAFkBqp2phgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAC6SURBVDiN3dMxagJBGAXgT1mwCsRW0qWNmNZC0NpzWOVG6XOAINYRcoEtLLyChegBVrTY2bCZrOOm9cHjhzf//3gzzM9vvKLA+Qq3GNQHupHBI77RaeAMT/iqm2Sh9rHAszR2IeEaY+yrBCO84SExnOMDS/QwrB9OQ7Sq3kLV+3OFJszxEmkbrOpCyiBTvk2yP2VQ4NCgtTa4gwSfgUnEX/nfiBMcMVEuTgqn0PvHIG/QWuHWGld8jwcvV/Es3+ygdpMAAAAASUVORK5CYII=";
  var editImg = document.createElement('img');
  editImg.src = editIcon;
  var deleteImg = document.createElement('img');
  deleteImg.src = deleteIcon;
  var cloneImg = document.createElement('img');
  cloneImg.src = cloneIcon;

  useEffect(() => {
      setStandWidth(shapeRef.current.getClientRect().width/(scale || 1));
      setStandHeight(shapeRef.current.getClientRect().height/(scale || 1));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.items.length]);

  return (
    <>
    <Group x={x} y={y}
        name={'stand'}
        id={data.markus.id}
        draggable={isSelected && !isEditing}
        onDragStart={() => {}}
        onDragEnd={(e) => {
            if (isSelected && !isEditing) {
                e.cancelBubble = true;
                const newData = {...data, x: e.target.x(), y: e.target.y()};
                if (onUpdateStand) {
                    onUpdateStand(newData);
                }
                onDragStand(e);
            }
        }}
        onDragMove={(e) => {
            if (isSelected && !isEditing) {
                e.cancelBubble = true;
                onDragStand(e);
            }
        }}
        onMouseEnter={e => {
            const stage = e.target.getStage();
            const container = stage.container();
            container.style.cursor = "pointer";
            stage.setDraggable(false);
        }}
        onMouseLeave={e => {
            const stage = e.target.getStage();
            const container = stage.container();
            container.style.cursor = "grab";
            stage.setDraggable(true);
        }}>
        {!noControl && <Group x={0} y={-35/(scale || 1)}>
            {isSelected && 
            <Image image={editImg} x={(standWidth/2)-(14/scale)} scaleX={1/scale} scaleY={1/scale} listening onClick={(e) => {if (onEdit) onEdit(e, data)}}  />}
            {(isSelected || (isEditing && selectedSeatsIds.length > 0)) &&
            <Image image={deleteImg} x={(standWidth/2)+((isEditing ? 0 : 14)/scale)} scaleX={1/scale} scaleY={1/scale} listening onClick={(e) => {if (onDelete) onDelete(isEditing ? false : data.markus.id)}} style={{cursor: 'pointer'}} />}
        </Group>}
        <Rect height={standHeight+25} width={standWidth+25} fill={'#7151F2'} opacity={isSelected ? .1 : 0} x={0} y={0} onClick={(e) => {
                if (isEditing) {
                    return;
                }
                e.cancelBubble = true;
                if (onSelect) {
                    onSelect(e, data)
                }
            }}
            onDblclick={(e) => {
                if (isEditing) {
                    return;
                }
                e.cancelBubble = true;;
                if (onEdit)
                    onEdit(e, data)
            }}
            onTap={(e) => {
                if (isEditing) {
                    return;
                }
                e.cancelBubble = true;
                if (onSelect)
                    onSelect(e, data)
            }} 
            onMouseEnter={e => {
                const container = e.target.getStage().container();
                container.style.cursor = "pointer";
            }}
            onMouseLeave={e => {
                const container = e.target.getStage().container();
                container.style.cursor = "";
            }}/>
        <Group
            onClick={(e) => {
                if (isEditing) {
                    return;
                }
                e.cancelBubble = true;
                if (onSelect)
                    onSelect(e, data)
            }}
            onDblclick={(e) => {
                if (isEditing) {
                    return;
                }
                e.cancelBubble = true;;
                if (onEdit)
                    onEdit(e, data)
            }}
            onTap={(e) => {
                if (isEditing) {
                    return;
                }
                e.cancelBubble = true;
                if (onSelect)
                    onSelect(e, data)
            }}
            ref={shapeRef}>
            {data.items.map((item, rowIndex) => {
                if (item.typeObject === 'text') {
                    return(
                        <Label text={item.text} align="center" y={item.top} x={item.left} padding={4} key={'text_'+rowIndex}>
                            <Tag fill={'#7151F2'}></Tag>
                            <Text text={item.text} padding={4} fontFamily={'Arial'} fontSize={14} fill={'white'}></Text>
                        </Label>
                    );
                }
                return (
                    <Seat
                        key={item.markus.id}
                        mode={mode || 'creation'}
                        x={item.left}
                        y={item.top}
                        data={item}
                        index={rowIndex}
                        onHover={onHoverSeat}
                        onSelect={onSelectSeat}
                        onDeselect={onDeselectSeat}
                        onDragSeat={onDragSeat}
                        selectedSeats={selectedSeats}
                        selectedSeatsIds={selectedSeatsIds}
                        onUpdateSeat={onUpdateSeat}
                        isGroupEditing={isEditing}
                        uniqueSeat={uniqueSeat}
                        isSelected={item && item.markus && selectedSeatsIds && selectedSeatsIds.length > 0 && selectedSeatsIds.includes(item.markus.id)}
                    />
                );
            })}
            </Group>
        </Group>
    </>
  );
};

export default Stand;