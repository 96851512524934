import React, { useState, useEffect } from "react";

export default function BlockSelected(props) {
    const [title, setTitle] = useState(``);
    const [code, setCode] = useState(``);
    const [nbSeats, setNbSeats] = useState(0);
    var slugify = require('slugify')
    useEffect(() => {
        if (props.infos.items && props.infos.items.length > 0) {
            const text = props.infos.items.find((object) => object.typeObject === 'text');
            if (text && text.text) {
                setTitle(text.text);
            }
            const seats = props.infos.items.filter((object) => object.typeObject === 'seat');
            setNbSeats(seats.length)
        }
        if (props.infos && props.infos.markus && props.infos.markus.code) { 
            setCode(props.infos.markus.code)
        }
    }, [props.infos]);

    let classes = "overflow-y-auto overflow-x-hidden absolute bottom-0 w-80 left-0 z-40 h-modal p-4 "

    const blockSelectedUpdate = (data) => {
        const keys = Object.keys(data);
        if (keys.includes('code')) {
            setCode(data.code);
        }
        if (keys.includes('title')) {
            setTitle(data.title);
        }
        return props.onUpdate(data);
    }
    
    return (
        <div id="blockselectedHelper" tabIndex="-1" aria-hidden="true" style={props.styles || {}} className={classes}>
            <div className="relative max-w-2xl">
                <div className="relative rounded-lg shadow bg-white ">
                    <div className="grid grid-cols-1 gap-3 p-3">
                        <label className="block relative">
                            <span className="absolute block text-gray-700 translate-y-2 pl-3 text-sm">Title</span>
                            <input type="text" className="
                                pt-7
                                block
                                w-full
                                rounded-md
                                border-gray-300
                                shadow-sm
                                focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                value={title}
                                onChange={(e) => blockSelectedUpdate({title: e.target.value})} />
                        </label>
                        <label className="block relative">
                            <span className="absolute block text-gray-700 translate-y-2 pl-3 text-sm">Code</span>
                            <input type="text" className="
                                pt-7
                                block
                                w-full
                                rounded-md
                                border-gray-300
                                shadow-sm
                                focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                value={slugify(code)}
                                onChange={(e) => blockSelectedUpdate({code: slugify(e.target.value)})} />
                        </label>
                        {props.types && props.types.length > 0 && (
                        <label className="block mr-1">
                                <span className="text-gray-700">Seats type</span>
                                <select className="
                                    block
                                    w-full
                                    mt-1
                                    rounded-md
                                    border-gray-300
                                    shadow-sm
                                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                                "
                                name={`seatsType`}
                                value={props.infos.markus.typeSeat || ``}
                                onChange={(e) => blockSelectedUpdate({seatsType: e.target.value})}>
                                    <option value={``}>-</option>
                                    {props.types.map((type, index) => (
                                        <option key={`type_${index}`} value={type}>{type}</option>
                                    ))}
                                </select>
                            </label>
                        )}
                        {props.infos.markus && props.infos.markus.typeObject === 'stand' &&
                            <label className="block relative">
                                <span className="absolute block text-gray-700 translate-y-2 pl-3 text-sm">Rows number</span>
                                <input type="number" className="
                                    pt-7
                                    block
                                    w-full
                                    rounded-md
                                    border-gray-300
                                    shadow-sm
                                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    min={1}
                                    max={30}
                                    value={props.infos.markus.rows}
                                    onChange={(e) => blockSelectedUpdate({rowNumber: e.target.value})} />
                            </label>
                        }
                        {props.infos.markus && props.infos.markus.typeObject === 'stand' &&
                            <label className="block relative">
                                <span className="absolute block text-gray-700 translate-y-2 pl-3 text-sm">Columns number</span>
                                <input type="number" className="
                                    pt-7
                                    block
                                    w-full
                                    rounded-md
                                    border-gray-300
                                    shadow-sm
                                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    min={1}
                                    max={30}
                                    value={props.infos.markus.cols}
                                    onChange={(e) => blockSelectedUpdate({colNumber: e.target.value})} />
                            </label>
                        }
                        {props.infos.typeObject === 'table' &&
                            <label className="block relative">
                                <span className="absolute block text-gray-700 translate-y-2 pl-3 text-sm">Table format</span>
                                <select type="text" className="
                                    pt-7
                                    block
                                    w-full
                                    rounded-md
                                    border-gray-300
                                    shadow-sm
                                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    value={props.infos.markus.tableType}
                                    onChange={(e) => blockSelectedUpdate({typeTable: e.target.value})}>
                                        <option value="round">Round</option>
                                        <option value="rectangular">Rectangular</option>
                                </select>
                            </label>
                        }
                        {props.infos.typeObject === 'table' && props.infos.markus.tableType === 'round' &&
                            <label className="block relative">   
                                 <span className="absolute block text-gray-700 translate-y-2 pl-3 text-sm">Number of seats</span>
                                 <input type="number" className="
                                    pt-7
                                    block
                                    w-full
                                    rounded-md
                                    border-gray-300
                                    shadow-sm
                                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    min={1}
                                    max={20}
                                    value={nbSeats}
                                    onChange={(e) => blockSelectedUpdate({seatNumber: e.target.value})} />
                            </label>
                        }
                        {props.infos.typeObject === 'table' && props.infos.markus.tableType !== 'round' &&
                        <div>
                            <p className="text-gray-400 text-sm">Number of seats</p>
                            <div className="flex">
                                <label className="block relative pr-1">   
                                    <span className="block text-gray-700 text-sm whitespace-nowrap">Seats on X (rows)</span>
                                    <input type="number" className="
                                        block
                                        w-full
                                        rounded-md
                                        border-gray-300
                                        shadow-sm
                                        focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        min={1}
                                        max={20}
                                        value={props.infos.markus.xSeats}
                                        onChange={(e) => blockSelectedUpdate({seatNumberX: e.target.value})} />
                                </label>
                                <label className="block relative pl-1">   
                                    <span className="block text-gray-700 text-sm whitespace-nowrap">Seats on Y (columns)</span>
                                    <input type="number" className="
                                        block
                                        w-full
                                        rounded-md
                                        border-gray-300
                                        shadow-sm
                                        focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        min={1}
                                        max={20}
                                        value={props.infos.markus.ySeats}
                                        onChange={(e) => blockSelectedUpdate({seatNumberY: e.target.value})} />
                                </label>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}