import React, { useState } from "react";
import { TwitterPicker } from 'react-color';

export default function SeatMultipleInfos(props) {
    const [showFillColor, setShowFillColor] = useState(false);
    const [fillColor, setFillColor] = useState(props.multipleSeatDatas && props.multipleSeatDatas.fillColors && props.multipleSeatDatas.fillColors.length === 1 ? props.multipleSeatDatas.fillColors[0] : '#d40000' );

    const seatInfoUpdate = (data) => {
        return props.onUpdate(data);
    }

    return (
        <div className="absolute w-80 z-40 p-4 bottom-0 left-0 m-10 bg-white rounded-xl border border-gray-300" style={props.styles || {}}>
            <p className="text-center mb-3">
                <strong className="text-4xl">Multiple seats</strong>
            </p>
            <div className="mt-3">
                <div>
                    <label className="block relative">
                        <span className="absolute block text-gray-700 translate-y-2 pl-3 text-sm">Status</span>
                        <select className="block
                            pt-7
                            w-full
                            rounded-md
                            border-gray-300
                            shadow-sm
                            focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        name={`seatStatus`}
                        value={props.multipleSeatDatas && props.multipleSeatDatas.seatStatuses && props.multipleSeatDatas.seatStatuses.length === 1 ? props.multipleSeatDatas.seatStatuses[0] : '-'}
                        onChange={(e) => seatInfoUpdate({seatStatus: e.target.value})}>
                            {props.multipleSeatDatas && props.multipleSeatDatas.seatStatuses && props.multipleSeatDatas.seatStatuses.length > 0 && <option value={`-`}>-</option>}
                            <option value={`active`}>Active</option>
                            <option value={`reserved`}>Reserved</option>
                        </select>
                    </label>
                    <div className="pt-2">
                        <span className="absolute block text-gray-700 translate-y-2 pl-3 text-sm">Reason of reservation</span>
                        <input type="text" className="
                            pt-7
                            block
                            w-full
                            rounded-md
                            border-gray-300
                            shadow-sm
                            focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            defaultValue={props.multipleSeatDatas && props.multipleSeatDatas.reservedReasons && props.multipleSeatDatas.reservedReasons.length === 1 ? props.multipleSeatDatas.reservedReasons[0].trim() : ''}
                            onBlur={(e) => seatInfoUpdate({reservedReason: e.target.value})} />
                    </div>
                </div>
                {props.types && props.types.length > 0 &&
                    <label className="block relative mt-2">
                        <span className="absolute block text-gray-700 translate-y-2 pl-3 text-xs">Type</span>
                        <select className="block
                            pt-7
                            w-full
                            rounded-md
                            border-gray-300
                            shadow-sm
                            focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            name={`type`}
                            value={props.multipleSeatDatas && props.multipleSeatDatas.typesSeats && props.multipleSeatDatas.typesSeats.length === 1 ? props.multipleSeatDatas.typesSeats[0] : '-'}
                            onChange={(e) => seatInfoUpdate({typeSeat: e.target.value})}>
                                <option value={''}>-</option>
                            {props.types.map((type, index) => 
                                 (<option key={index} value={type}>{type}</option>))
                            }
                        </select>
                    </label>
                }
                {props.multipleSeatDatas && props.multipleSeatDatas.seatStatuses && props.multipleSeatDatas.seatStatuses.length === 1 && props.multipleSeatDatas.seatStatuses[0] === 'reserved' &&
                <label className="block relative mt-2">
                    <div className="flex">
                        <div className="h-16 w-20 rounded-lg cursor-pointer border border-gray-300" style={{backgroundColor: fillColor}} onClick={(e) => setShowFillColor(!showFillColor)}></div>
                        <div className="pl-2 w-full">
                            <span className="absolute block text-gray-700 translate-y-2 pl-3 text-sm">Main color</span>
                            <input type="text" className="
                                pt-7
                                block
                                w-full
                                rounded-md
                                border-gray-300
                                shadow-sm
                                focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                value={fillColor}
                                readOnly={true}
                                onBlur={(e) => {
                                    if(e.target.value !== fillColor) {
                                        setFillColor(e.target.value);
                                        seatInfoUpdate({fillColor: e.target.value})
                                    }
                                }} />
                        </div>
                    </div>
                    <div className={showFillColor ? 'block mt-2' : 'hidden'}>
                        <TwitterPicker color={fillColor} onChange={ (color, event) => {
                            setFillColor(color.hex);
                            seatInfoUpdate({fillColor: color.hex});
                        }} height={100} />
                    </div>
                </label>}
            </div>
        </div>
    )

}