import React from "react";

export default function HistoryLoading(props) {
    let classes = "hidden items-center justify-center h-screen overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full bg-white"
    if (props && props.visible) {
        classes = "flex items-center justify-center h-screen overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full bg-violet-940/50"
    }
    return (
        <div id="modalLoading" tabIndex="-1" aria-hidden="true" className={classes}>
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto ">
                <div className="relative">
                    <h3 className="text-xl text-center font-semibold text-white">
                    {props.message || `Modifying the history`}
                    </h3>
                </div>
            </div>
        </div>
    )

}