import React, { useState, useEffect, useRef } from "react";
import { Tooltip } from 'react-tooltip';
import { Stage, Layer, Group } from 'react-konva';
import Konva from 'konva';
import { zoomHigh, zoomLow, zoomIncrement } from 'constants.js';
import { jsPDF } from "jspdf";
import {invertColor} from "lib/invertColor";
import MarkusDropZone from "components/forms/dropZone";
import autoTable from 'jspdf-autotable';
import Loading from "components/loading";
import ShowHelp from "components/infos/showHelp";
import ShowError from "components/infos/showError";
import SeatInfoAttendee from "components/infos/seatInfoAttendee";
import Print from 'assets/print.svg';
import ExternalLink from 'assets/externalLink.svg';
import BackLinkButton from 'assets/backLinkButton.svg';
import ZoomIn from 'assets/zoomIn.svg';
import ZoomOut from 'assets/zoomOut.svg';
import Center from 'assets/center.svg';
import Import from 'assets/import.svg';
import Stand from 'components/konva/Stand';
import Table from 'components/konva/Table';
import "App.css";
import 'react-tooltip/dist/react-tooltip.css'

export default function Placement(props) {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ seatInfo, setSeatInfo ] = useState(false);
  const [ seatInfoPerson, setSeatInfoPerson ] = useState(false);
  const [ seatInfosStyles, setSeatInfosStyles ] = useState({});
  const [ errorApi, setErrorApi] = useState(props.errorMessage || false);
  const [ allEntities, setAllEntities ] = useState([]);
  const [ documentName, setDocumentName ] = useState('My new plan');
  const [ help, setHelp ] = useState('Please select an attendee in the left column.');
  const [ selectedSeatsIds, setSelectedSeatsIds ] = useState([])
  const [ searchAttendee, setSearchAttendee ] = useState('');
  const [ filterAttendee, setFilterAttendee ] = useState('');
  const [ filterCategory, setFilterCategorie ] = useState('');
  const [ filterType, setFilterType ] = useState('');
  const [ filterEntity, setFilterEntity ] = useState('');
  const [ filterConfirmationStatus, setFilterConfirmationStatus ] = useState('');
  const [ attendeeSelected, setAttendeeSelected ] = useState('');
  const [ assignedPlaces, setAssignedPlaces ] = useState([]);
  const [ zoomLevel, setZoomLevel ] = useState(1);
  const [ place, setPlace ] = useState(false);
  const [ plan, setPlan ] = useState({objects:{stands: [], tables: []}, title: 'My new plan'});
  const [ seatsTotal, setSeatsTotal ] = useState(0);
  const [ seatsReservedTotal, setSeatsReservedTotal ] = useState(0);
  const [ seatsBookedTotal, setSeatsBookedTotal ] = useState(0);
  const [ seatsAtDisposition, setSeatsAtDisposition ] = useState(0);
  const [ attendeesTotal, setAttendeesTotal ] = useState(0);
  const [ attendeesPlaced, setAttendeesPlaced ] = useState(0);
  
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0
  })

  var slugify = require('slugify')
  var lastDist = 0;

  const containerRef = useRef(null)
  const stageRef = useRef();
  const layerRef = useRef();
  const groupRef = useRef();

  const canvasContainerId = "#canvas-container";

  const handleResize = () => {
    if (stageRef && stageRef.current) {
      const nodeFinal = document.querySelector(canvasContainerId);
      stageRef.current.height(nodeFinal.offsetHeight);
    }
  }

  useEffect(() => {
    Konva.hitOnDragEnabled = true;
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  useEffect(() => {
    // INIT
    _onReady()
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const countElements = async (newPlan, force, assignedPlacesSaved) => {
    let seats = 0;
    let seatsReserved = 0;
    let seatsBooked = 0;
    let seatsAtdisposition = 0;
    const objects = newPlan.objects.tables.concat(newPlan.objects.stands);
    for (let i in objects) {
      var object = objects[i];
      seats += object.items.filter((subobject) => subobject.typeObject && subobject.typeObject === 'seat').length;
      seatsReserved += object.items.filter((subobject) => subobject.typeObject && subobject.typeObject === 'seat' && subobject.markus.seatStatus && subobject.markus.seatStatus === 'reserved').length;
      seatsBooked += object.items.filter((subobject) => subobject.typeObject && subobject.typeObject === 'seat' && subobject.markus.seatStatus && subobject.markus.seatStatus === 'booked').length;
    }
    seatsAtdisposition = seats - seatsReserved - seatsBooked;
    setSeatsTotal(seats);
    setSeatsReservedTotal(seatsReserved);
    setSeatsBookedTotal(seatsBooked);
    setSeatsAtDisposition(seatsAtdisposition);
    setAttendeesTotal(props.attendantsArray.length);
    setAttendeesPlaced(assignedPlacesSaved.length);
  }

  const getHeight = () => {
    return window.innerHeight;
  }

  const getWidth = () => {
    var list_width = document.querySelector('#list-persons') ? document.querySelector('#list-persons').offsetWidth : 0;
    var available_width = window.innerWidth - list_width;
    return available_width;
  }

  const reCenter = (pointToObject, zoomLevel=1) => {
    setZoomLevel(zoomLevel);
    stageRef.current.scale({ x: zoomLevel, y: zoomLevel});
    if (stageRef && stageRef.current) {
      const nodeFinal = document.querySelector(canvasContainerId);
      stageRef.current.width(nodeFinal.offsetWidth);
      stageRef.current.height(nodeFinal.offsetHeight);
    }
    
    let groupPosition = groupRef.current.getClientRect();
    if (pointToObject && pointToObject.markus && pointToObject.markus.id) {
      const object = stageRef.current.find('#'+pointToObject.markus.id);
      if (object && object.length > 0) {
        groupPosition = object[0].getClientRect();
      }
    }
    var pointTo = {
      x: (stageRef.current.x() - groupPosition.x + (getWidth()/2) - (groupPosition.width / 2)),
      y: (stageRef.current.y() - groupPosition.y+ (getHeight()/2) - (groupPosition.height / 2))
    };
    stageRef.current.position(pointTo);

    if (selectedSeatsIds.length > 0) {
      const current = stageRef.current.find('#'+selectedSeatsIds[0]);
      if (current && current.length === 1) {
        const position = getAbsolutePosition_konva(current[0]);
        setSeatInfosStyles(position);
      }
    }
  }

  useEffect(() => {
    if (!isLoading) {
      reCenter();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleWheel = (e) => {
    e.evt.preventDefault();
    setZoom(e.target.getStage(), e.evt.deltaY, true, e.evt)
  }
  const handleTouchEnd = (e) => {
    lastDist = 0;
  }
  const handleTouchMove = (e) => {
    var touch1 = e.evt.touches[0];
    var touch2 = e.evt.touches[1];
    if (touch1 && touch2) {
      e.evt.preventDefault();
      const stage = e.target.getStage();
      if (stage.isDragging()) {
        stage.stopDrag();
      }
      var dist = touch1.clientX - touch2.clientX;
      if (touch1.clientX < touch2.clientX) {
        dist = touch2.clientX - touch1.clientX;
      }
      if (!lastDist) {
        lastDist = dist;
      }
      if (dist === lastDist )
        return;
      setZoom(e.target.getStage(), dist > lastDist ? 1 : 0, true, e.evt);
      lastDist = dist;
    } else {
      lastDist = 0;
    }
  }

  const handleDragStage = (e) => {
    if (selectedSeatsIds.length > 0) {
      const current = stageRef.current.find('#'+selectedSeatsIds[0]);
      if (current && current.length === 1) {
        const position = getAbsolutePosition_konva(current[0]);
        setSeatInfosStyles(position);
      } else {
        setSeatInfosStyles({});
      }
    } else {
      setSeatInfosStyles({});
    }
  }

  const setZoom = (stage, increment, zoomToPointer = false, evt) => {
    var scaleBy = zoomIncrement;
    var oldScale = stage.scaleX();
  
    // how to scale? Zoom in? Or zoom out?
    let direction = increment  > 0 ? 1 : -1;

    // when we zoom on trackpad, e.evt.ctrlKey is true
    // in that case lets revert direction
    if (evt && evt.ctrlKey) {
      direction = -direction;
    }

    var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    if (newScale > zoomHigh || newScale < zoomLow)
      return;
    
    stage.scale({ x: newScale, y: newScale });

    if (zoomToPointer) {
      var pointer = stage.getPointerPosition();

      var mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };
      var newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      stage.position(newPos);
    }
    setZoomLevel(newScale);
    setSeatInfosStyles({})
  }

  const zoomPlus = () => {
    setZoom(stageRef.current, 10, false)
  }

  const zoomMoins = () => {
    setZoom(stageRef.current, -10, false)
  }

  const onKeyDown = (e) => {
    if (e.target && e.target.nodeName === 'INPUT') {
      return
    }
    if (e.key === '+') {
      zoomPlus()
      return false;
    }
    if (e.key === '-') {
      zoomMoins()
      return false;
    }
  }

  const get_all_seats = (planLoaded) => {
    if (!planLoaded) {
      planLoaded = plan;
    }
    let AllSeats = [];
    planLoaded.objects.stands.map((stand) => {
      AllSeats = AllSeats.concat(stand.items);
      return stand.items;
    });
    planLoaded.objects.tables.map((table) => {
      AllSeats = AllSeats.concat(table.items);
      return table.items;
    });
    AllSeats = AllSeats.length > 0 ? AllSeats.filter((seat) => seat.typeObject === 'seat') : AllSeats;
    return AllSeats;
  }

  const fillPlan = (plan, places) => {
    return [
      plan.objects.stands.map((stand) => {
        stand.items = stand.items.map((item) => {
          if (item.typeObject === 'seat'){
            if (places) {
              const is_assigned = places.filter((place) => place.seat_id === item.markus.id);
              if (is_assigned && is_assigned.length > 0) {
                const user_id = is_assigned[0].user_id;
                const attendants = [...new Set(props.attendantsArray.map(item => item))]; // [ 'A', 'B']
                const attendee = attendants && attendants.find((attendee) => attendee.id === user_id);
                if (props.attendantsCategories && attendee && !(item.markus.seatStatus && item.markus.seatStatus === 'reserved')) {
                  item.markus.seatStatus = 'booked';
                  const categorie = getCat(attendee.category_id);
                  if (categorie.color) {
                    item.markus.fillColor = categorie.color;
                    item.markus.lineColor = invertColor(categorie.color, true);
                    item.markus.textColor = invertColor(categorie.color, true);
                  }
                }
                if (attendee) {
                  item.markus.attendee = attendee;
                }
              } else {
                if (!(item.markus.seatStatus && item.markus.seatStatus === 'reserved')) {
                  item.markus.seatStatus = 'active';
                  if (item.markus.attendee) {
                    delete item.markus.fillColor;
                    delete item.markus.lineColor;
                    delete item.markus.textColor;
                  }
                }
                if (item.markus.attendee) {
                  delete item.markus.attendee;
                }
              }
            }
            item.markus.hoverCursor = 'pointer'
          }
          if (item.typeObject === 'seat' && item.markus.seatStatus && item.markus.seatStatus === 'reserved') {
            item.markus.hoverCursor = 'not-allowed';
          }
          return item;
        });
        return stand;
      }),
      plan.objects.tables.map((table) => {
        table.items = table.items.map((item) => {
          if (item.typeObject === 'seat'){
            if (places) {
              const is_assigned = places.filter((place) => place.seat_id === item.markus.id);
              if (is_assigned && is_assigned.length > 0) {
                item.markus.seatStatus = 'booked';
                const user_id = is_assigned[0].user_id;
                const attendants = [...new Set(props.attendantsArray.map(item => item))]; // [ 'A', 'B']
                const attendee = attendants && attendants.find((attendee) => attendee.id === user_id);
                if (props.attendantsCategories && attendee && !(item.markus.seatStatus && item.markus.seatStatus === 'reserved')) {
                  const categorie = getCat(attendee.category_id);
                  if (categorie.color) {
                    item.markus.fillColor = categorie.color;
                    item.markus.lineColor = invertColor(categorie.color, true);
                    item.markus.textColor = invertColor(categorie.color, true);
                  }
                }
                if (attendee) {
                  item.markus.attendee = attendee;
                }
              } else {
                if (!(item.markus.seatStatus && item.markus.seatStatus === 'reserved')) {
                  item.markus.seatStatus = 'active';
                  if (item.markus.attendee) {
                    delete item.markus.fillColor;
                    delete item.markus.lineColor;
                    delete item.markus.textColor;
                  }
                }
                if (item.markus.attendee) {
                  delete item.markus.attendee;
                }
              }
            }
            item.markus.hoverCursor = 'pointer'
          }
          if (item.typeObject === 'seat' && item.markus.seatStatus && item.markus.seatStatus === 'reserved') {
            item.markus.hoverCursor = 'not-allowed';
          }
          return item;
        });
        return table;
      })
    ]
  }

  const _onReady = () => {
    if (containerRef.current?.offsetHeight && containerRef.current?.offsetWidth) {
      setDimensions({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight
      })
    }
    var plan = localStorage.getItem('plan_konva');
    if (props.planToLoad) {
      plan = props.planToLoad;
    }
    if (plan !== null) {
      try {
        if (typeof plan !== 'object') {
          plan = JSON.parse(plan);
        }
        if (!plan.objects || (!plan.objects.stands || !plan.objects.tables) || !(plan.objects.stands.length > 0 || plan.objects.tables.length > 0) ) {
          alert('Please fulfill your empty plan before placing people.')
          if (props.backLink) {
            window.location.replace(props.backLink);
            return false;
          }
        }
        if (!plan.objects) {
          plan.objects = {stands: [], tables: []};
        }
        if (!plan.objects.stands) {
          plan.objects.stands = [];
        }
        if (!plan.objects.tables) {
          plan.objects.tables = [];
        }
        setPlan(plan);
        if (plan.title) {
          setDocumentName(plan.title)
        }
        var places = JSON.parse(localStorage.getItem('assignedPlaces_konva')) || [];
        if (props.assignedPlaces) {
          places = props.assignedPlaces;
        }
        if (places.assigned) {
          places = places.assigned;
        }
        if (places.length === 0 && props.attendantsArray && props.attendantsArray.length > 0) {
          places = props.attendantsArray.filter((att) => !!att.seat_id).map((user) => {
            return {
              user_id: user.id,
              seat_id: user.seat_id
            }
          });
        }
        const finalPlaces = [];
        const AllSeats = get_all_seats(plan);
        places.map((place) => {
          const seat = AllSeats.find((seat) => seat.markus.id === place.seat_id);
          if (seat) {
            if (props.attendantsArray) {
              const attendantPlace = props.attendantsArray.find((attendant) => attendant.id === place.user_id);
              if (attendantPlace) {
                place.seat_name = seat.markus.place;
                if (seat.markus.standName) {
                  place.stand_name = seat.markus.standName;
                }
                finalPlaces.push(place);
              }
            }
          }
          return place
        });
        
        if (finalPlaces.length !== places.length) {
          save(finalPlaces);
        }

        if (finalPlaces) {
          setAssignedPlaces(finalPlaces);
        }

        if (props.attendantsArray && props.attendantsArray.length > 0) {
          const entities = [...new Set(props.attendantsArray.map(item => item.entity ? item.entity : ''))]; // [ 'A', 'B']
          setAllEntities(entities.filter((entity) => entity !== ''));
        }
        let newPlan = plan;
        const [fillstands, filltables] = fillPlan(newPlan, finalPlaces);
        newPlan.objects.stands = fillstands;
        newPlan.objects.tables = filltables;
        setPlan({...newPlan})
        countElements(newPlan, false, finalPlaces);
        setTimeout(function(){setIsLoading(false)}, 500);
      } catch(e) {
        console.log(e);
        setTimeout(function(){setIsLoading(false)}, 500);
      }
    }
  }

  const save = async(assignedPlacesSave) => {
    if (Array.isArray(assignedPlacesSave)) {
      const deleted = props.attendantsArray.filter((attendant) => !assignedPlacesSave.find((assigned) => assigned.user_id === attendant.id)).map((attendee) => attendee.id);
      if (props.onSave && typeof props.onSave === 'function' && !errorApi) {
        const saveOk = await props.onSave({assigned: assignedPlacesSave, notassigned: deleted});
        if (saveOk && saveOk.status && saveOk.status > 400) {
          setErrorApi('Une erreur est survenue lors de la sauvegarde. Merci de recharger votre page.');
        } else {
          if (errorApi) {
            setErrorApi(false);
          }
        }
        if (props.forceToLocalStorage) {
          localStorage.setItem('assignedPlaces_konva', JSON.stringify({assigned: assignedPlacesSave, notassigned: deleted}));
        }
      } else if (!errorApi){
        localStorage.setItem('assignedPlaces_konva', JSON.stringify({assigned: assignedPlacesSave, notassigned: deleted}));
      }
      countElements(plan, true, assignedPlacesSave);
    }
  }

  const print = () => {
      setTimeout(function() {
        const stage = groupRef.current;
        let width = stage.getClientRect().width+100;
        let height = stage.getClientRect().height+150;
        let pdf;
        if (width > height){
          pdf = new jsPDF('l', 'px', [width, height]);
        }
        else {
          pdf = new jsPDF('p', 'px', [height, width]);
        }
        pdf.setProperties({
          title: documentName,
          subject: 'Plan for mark.us ('+documentName+')',
          author: 'Mark.us feat Apsulis',
          keywords: 'plan, mark.us',
          creator: 'Markus Seat Planner'
        });

        width = pdf.internal.pageSize.getWidth();
        height = pdf.internal.pageSize.getHeight();

        const logo = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGoAAAAWCAIAAACno556AAAFbUlEQVR4Ae2Yg5skWQzA9+9Y27Zt27Zt2xrbtm3btu02qy+zfd/rdDVOde76spgkT79JKnk1QPYPflgs1pAhg4i8fPnin7ZDHT4dPh0+HT4dPqYeHT6RSKTJJBQKf+Xs4h+Pdh/Nq/+z8FGUjMOiJGJlfOfPnyMSHh7GZrM/fHg/f/5c2O7o0SMPHTqYnp4md83Ozj5x4vjYsaPBNHnyxNu3b7W1takuw+Px7O3tjh49PHXqZPmxJ0wYf+TIIV9fH6lUij0bGxvv3btLpKampqqq6tSpk7Du0KGDgZ0WfLD0pUsX8eajo6O1HJ7Lpmw/8YhkxYuw1VmfT0yx/kJs4nGoEGfB+yuc23tYN3b0y9uLHC9zflujtB8f3t/nz5/Wrl2NNSAjRgyLjIzw9PSA/9BMc+bMAgR4sdDQkOnTp2EfLOfOnZFIJMQ5Pz8PW93cXAlxEAhbTfjAtHv3Tmw6fPig9jDv7aLg5EQCHQXY+uAQm5jsPvOIHhi9PMMhJiy3drEiPIVK+CBM1B4bTjVq1Ai1ptOnT5HFenp6pk+fCkotYmNjrQkfHgsxriV5nz9/hvXLly/r6+sDPbP4IFs/XqezI3JzJyspRKTAR2TevDmzZ8/EGmyaOFEJ8bBhQ3AK5+XlTZgwDvSLFi2Al4CTk+Pbt2+mTJlE/JcuXawJH5ZZs2ZowgcBDqmNf7WQ9aBnHF9NiQTHGgwpTBcnhojeXe5nGuUtpCcviLm5GWghxa5evUwzubu7yRPn4MEDWB8TE4O3kpSU9P37N5xKmZmZ2L+pqUkTvoULF0REhLe2tra0tKjFV1lZCSmCXyywHFmIWXyZsSKifHWeQ5xFQlleyo/TEXwkNCiKkhsKCvKxaevWzWQ8vAqxycHBXu2OgQIASkiIDw4Oxu/N+Pg4jA/HUUdHh5bGBcrLypUrsMbJyYk4M46vJFuMhxg94ULodbej6kfDd/HiBWKAk2DTo0cPiam4uJgesOjJysq6du0KLgI08fPzVYsP0lx730erXU+ePEa+zOMTi2Rq68anGxwITIpSwQe9CJmxq6tL09kqKsrV4oO+5Nmzp5qo4QpL8GkOJYJPY2Vbt24NrQNlvPI2VElen1dfPSxe86QSRvE5Ojpg/apVK16/fgVKLy/PBQvmE72rq4tafM7OztrxPX36ZPPmjVgDJfj34fOxUuCDVurWbpYqPhKDyWEik+c80vcRiQ8UMolv48YNRHnr1k3c4s2YMf2P44PSAUtDU000UIKh1NBR9TTJCkNl+UGyjmqi43OV8Jm/4imOUyBBJjo+PENmnOjJcUWcGj/lMokPtzs4E+GEoGEEHyj19fVo3aLS5ae7XhalJ4v8/rO0lhPLwyOKk9/ezSrOEoOyvVn6/ipHE76WeqnpC15PJ0U0bkZ84qn3gFF8OLPmzp3t4uIcFRUJN5kxY0YxiA9aIloKw82SNAyyXH8FO5AsTzIbBAst++4fJEDV4OtqlT47xZa7uZvwoez62Qju7VcMcTXiM4kPbhRYj4VBfPDgFKZX/2xvJXwZ7mQ2aNY03R8eH6WXDgGfen0BRyVd4HVZVyFhEh+87KD1UeV15swpuEoziA8eIyNDWk+Tk5PTb2gtVcLXVIAn9DTjq7KL9RPqP+CqRl9OovjxMXp4ktxPixKBzwDgQiQoKJCsxOVysQl/z4CWEJvS0lLxFsPCQuH7x/r1a5ctWwLfXaDsQkNjbW1F/OFiJ/eEqwWeJzc3l4ZPIBBgB3gV4E9hX79+wVbyW5G1lffHIKRtcxGejUAxfsaFrISOxPYjr7pYAkq4vQbYC+SCP8YIBVRKhMjmA+/lWc6dvSyg+fkmx9tC0N4klTv8BH+rZ/K8ZeFRAAAAAElFTkSuQmCC`;
        pdf.addImage(logo, 'PNG', pdf.internal.pageSize.width-(30+53), 10, 53, 11, 'NONE', 0);
        
        const data = stage.toDataURL({
          mimeType: 'image/png',
          pixelRatio: 2
        });
        pdf.addImage(data, 'PNG', 50, 75, width-100, height-150);
        pdf.setFontSize(50);
        pdf.text(50, 60, documentName);
        pdf.addPage('a4', 'landscape');
        pdf.setFontSize(15);

        const AllSeats = get_all_seats();

        let fullassignedPlaces = assignedPlaces;
        if (props.attendantsArray) {
          fullassignedPlaces = fullassignedPlaces.map((attendee) => {
            let newattendee = attendee;
            const attendants = [...new Set(props.attendantsArray.map(item => item))]; // [ 'A', 'B']
            const attendeeOrigin = attendants.find((attendeearray) => attendeearray.id === attendee.user_id);
            if (attendeeOrigin) {
              newattendee = {...attendeeOrigin, ...newattendee};
            } else {
              return null;
            }
            delete newattendee.user_id;
            let seatName = AllSeats.find((seat) => seat.markus.id === newattendee.seat_id);
            if (seatName && seatName.markus.standName) {
              newattendee.seat_name = seatName.markus.standName+' - '+newattendee.seat_name;
            } else if (seatName && seatName.markus.tableName) {
              newattendee.seat_name = seatName.markus.tableName+' - '+newattendee.seat_name;
            }
            return newattendee;
          });
        }

        fullassignedPlaces.map((place) => {
          if (place.parent === 0) {
            place.name_child = place.name+ ' '+place.firstname;
          }
          if (place.name_child === place.name_parent) {
            place.name_parent = '';
          }
          if (!place.type) {
            place.type = '';
          }
          if (!place.comment) {
            place.comment = '';
          }
          if (!place.entity) {
            place.entity = '';
          }
          place.confirmation_status = place.confirmation_status ? place.confirmation_status[0].toUpperCase() + place.confirmation_status.slice(1) : `-`;
          if (place.category_id) {
            place.category_id = getCat(place.category_id).name;
          }
          delete place.firstname;
          delete place.name;
          delete place.parent;
          delete place.seat_id;
          delete place.type;
          delete place.comment;
          delete place.entity;
          return place;
        }).sort((a, b) => a.name_child.localeCompare(b.name_child));
        const keys = fullassignedPlaces.length > 0 ? Object.keys(fullassignedPlaces[0]) : [];

        const dataPlaces = fullassignedPlaces.sort((a, b) => a.name_child.localeCompare(b.name_child)).map((place) => Object.values(place));
        const keysRename = [
          {old: 'id', new: 'ID'},
          {old: 'name_child', new: 'Name'},
          {old: 'name_parent', new: 'Parent name'},
          {old: 'seat_name', new: 'Seat'},
          {old: 'confirmation_status', new: 'Status'},
          {old: 'category_id', new: 'Category'},
        ];

        keysRename.map((key) => {
          var index = keys.indexOf(key.old);
          if (index !== -1) {
            keys[index] = key.new;
          }
          return key;
        });
        
        if (fullassignedPlaces.length > 0){
          pdf.text(30, 40, 'Guests placed');
          autoTable(pdf , {
            startY: 50,
            head: [keys],
            body: dataPlaces,
            theme: 'striped',
            headStyles: {
              fillColor: '#7151F2'
            },
          });
        }

        var finalY = pdf.lastAutoTable.finalY || 10

        const attendants = [...new Set(props.attendantsArray.map(item => item))]; // [ 'A', 'B']
        let notAssigned = attendants.filter((attendee) => {
          const assigned = assignedPlaces.find((assigned) => attendee.id === assigned.user_id);
          if (assigned) {
            return null;
          }
          delete attendee.seat_id;
          return attendee;
        });
        notAssigned.map((place) => {
          if (place.parent === 0) {
            place.name_child = place.name+ ' '+place.firstname;
          }
          if (place.name_child === place.name_parent) {
            place.name_parent = '';
          }
          place.confirmation_status = place.confirmation_status ? place.confirmation_status[0].toUpperCase() + place.confirmation_status.slice(1) : `-`;
          if (place.category_id) {
            place.category_id = getCat(place.category_id).name;
          }
          if (!place.type) {
            place.type = '';
          }
          if (!place.comment) {
            place.comment = '';
          }
          if (!place.entity) {
            place.entity = '';
          }
          delete place.firstname;
          delete place.name;
          delete place.parent;
          delete place.seat_id;
          delete place.type;
          delete place.comment;
          delete place.entity;
          return place;
        }).sort((a, b) => a.name_child.localeCompare(b.name_child));
        const dataNotAssigned = notAssigned.sort((a, b) => a.name_child.localeCompare(b.name_child)).map((attendee) => Object.values(attendee));
        const keysNotAssigned = notAssigned.length > 0 ? Object.keys(notAssigned[0]) : [];

        keysRename.map((key) => {
          var index = keysNotAssigned.indexOf(key.old);
          if (index !== -1) {
            keysNotAssigned[index] = key.new;
          }
          return key;
        });

        if (notAssigned.length > 0) {
          pdf.text(30, finalY + 20, 'Guests without place');
          autoTable(pdf, {
            startY: finalY + 30,
            head: [keysNotAssigned],
            body: dataNotAssigned,
            theme: 'striped',
            headStyles: {
              fillColor: '#7151F2'
            },
          });
        }

        var totalPages = pdf.internal.getNumberOfPages();
        const currentDate = new Date().toJSON().slice(0,16).replace(/-/g,'/').replace(/T/g, ' at ');
        for (var i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          if (i > 1) {
            pdf.addImage(logo, 'PNG', pdf.internal.pageSize.width-(30+53), 10, 53, 11, 'NONE', 0);
          }
          pdf.setFontSize(10);
          pdf.text(20, pdf.internal.pageSize.height-20, 'Date of export: '+currentDate);
        }

        setTimeout(
          function() {
            pdf.save(slugify(documentName || 'my new plan')+'.pdf');
          }, 
        500);
      }, 10);
  }

  const exportPlan = () => {
    const data = { assignedPlaces: assignedPlaces };
    const AllSeats = get_all_seats();
    data.assignedPlaces.map((placeUser) => {
      if (placeUser && !placeUser.seat_name) {
        let seatName = AllSeats.find((seat) => seat.markus.id === placeUser.seat_id);
        if (seatName) {
          placeUser.seat_name = seatName.markus.place;
        }
      }
      return placeUser;
    })
    data.title = documentName || 'My new plan';
    const dataStr = JSON.stringify(data);
    const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
    const exportFileDefaultName = slugify(documentName || 'my new plan')+'_assigned_places.json';

    let linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  }

  const seatSelectedUpdate = (deleteLink) => {
    if (place && place.markus && place.markus.id) {
      const placeSelected = {
        user_id: attendeeSelected,
        seat_id: place.markus.id,
        seat_name: place.markus.place,
        stand_name: place.markus.standName,
      };
      const attendants = [...new Set(props.attendantsArray.map(item => item))]; // [ 'A', 'B']
      const enfants = attendants.filter((attendee) => attendee.parent === attendeeSelected);
      let placeEnfants = false;
      if (enfants.length > 0 && !deleteLink && window.confirm('Do you want to automatically assign this person\'s guests?')) {
        placeEnfants = true;
      } else if (enfants.length > 0 && deleteLink && window.confirm('Do you also want to remove this person\'s guest spot?')) {
        placeEnfants = true;
      }

      const placeToDeleteFromAssigner = [attendeeSelected];
      if (placeEnfants) {
        enfants.map((enfant) => placeToDeleteFromAssigner.push(enfant.id));
      }
      let newAssignedPlaces = assignedPlaces.filter((place) => !placeToDeleteFromAssigner.includes(place.user_id));

      if (!deleteLink) {
        if (placeEnfants){
          const AllSeats = get_all_seats();
          let typePlace = 'stand';
          let freeSeats = false;
          let freeSeatsUsed = false;
          if (place.markus.standCode) {
            freeSeats = AllSeats.filter((seat) => 
              seat.markus && seat.markus.standCode && seat.markus.standCode === place.markus.standCode && seat.markus.seatStatus && seat.markus.seatStatus === "active"
            );
          } else if (place.markus.tableCode) {
            typePlace = 'table';
            freeSeats = AllSeats.filter((seat) => 
              seat.markus && seat.markus.tableCode && seat.markus.tableCode === place.markus.tableCode && seat.markus.seatStatus && seat.markus.seatStatus === "active"
            );
          }

          if (freeSeats.length > (enfants.length + 1)) {
            const currentIndex = freeSeats.findIndex(seat => seat.markus.id === place.markus.id);
            /**
             * On peut les placer l'un à coté de l'autre
             */
            
            if ((freeSeats.length-1) >= (currentIndex + enfants.length)) {
              freeSeatsUsed = freeSeats.slice(currentIndex, currentIndex+enfants.length+1);
            } else {
              freeSeatsUsed = freeSeats.slice((freeSeats.length-enfants.length-1), freeSeats.length);
            }
            freeSeatsUsed = freeSeatsUsed.filter((seat) => seat.markus.id !== placeSelected.seat_id);
            enfants.map((enfant, i) => {
              const enfantPlace = {
                user_id: enfant.id,
                seat_id: freeSeatsUsed[i].markus.id,
                seat_name: freeSeatsUsed[i].markus.place,
                stand_name: freeSeatsUsed[i].markus.standName,
              };
              newAssignedPlaces.push(enfantPlace);
              return enfant;
            });

          } else {
            if (typePlace === 'stand') {
              alert('Sorry, there are not enough free places for the guests in the stand.')
            } else {
              alert('Sorry, there are not enough free places for the guests around the table.')
            }
            return;
          }
        }
        newAssignedPlaces.push(placeSelected);
      } else if (placeEnfants) {
        const enfanttodelete = [];
        enfants.map((enfant) => {
          enfanttodelete.push(enfant.id);
          return enfant
        });
        newAssignedPlaces = newAssignedPlaces.filter((place) => !enfanttodelete.includes(place.user_id));
      }
      let uniquenewAssignedPlaces = [
        ...new Map(newAssignedPlaces.map((item) => [item["user_id"], item])).values(),
      ];

      let attendent = attendants.find((attendee) => attendee.id === attendeeSelected);
      if (!deleteLink && attendent) {
        const placeUsers = [];
        const placeUser = getPlaceUser(attendeeSelected);
        if (placeUser) {
          placeUsers.push(placeUser.attrs.id);
        }
        if (placeEnfants && enfants.length > 0) {
          enfants.map((enfant) => {
            const placeEnfant = getPlaceUser(enfant.id);
            if (placeEnfant) {
              placeUsers.push(placeEnfant.attrs.id);
            }
            return enfant;
          });
        }
        let newPlan = plan;
        const [fillstands, filltables] = fillPlan(newPlan, uniquenewAssignedPlaces);
        newPlan.objects.stands = fillstands;
        newPlan.objects.tables = filltables;
        setPlan({...newPlan})
        setAssignedPlaces(uniquenewAssignedPlaces);
        save(uniquenewAssignedPlaces);
        setPlace(place);
        setSeatInfo(place);
        setSeatInfoPerson(attendent);
      } else if (deleteLink) {
        setSeatInfoPerson(false);
        let newPlan = plan;
        const [fillstands, filltables] = fillPlan(newPlan, uniquenewAssignedPlaces);
        newPlan.objects.stands = fillstands;
        newPlan.objects.tables = filltables;
        setPlan({...newPlan})
        setAssignedPlaces(uniquenewAssignedPlaces);
        save(uniquenewAssignedPlaces);
      }
    }
  }

  const readFileOnUpload = (uploadedFile) => {
    setIsLoading(true);
    const fileReader = new FileReader();
    fileReader.onloadend = ()=>{
      try {
        const planJson = JSON.parse(fileReader.result);
        if (planJson.assignedPlaces) {
          setAssignedPlaces(planJson.assignedPlaces);
          setSeatInfo(false);
          setSeatInfoPerson(false);
          setSeatInfosStyles({});
          setSelectedSeatsIds([]);
          setAttendeeSelected(false);
          let newPlan = plan;
          const [fillstands, filltables] = fillPlan(newPlan, planJson.assignedPlaces);
          newPlan.objects.stands = fillstands;
          newPlan.objects.tables = filltables;
          setPlan({...newPlan})
          save(planJson.assignedPlaces);
        }
        setTimeout(function(){setIsLoading(false)}, 500);
      } catch(e) {
        setTimeout(function(){setIsLoading(false)}, 500);
        alert("**Not valid JSON file!**");
      }
    }
    if (uploadedFile !== undefined) {
      fileReader.readAsText(uploadedFile);
    }
  }

  const getCat = (category_id) => {
    let categorie = props.attendantsCategories.find((cat) => cat.id === category_id);
    if (!categorie) {
      categorie = {id: 0, name: 'N/A', alias: 'N/A', color: '#ccc'};
    }
    return categorie;
  }

  const getAbsolutePosition_konva = (obj) => {
    if (!obj) {
      return;
    } 

    const position = obj.absolutePosition();
    const rect = obj.getClientRect();
    let markus = obj.markus && obj.markus.type === 'seat' ? obj.markus : false;
    if (obj && obj.attrs && obj.attrs.name === 'seat') {
      const AllSeats = get_all_seats();
      const selectedSeat = AllSeats.find((seat) => seat.markus.id === obj.attrs.id);
      markus = selectedSeat.markus;
    }
    let max = markus && markus.type === 'seat' ? (markus.seatStatus && markus.seatStatus === 'reserved' ? 480 : 320) : 350;
    if (position) {
      const styles = {
        left: position.x+(rect.width / 2),
        top: position.y-10,
      };
      if (styles.top < max+(window.scrollY || 0)) {
        styles.top = position.y;
        styles.transform = 'translate(-50%, 0px)';
        if (markus && markus.type === 'seat') {
          styles.marginTop = '34px';
        }
      }
      return styles;
    } else {
      return false;
    }
  }

  const getPlaceUser = (attendee) => {
    let placeUser = false;
    if (attendee && Array.isArray(assignedPlaces)) {
      placeUser = assignedPlaces.find((place) => place.user_id === attendee);
      if (placeUser) {

        let seat = stageRef.current.find('#'+placeUser.seat_id)
        if (seat.length === 1) {
          placeUser = seat[0];
        } else {
          placeUser = false;
        }
      } else {
        placeUser = false;
      }
    }
    return placeUser;
  }

  const setAttendee = (id) => {
    const attendee = id === attendeeSelected ? '' : id;
    setAttendeeSelected(attendee);
    setHelp(id === attendeeSelected ? 'Please select an attendee in the left column.' : 'Select now a place to link to the selected attendee.');  
    
    const attendants = [...new Set(props.attendantsArray.map(item => item))]; // [ 'A', 'B']
    let attendent = attendants.find((attendent) => attendent.id === attendee);
    if (attendee && Array.isArray(assignedPlaces)) {
      const placeUser = getPlaceUser(attendee);
      if (placeUser) {
        setSeatInfoPerson(attendent);
        setSelectedSeatsIds([placeUser.attrs.id]);
        const AllSeats = get_all_seats();
        const data = AllSeats.find((seat) => seat.typeObject === 'seat' && seat.markus.id === placeUser.attrs.id)
        reCenter(data, stageRef.current.scaleX())
        setPlace(data);
        setSeatInfo(data);
        setSeatInfosStyles(getAbsolutePosition_konva(placeUser));
      } else {
        setSeatInfoPerson(false);
        setSeatInfo(false);
        setPlace(false);
        setSeatInfosStyles({});
        setSelectedSeatsIds([]);
      }
    } else {
      setSeatInfoPerson(false);
      setSeatInfo(false);
      setPlace(false);
      setSeatInfosStyles({});
      setSelectedSeatsIds([]);
    }
  }

  const placeNoOne = () => {
    if (window.confirm('Are you sure you wish to reset the whole placement ?' )) {
      let planNow = plan;
      const [fillstands, filltables] = fillPlan(planNow, []);
      planNow.objects.stands = fillstands;
      planNow.objects.tables = filltables;
      setPlan({...planNow})
      setAssignedPlaces([]);
      setPlace(false);
      setSelectedSeatsIds([]);
      setSeatInfo(false);
      setSeatInfoPerson(false);
      setAttendeeSelected(false);
      save([]);
    }
  }
  const placeEveryone = () => {
    const { attendantsArray } = props;
    if (!attendantsArray || attendantsArray.length === 0) {
      return null;
    }
    const attendants = [...new Set(attendantsArray.map(item => item))]; // [ 'A', 'B']
    const toPlacelist = attendants
      .sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      .filter((attendee) => {
        let placeUser = false;
        if (Array.isArray(assignedPlaces)) {
          placeUser = assignedPlaces.find((place) => place.user_id === attendee.id);
        }
        if (placeUser) {
          return false;
        }
        if (filterCategory && (!attendee.category_id || parseInt(attendee.category_id, 10) !== parseInt(filterCategory,10))) {
          return false;
        }
        if (filterType && (!attendee.type || attendee.type !== filterType)) {
          return false;
        }
        if (filterEntity && (!attendee.entity || attendee.entity !== filterEntity)) {
          return false;
        }
        if (filterConfirmationStatus && (!attendee.confirmation_status || attendee.confirmation_status !== filterConfirmationStatus)) {
          return false;
        }
        if (searchAttendee) {
          var regex = new RegExp( searchAttendee.toLowerCase(), 'g' );
          return (attendee.firstname && attendee.firstname.toLowerCase().match(regex)) || 
            (attendee.name && attendee.name.toLowerCase().match(regex)) || 
            (attendee.name_parent && attendee.name_parent.toLowerCase().match(regex)) || 
            (attendee.name_child && attendee.name_child.toLowerCase().match(regex)) || 
            (attendee.entity && attendee.entity.toLowerCase().match(regex));
        }
        return true;
      });
    
    /**
     * Stocker les avec parents ensemble
     */
    const parents = {};
    toPlacelist.filter((attendee) => attendee.parent && attendee.parent > 0).map((attendee) => {
      if (!parents[attendee.parent]) {
        parents[attendee.parent] = [attendee];
      } else {
        parents[attendee.parent].push(attendee)
      }
      return attendee;
    });

    /**
     * remettre les avec parents au coté de leur parent
     */
    const ids = Object.keys(parents);
    let toPlaceWithoutParents = toPlacelist.filter((elem) => !elem.parent || elem.parent === 0);
    ids.forEach((id) => {
      const i = toPlaceWithoutParents.findIndex((elem) => parseInt(elem.id, 10) === parseInt(id, 10));
      if (i || i === 0) {
        toPlaceWithoutParents = toPlaceWithoutParents.slice(0, i+1).concat(parents[id], toPlaceWithoutParents.slice(i+1));
      }
    });
    const toPlace = toPlaceWithoutParents;
    if (toPlace.length > 0) {
      if (window.confirm('Are you sure you wish to place the '+toPlacelist.length+' selected people ?' )) {
        const AllSeats = get_all_seats();
        const freeSeats = AllSeats.filter((seat) => seat.markus && seat.markus.seatStatus && seat.markus.seatStatus === "active");
        if ((!props.types || props.types.length > 0)  && freeSeats.length < toPlacelist.length) {
          alert('There are unfortunately not enough free places.')
          return false;
        } else {
          const userBytypes = {};
          const typeInsufficient = [];
          if (props.types && props.types.length > 0) {
            props.types.map((type) => {
              const userType = toPlace.filter((user) => user.type && user.type === type)
              const userByType = {  
                'type': type,
                'attendees': userType,
                'attendeesNb': userType.length,
                'freeSeats': freeSeats.filter((seat) => seat.markus.typeSeat && seat.markus.typeSeat === type),
                'freeSeatsNb': freeSeats.filter((seat) => seat.markus.typeSeat && seat.markus.typeSeat === type).length
              };
              userBytypes[type] = userByType;
              if (userByType['freeSeatsNb'] < userByType['attendeesNb']) {
                typeInsufficient.push(type);
              }
              return type;
            });
          }
          const userTypeEmpty = toPlace.filter((user) => !user.type || user.type === '')
          const userByTypeEmpty = {
            'type': 'null',
            'attendees': userTypeEmpty,
            'attendeesNb': userTypeEmpty.length,
            'freeSeats': freeSeats.filter((seat) => !seat.markus.typeSeat || seat.markus.typeSeat === ''),
            'freeSeatsNb': freeSeats.filter((seat) => !seat.markus.typeSeat || seat.markus.typeSeat === '').length
          };
          userBytypes['null'] = userByTypeEmpty;
          if (userByTypeEmpty['freeSeatsNb'] < userByTypeEmpty['attendeesNb']) {
            typeInsufficient.push('empty');
          }
          if (typeInsufficient.length > 0) {
            let message = `There are no sufficient places for types `+typeInsufficient.join(', ');
            if (typeInsufficient.length === 1) {
              message = `There are no sufficient places for the type `+typeInsufficient[0];
            }
            alert(message);
            return false;
          }

          let assignedPlacesNow = assignedPlaces;
          let planNow = plan;
          Object.values(userBytypes).map((type, i) => {
            type.attendees.map((attendant, i) => {
              const placeSelected = {
                user_id: attendant.id,
                seat_id: type.freeSeats[i].markus.id,
                seat_name: type.freeSeats[i].markus.place,
                stand_name: type.freeSeats[i].markus.standName,
              };
              assignedPlacesNow.push(placeSelected);

              assignedPlacesNow = [
                ...new Map(assignedPlacesNow.map((item) => [item["user_id"], item])).values(),
              ];
              return true;
            });
            return true;
          });
          const [fillstands, filltables] = fillPlan(planNow, assignedPlacesNow);
          planNow.objects.stands = fillstands;
          planNow.objects.tables = filltables;
          setPlan({...planNow})
          setAssignedPlaces(assignedPlacesNow);
          setPlace(false);
          setSelectedSeatsIds([]);
          setSeatInfo(false);
          setSeatInfoPerson(false);
          setAttendeeSelected(false);
          save(assignedPlacesNow);
        }
      }
    }
  }

  const renderListPersonnes = () => {
    const { attendantsArray } = props;
    if (!attendantsArray || attendantsArray.length === 0) {
      return null;
    }
    const places = get_all_seats();
    let attendants = [...new Set(attendantsArray.map(item => item))]; // [ 'A', 'B']
    attendants = attendants.map((attendant) => {
      if (!attendants.name_child) {
        attendant.name_child = attendant.name+' '+attendant.firstname;
      }
      attendant.name_parent = attendant.name+' '+attendant.firstname;
      if (attendant.parent !== 0) {
        const parent = attendants.find((att) => att.id === attendant.parent);
        const parentIndex = attendants.findIndex((att) => att.id === attendant.parent);
        if (parent) {
          attendant.name_parent = parent.name+' '+parent.firstname;
          attendants[parentIndex].name_child = attendant.name+' '+attendant.firstname;
        }
        
      }
      return attendant;
    })
    
    const finalAttendants =  attendants
      .sort((a,b) => a.name_parent.toLowerCase().localeCompare(b.name_parent.toLowerCase()) || a.parent - b.parent)
      .filter((attendee) => {
        let placeUser = false;
        if (Array.isArray(assignedPlaces)) {
          placeUser = assignedPlaces.find((place) => place.user_id === attendee.id);
        }
        if (filterAttendee === 'placed' && !placeUser) {
          return false;
        }
        if (filterAttendee === 'unplaced' && placeUser) {
          return false;
        }
        if (filterCategory && (!attendee.category_id || parseInt(attendee.category_id, 10) !== parseInt(filterCategory,10))) {
          return false;
        }
        if (filterType && (!attendee.type ||attendee.type !== filterType)) {
          return false;
        }
        if (filterEntity && (!attendee.entity || attendee.entity !== filterEntity)) {
          return false;
        }
        if (filterConfirmationStatus && (!attendee.confirmation_status ||attendee.confirmation_status !== filterConfirmationStatus)) {
          return false;
        }
        if (searchAttendee) {
          var regex = new RegExp( searchAttendee.toLowerCase(), 'g' );
          return (attendee.firstname && attendee.firstname.toLowerCase().match(regex)) || 
            (attendee.name && attendee.name.toLowerCase().match(regex)) || 
            (attendee.name_parent && attendee.name_parent.toLowerCase().match(regex)) || 
            (attendee.name_child && attendee.name_child.toLowerCase().match(regex)) || 
            (attendee.entity && attendee.entity.toLowerCase().match(regex));
        }
        return true;
      });
    if (finalAttendants.length === 0) {
      return <div className={`flex center p-2`}>No attendant corresponding to your filters to list here</div>
    }
    
    const AllSeats = get_all_seats();
    return finalAttendants.map((attendee, index) => {
        let classes = "grid grid-cols-6 gap-1 p-1 text-sm hover:bg-violet-940/10 cursor-pointer";
        let classesCheck = "rounded-full border-2 ml-2 text-center";
        let classText = 'text-gray-300 '
        let colorCheck = '#DBDADC';
        let placeUser = false;
        let placeFullUser = false;
        if (Array.isArray(assignedPlaces)) {
          placeUser = assignedPlaces.find((place) => place.user_id === attendee.id);
          if (placeUser) {
            placeFullUser = places.find((place) => place.markus.id === placeUser.seat_id && place.markus.type === 'seat');
            colorCheck = '#ffffff';
            classText = 'text-white ';
            classesCheck += " border-violet-940/20 bg-violet-940 border-3";
          }
        }

        if (attendeeSelected && attendeeSelected === attendee.id) {
          classes += " bg-violet-940/20";
          if (!placeUser) {
            classesCheck += " border-white bg-white/20";
            classText = 'text-white ';
            colorCheck = '#ffffff';
          }
        } else if (!placeUser){
          classesCheck += "  border[#DBDADC]";
        }

        if (placeUser && !placeUser.seat_name) {
          let seatName = AllSeats.find((seat) => seat.markus.id === placeUser.seat_id);
          if (seatName) {
            placeUser.seat_name = seatName.markus.place;
          }
        }

        const categorie = getCat(attendee.category_id);

        return (
          <div key={`attendee_`+attendee.id} className={classes} onClick={(e) => setAttendee(attendee.id)}>
            <div className="col-span-2 row-span-2 self-center text-xs">
              {!attendee.comment && <span>{attendee.parent > 0 ? '↳ ' : ''}{attendee.firstname + ' '+ attendee.name}</span>}
              {attendee.comment && // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <abbr
                  data-tooltip-id="tooltip-comment"
                  data-tooltip-content={attendee.comment}
                  data-tooltip-place="top"
                >{attendee.parent > 0 ? '↳ ' : ''}{attendee.firstname + ' '+ attendee.name}<sup className={classText+classesCheck +' w-6'}>?</sup></abbr>}
            </div>
            <div className="whitespace-nowrap text-xs self-center text-ellipsis overflow-hidden">
              <div className="rounded-full inline-block mr-1" style={{backgroundColor: categorie.color, width: '10px', height: '10px'}}></div>{categorie.name.replace('_', ' ')}
            </div>
            <div className="whitespace-nowrap text-xs self-center text-ellipsis overflow-hidden">
              <span className="p-2 pt-1 pb-1 text-[#98979A] rounded-sm bg-[#F8F7FA] inline-block whitespace-nowrap text-xs self-center text-ellipsis overflow-hidden">{attendee.confirmation_status ? attendee.confirmation_status[0].toUpperCase() + attendee.confirmation_status.slice(1) : `-`}</span>
            </div>
            <div className={props.types && props.types.length > 0 ? "grid grid-flow-col col-span-2 row-span-2 text-xs self-center text-right items-center justify-items-stretch" : "grid grid-flow-col col-span-2 row-span-2 text-xs w-3/12 self-center text-right items-center justify-items-stretch"}>
              <div className={classesCheck} style={{width:'20px', height: '20px'}}>
                <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginTop: '5px',marginLeft: '3px'}}>
                  <path d="M8.33268 1L3.74935 5.58333L1.66602 3.5" stroke={colorCheck} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
              <div>
                {placeFullUser && placeFullUser.markus.standName ? placeFullUser.markus.standName+' - ' : ''}
                {placeFullUser && placeFullUser.markus.tableName ? placeFullUser.markus.tableName+' - ' : ''}
                {placeUser && placeUser.seat_name ? placeUser.seat_name : '-'}
                {placeFullUser && placeFullUser.markus.typeSeat ? ' ('+placeFullUser.markus.typeSeat+')' : ''}
              </div>
            </div>
            {props.types &&
            <div className="col-start-3 whitespace-nowrap text-xs self-center text-ellipsis overflow-hidden" title={`Attendee entity`}>
              {attendee.entity || '-'}
            </div>}
            {props.types &&
            <div className="col-start-4 whitespace-nowrap text-xs self-center text-ellipsis overflow-hidden" title={`Attendee type`}>
              {attendee.type || '-'}
            </div>}
          </div>
        );
      });
  }

  const getCategories = () => {
    const { attendantsArray } = props;
    let categories = [];
    if (!attendantsArray || attendantsArray.length === 0) {
      return categories;
    }
    categories = [...new Set(attendantsArray.map((att) => att.category_id))]
      .filter(el => el !== null)
      .map((categoryId) => {
        const categorie = getCat(categoryId);
        return categorie;
      });
    return categories;
  }

  const cats = getCategories();

  const confirmationStatuses = [...new Set(props.attendantsArray.map((att) => att.confirmation_status))];
  return (
    <div className="seat-planner-app text-sm 2xl:text-base height-full width-full fixed" style={{width: '100%'}} id={canvasContainerId.replace('#', '')} tabIndex={0} onKeyDown={onKeyDown}>
      <Tooltip id="tooltip-comment" />
      <Loading visible={isLoading} />
      <MarkusDropZone onChangeUpload={readFileOnUpload}>
        <div>
          <h1>Drag your file here...</h1>
        </div>
      </MarkusDropZone>
      {errorApi && <ShowError message={errorApi} backLink={props.backLink} />}
      
      <div className="flex">
        <div className="relative w-3/12 md:w-4/12 bg-white border-r border-gray-300 z-50 max-h-screen overflow-y-scroll" id="list-persons">
          <header className="p-2">
            <div className="flex items-center justify-between">
              <div>
                {props.backLink &&
                <a
                  href={props.backLink}
                  rel="noreferer"
                  className="inline-block rounded-md border border-gray-300 shadow-sm p-3 leading-4 bg-white text-gray-700 hover:bg-violet-940  hover:text-white focus:outline-none align-bottom">
                  <img src={BackLinkButton} alt="Back" />
                </a>}
              </div>
              <div className="truncate font-bold" style={{'maxWidth': 'calc(100% - 175px)'}}>
                  {documentName}
              </div>
              <div>
                <button 
                  type="button"
                  onClick={print}
                  className="inline-block ml-2 rounded-md border border-gray-300 shadow-sm p-3 leading-4 bg-white text-gray-700 hover:bg-violet-940  hover:text-white focus:outline-none align-bottom">
                  <img src={Print} alt="Print" />
                </button>
                <label htmlFor="jsonFile"
                  type="button"
                  title="Import placed attendees"
                  className="cursor-pointer inline-block ml-2 rounded-md border border-gray-300 shadow-sm p-3 leading-4 bg-violet-940 font-medium text-white hover:bg-violet-940/80 focus:outline-none  align-bottom">
                  <img src={Import} alt="Import" className="inline-block" /><input type="file" className="hidden" id="jsonFile" onChange={(e)=>readFileOnUpload(e.target.files[0])} />
                </label>
                <button
                  type="button"
                  onClick={exportPlan}
                  title="Export placed attendees"
                  className="inline-block ml-2 rounded-md border border-gray-300 shadow-sm p-3 leading-4 bg-violet-940 font-medium text-white hover:bg-violet-940/80 focus:outline-none  align-bottom">
                  <img src={ExternalLink} alt="Export" className="inline-block" style={{width:'16px', height:'16px'}} />
                </button>
              </div>
            </div>
          </header>
          <div className="p-2">
            {props.attendantsArray && props.attendantsArray.length > 0 &&
            <div>
              <div>
                <input type="search" className="
                  block
                  w-full
                  rounded-md
                  border-gray-300
                  shadow-sm
                  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  value={searchAttendee || ''}
                  placeholder={`Search an attendee`}
                  onChange={(e) => setSearchAttendee(e.target.value)} />
              </div>
              <div className="filter p-2 ">
                <span className="inline-block p-2 pl-0">Filter : </span>
                <button 
                  type="button"
                  className={filterAttendee === '' ? 
                    "inline-block ml-2 rounded-full border border-gray-300 shadow-sm p-2  bg-violet-940 text-white focus:outline-none align-bottom" :
                    "inline-block ml-2 rounded-full border border-gray-300 shadow-sm p-2 hover:bg-violet-940 bg-white text-gray-700 hover:text-white focus:outline-none align-bottom"
                  }
                  onClick={(e) => setFilterAttendee('')}>
                    All
                </button>
                <button 
                  type="button"
                  className={filterAttendee === 'placed' ? 
                    "inline-block ml-2 rounded-full border border-gray-300 shadow-sm p-2 bg-violet-940 text-white focus:outline-none align-bottom" :
                    "inline-block ml-2 rounded-full border border-gray-300 shadow-sm p-2 hover:bg-violet-940 bg-white text-gray-700 hover:text-white focus:outline-none align-bottom"
                  }
                  onClick={(e) => setFilterAttendee('placed')}>
                    Placed
                </button>
                <button
                  type="button"
                  className={filterAttendee === 'unplaced' ? 
                    "inline-block ml-2 rounded-full border border-gray-300 shadow-sm p-2 bg-violet-940 text-white focus:outline-none align-bottom" :
                    "inline-block ml-2 rounded-full border border-gray-300 shadow-sm p-2 hover:bg-violet-940 bg-white text-gray-700 hover:text-white focus:outline-none align-bottom"
                  }
                  onClick={(e) => setFilterAttendee('unplaced')}>
                    Unplaced
                </button>
              </div>
              <div>
                {cats.length > 0 && 
                <div className="filter p-2 w-6/12 inline-block">
                  <span className="inline-block p-2 pl-0">Categories: </span>
                  <select 
                    name={`categorie`}
                    defaultValue={filterCategory}
                    onChange={(e) => setFilterCategorie(e.target.value)}
                    className={'block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'}>
                    <option value="">-</option>
                    {cats.map((cat) => {
                      return (
                        <option key={'cat_'+cat.id} value={cat.id}>{cat.name.replace('_', ' ')}</option>
                      )
                    })}
                  </select>
                </div>}

                {props.types && props.types.length > 0 && 
                <div className="filter p-2 w-6/12 inline-block">
                  <span className="inline-block p-2 pl-0">Types: </span>
                  <select 
                    name={`types`}
                    defaultValue={filterType}
                    onChange={(e) => setFilterType(e.target.value)}
                    className={'block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'}>
                    <option value="">-</option>
                    {props.types.map((type) => {
                      return (
                        <option  key={'type_'+type} value={type}>{type.replace('_', ' ')}</option>
                      )
                    })}
                  </select>
                </div>}
                {allEntities && allEntities.length > 0 && 
                <div className="filter p-2 w-6/12 inline-block">
                  <span className="inline-block p-2 pl-0">Entities: </span>
                  <select 
                    name={`entitoes`}
                    defaultValue={filterEntity}
                    onChange={(e) => setFilterEntity(e.target.value)}
                    className={'block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'}>
                    <option value="">-</option>
                    {allEntities.map((entity) => {
                      return (
                        <option key={'type_'+entity.replace(' ', '_')} value={entity}>{entity}</option>
                      )
                    })}
                  </select>
                </div>}
                {confirmationStatuses.length > 0 && 
                <div className="filter p-2 w-6/12 inline-block">
                  <span className="inline-block p-2 pl-0">Confirmation status: </span>
                  <select 
                    name={`confirmation_status`}
                    defaultValue={filterConfirmationStatus}
                    onChange={(e) => setFilterConfirmationStatus(e.target.value)}
                    className={'block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'}>
                    <option value="">-</option>
                    {confirmationStatuses.map((statut) => {
                      return (
                        <option key={'status_'+statut} value={statut}>{statut.replace('_', ' ')}</option>
                      )
                    })}
                  </select>
                </div>}
              </div>

              <div className="list">
                {filterAttendee === 'placed' && 
                  <button
                    type="button"
                    className={
                      "block ml-2 rounded-full border border-gray-300 shadow-sm p-2 bg-violet-940 text-white focus:outline-none align-bottom"
                    }
                    onClick={(e) => placeNoOne()}>
                      Reset placement
                  </button>
                }
                {filterAttendee === 'unplaced' && [...new Set(props.attendantsArray.map(item => item))].filter((attendee) => {
                  let placeUser = false;
                  if (Array.isArray(assignedPlaces)) {
                    placeUser = assignedPlaces.find((place) => place.user_id === attendee.id);
                  }
                  if (placeUser) {
                    return false;
                  }
                  if (searchAttendee) {
                    var regex = new RegExp( searchAttendee.toLowerCase(), 'g' );
                    return (attendee.firstname && attendee.firstname.toLowerCase().match(regex)) || 
            (attendee.name && attendee.name.toLowerCase().match(regex)) || 
            (attendee.name_parent && attendee.name_parent.toLowerCase().match(regex)) || 
            (attendee.name_child && attendee.name_child.toLowerCase().match(regex)) || 
            (attendee.entity && attendee.entity.toLowerCase().match(regex));
                  }
                  return true;
                }).length > 0 &&
                  <button
                    type="button"
                    className={
                      "block ml-2 rounded-full border border-gray-300 shadow-sm p-2 bg-violet-940 text-white focus:outline-none align-bottom"
                    }
                    onClick={(e) => placeEveryone()}>
                      Place everyone
                  </button>
                }
                {renderListPersonnes()}
              </div>
            </div>
            }
          </div>
        </div>
        <div className="relative w-9/12 md:w-8/12" ref={containerRef}>
          <div className="fixed top-0 text-white bg-violet-940/80 z-10 ">
            <div className="flex items-center justify-between p-2">
              <span className="pl-2 pr-2">
                {seatsTotal+ ` seats`}
              </span>
              <span className="pl-2 pr-2">
                {seatsBookedTotal+ ` reserved seats`}
              </span>
              <span className="pl-2 pr-2">
                {seatsReservedTotal+ ` blocked seats`}
              </span>
              <span className="pl-2 pr-2">
                {seatsAtDisposition+ ` seats at disposition`}
              </span>
              <span className="pl-2 pr-2">
                {attendeesTotal+ ` total attendees`}
              </span>
              <span className="pl-2 pr-2">
                {attendeesPlaced+ ` placed attendees`}
              </span>
            </div>

          </div>
          <div className="fixed z-10 bg-white m-6 p-2 bottom-0 right-0  border border-gray-200 rounded-md shadow-gray-500 shadow-2xl">
            <div
              title="Actual zoom level"
              className={"block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium cursor-default text-gray-700 text-center text-xs mt-0 sm:m-1 sm:w-auto sm:text-xs"}>
              {Math.round((zoomLevel)*10)/10}
            </div>
            <button 
              type="button"
              onClick={reCenter}
              className={"block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium text-gray-700 hover:bg-violet-940 hover:border-violet-940 hover:text-white mt-0 sm:m-1 sm:w-auto sm:text-sm"}>
                <img src={Center} alt="Recenter" />
            </button>
            <button 
              type="button"
              onClick={zoomPlus}
              className={zoomLevel < zoomHigh ? "block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium text-gray-700 hover:bg-violet-940 hover:border-violet-940 hover:text-white mt-0 sm:m-1 sm:w-auto sm:text-sm" : "block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium cursor-default mt-0"}>
                <img src={ZoomIn} alt="Zoom in" className={zoomLevel < zoomHigh ? '' : 'opacity-25'} />
            </button>
            <button 
              type="button"
              onClick={zoomMoins}
              className={zoomLevel > zoomLow ? "block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium text-gray-700 hover:bg-violet-940 hover:border-violet-940 hover:text-white mt-0 sm:m-1 sm:w-auto sm:text-sm" : "block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium cursor-default mt-0"}>
                <img src={ZoomOut} alt="Zoom out" className={zoomLevel > zoomLow ? '' : 'opacity-25'} />
            </button>
        </div>
          {help && <ShowHelp help={help} />}
          {seatInfo && seatInfo.markus && 
            <SeatInfoAttendee 
              infos={seatInfo.markus} 
              attendeeSelected={attendeeSelected}
              attendeeSelectedInfos={props.attendantsArray.find((attendee) => attendee.id === attendeeSelected)}
              styles={seatInfosStyles}
              onSelect={seatSelectedUpdate}
              infoPerson={seatInfoPerson}
              attendantsCategories={props.attendantsCategories} />}
          <Stage width={dimensions.width} height={dimensions.height}
            ref={stageRef}
            draggable={true}
            onWheel={handleWheel}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onDragMove={handleDragStage}>
          {!isLoading && plan && (plan.objects.stands || plan.objects.tables) && (plan.objects.stands.length > 0 || plan.objects.tables.length > 0) &&
          <Layer ref={layerRef} draggable={true}>
            <Group ref={groupRef}>
            {plan.objects.stands.map((stand, index) => {
              return (
                <Stand
                  x={stand.x || 200}
                  y={stand.y || 200}
                  key={'stand_'+index}
                  data={stand}
                  mode={'placement'}
                  isEditing={true}
                  noControl={true}
                  selectedSeatsIds={selectedSeatsIds}
                  onSelectSeat={(current, selectedSeats, data) => {
                    if (data) {
                      setSelectedSeatsIds(selectedSeats);
                      setSeatInfo(data);
                      setPlace(data);
                      const newAttendeeSelected = assignedPlaces.find((place) => place.seat_id === data.markus.id)
                      if (newAttendeeSelected) {
                        setAttendee(newAttendeeSelected.user_id);
                      } else {
                        setSeatInfoPerson(false);
                        setSeatInfosStyles(getAbsolutePosition_konva(current[0]));
                      }
                    }
                  }}
                  uniqueSeat={true}
                />
              );
          })}
            {plan.objects.tables.map((table, index) => {
              return (
                <Table
                  x={table.x || 200}
                  y={table.y || 200}
                  key={'table_'+index}
                  data={table}
                  mode={'placement'}
                  isEditing={true}
                  noControl={true}
                  selectedSeatsIds={selectedSeatsIds}
                  onSelectSeat={(current, selectedSeats, data) => {
                    if (data) {
                      setSelectedSeatsIds(selectedSeats);
                      setSeatInfo(data);
                      setPlace(data);
                      const newAttendeeSelected = assignedPlaces.find((place) => place.seat_id === data.markus.id);
                      if (newAttendeeSelected) {
                        setAttendee(newAttendeeSelected.user_id);
                      } else {
                        setSeatInfoPerson(false);
                        setSeatInfosStyles(getAbsolutePosition_konva(current[0]));
                      }
                    }
                  }}
                  uniqueSeat={true}
                />
              );
          })}
          </Group>
          </Layer>}
        </Stage>
        </div>
      </div>
    </div>
  );
}