import React, { useState, useEffect } from "react";

export default function AddStand(props) {
    const [lines, setLines] = useState(5);
    const [lineranking, setLineranking] = useState('alphabetical');
    const [linefirst, setLinefirst] = useState('A');
    const [columns, setColumns] = useState(10);
    const [columnranking, setColumnranking] = useState('alphanumerical');
    const [columnfirst, setColumnfirst] = useState(1);
    const [switchCols, setSwitchCols] = useState(false);
    const [switchRows, setSwitchRows] = useState(false);
    const [seatsType, setSeatsType] = useState('');

    const [standName, setStandName] = useState(`Stand `+(props.plan.objects.stands.length+1));
    const [standCode, setStandCode] = useState(`stand-`+(props.plan.objects.stands.length+1));
 
    useEffect(() => {
        if (standCode.slice(0,6) === 'stand-' && props.plan.objects.stands.length > 0) {
            setStandName(`Stand `+(props.plan.objects.stands.length+1));
            setStandCode(`stand-`+(props.plan.objects.stands.length+1));
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible]);

    let classes = "hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
    if (props && props.visible) {
        classes = "flex items-center justify-center h-screen overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full bg-violet-940/20"
    }

    let firstLine = linefirst;
    if (lineranking === 'alphanumerical' && isNaN(parseInt(firstLine, 10))) {
        firstLine = 1;
    } else if (lineranking === 'alphabetical' && parseInt(firstLine, 10) >= 0) {
        firstLine = 'A';
    }

    let firstCol = columnfirst;
    if (columnranking === 'alphanumerical' && isNaN(parseInt(firstCol, 10))) {
        firstCol = 1;
    } else if (columnranking === 'alphabetical' && parseInt(firstCol, 10) >= 0) {
        firstCol = 'A';
    }
    
    const addStand = () => {
        const options = {
            cols: columns,
            rows: lines,
            name: standName,
            code: standCode,
            lineranking: lineranking,
            colranking: columnranking,
            rowStart: linefirst,
            colStart: columnfirst,
            switchCols,
            switchRows,
            seatsType: seatsType,
        }
        return props.makeStand(options);
    }

    const close = () => {
        return props.close(false)
    }

    return (
        <div id="modalAddStand" tabIndex="-1" aria-hidden="true" className={classes} style={{maxHeight: '100svh'}}>
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto ">
                <div className="relative rounded-lg shadow bg-white ">
                    <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-violet-950">
                        <h3 className="text-xl font-semibold text-gray-900">
                            Add a stand
                        </h3>
                        <button type="button" className="text-violet-940 bg-transparent hover:bg-violet-940 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-violet-940 dark:hover:text-white" onClick={close}>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>  
                        </button>
                    </div>
                    <div className="grid grid-cols-1 gap-6 p-4">
                        <label className="block">
                            <span className="text-gray-700">Name of the Stand</span>
                            <input type="text" className="
                                mt-1
                                block
                                w-full
                                rounded-md
                                border-gray-300
                                shadow-sm
                                focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                value={standName}
                                onChange={(e) => setStandName(e.target.value)} />
                        </label>
                        <label className="block">
                            <span className="text-gray-700">Code of the Stand</span>
                            <input type="text" className="
                                mt-1
                                block
                                w-full
                                rounded-md
                                border-gray-300
                                shadow-sm
                                focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                value={standCode}
                                onChange={(e) => setStandCode(e.target.value)} />
                        </label>
                        {props.types && props.types.length > 0 && (
                        <label className="block mr-1">
                                <span className="text-gray-700">Seats type</span>
                                <select className="
                                    block
                                    w-full
                                    mt-1
                                    rounded-md
                                    border-gray-300
                                    shadow-sm
                                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                                "
                                name={`seatsType`}
                                defaultValue={''}
                                onChange={(e) => setSeatsType(e.target.value)}>
                                    <option value={``}>-</option>
                                    {props.types.map((type, index) => (
                                        <option key={`type_${index}`} value={type}>{type}</option>
                                    ))}
                                </select>
                            </label>
                        )}
                        <div className="grid grid-cols-2">
                            <label className="block mr-1">
                                <span className="text-gray-700">Rows number</span>
                                <input type="number" className="
                                    mt-1
                                    block
                                    w-full
                                    rounded-md
                                    border-gray-300
                                    shadow-sm
                                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    defaultValue={lines}
                                    min={1}
                                    max={100}
                                    onChange={(e) => setLines(e.target.value)} />
                            </label>
                            <div className="block ml-1">
                                <span className="text-gray-700">Switch the order</span>
                                <label htmlFor="swtchRowsOrder" className="inline-flex relative items-center cursor-pointer mt-3">
                                    <input type="checkbox" value={switchRows} onChange={(e) => setSwitchRows(!switchRows)} id="swtchRowsOrder" className="sr-only peer" />
                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-violet-300 rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-violet-940"></div>
                                    <span className="ml-3 text-sm font-medium text-gray-900">Count from bottom to top</span>
                                </label>
                            </div>
                        </div>
                        <div className="grid grid-cols-2">
                            <label className="block mr-1">
                                <span className="text-gray-700">Rows ranking</span>
                                <select className="
                                    block
                                    w-full
                                    mt-1
                                    rounded-md
                                    border-gray-300
                                    shadow-sm
                                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                                "
                                name={`lineranking`}
                                defaultValue={lineranking}
                                onChange={(e) => {setLineranking(e.target.value);}}>
                                    <option value={`alphabetical`}>Alphabetical</option>
                                    <option value={`alphanumerical`}>Alphanumeric</option>
                                </select>
                            </label>
                            <label className="block ml-1">
                                <span className="text-gray-700">{`First row `+(lineranking === 'alphabetical' ? `letter` : `number`)}</span>
                                <input type={lineranking === 'alphabetical' ? 'text' : 'number'} className="
                                    mt-1
                                    block
                                    w-full
                                    rounded-md
                                    border-gray-300
                                    shadow-sm
                                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    value={firstLine}
                                    maxLength={(lineranking === 'alphabetical' ? 1 : 2)}
                                    min={1}
                                    max={99}
                                    onChange={(e) => setLinefirst(e.target.value)} />
                            </label>
                        </div>
                        <div className="grid  grid-cols-2">
                            <label className="block mr-1">
                                <span className="text-gray-700">Columns number</span>
                                <input type="number" className="
                                    mt-1
                                    block
                                    w-full
                                    rounded-md
                                    border-gray-300
                                    shadow-sm
                                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    defaultValue={columns}
                                    min={1}
                                    max={100}
                                    onChange={(e) => setColumns(e.target.value)} />
                            </label>
                            <div className="block ml-1">
                                <span className="text-gray-700">Switch the order</span>
                                <label htmlFor="swtchColsOrder" className="inline-flex relative items-center cursor-pointer mt-3">
                                    <input type="checkbox" value={switchCols} onChange={(e) => setSwitchCols(!switchCols)}  id="swtchColsOrder" className="sr-only peer" />
                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-violet-300 rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-violet-940"></div>
                                    <span className="ml-3 text-sm font-medium text-gray-900">Count from right to left</span>
                                </label>
                            </div>
                        </div>
                        <div className="grid grid-cols-2">
                            <label className="block mr-1">
                                <span className="text-gray-700">Columns ranking</span>
                                <select className="
                                    block
                                    w-full
                                    mt-1
                                    rounded-md
                                    border-gray-300
                                    shadow-sm
                                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                                "
                                name={`columnranking`}
                                defaultValue={columnranking}
                                onChange={(e) => setColumnranking(e.target.value)}>
                                    <option value={`alphanumerical`}>Alphanumeric</option>
                                    <option value={`alphabetical`}>Alphabetical</option>
                                </select>
                            </label>
                            <label className="block ml-1">
                                <span className="text-gray-700">{`First Column `+(columnranking === 'alphabetical' ? `letter` : `number`)}</span>
                                <input type={columnranking === 'alphabetical' ? 'text' : 'number'} className="
                                    mt-1
                                    block
                                    w-full
                                    rounded-md
                                    border-gray-300
                                    shadow-sm
                                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    min={1}
                                    max={100}
                                    value={firstCol}
                                    onChange={(e) => setColumnfirst(e.target.value)} />
                            </label>
                        </div>
                    </div>
                    <div className="flex items-center p-6 space-x-2 rounded-b border-t border-violet-950">
                        <button 
                            type="button" 
                            className="text-white bg-violet-940 hover:bg-violet-940 focus:ring-4 focus:outline-none focus:ring-violet-950 font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={addStand}>Create the stand</button>
                    </div>
                </div>
            </div>
        </div>
    )

}