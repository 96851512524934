import React from "react";
import { Path, Group, Text, Label, Tag, Transformer } from "react-konva";
import { seatPath, seatUnderNumberPath, seatWidth } from '../../constants.js';
import Pencil from '../../assets/pencil.png';
import Trash from '../../assets/trash.png';

function getColor(data, isSelected, defaut="#f1eefe") {
  if ((data.markus.seatStatus === 'reserved' || data.markus.seatStatus === 'booked') && data.markus.fillColor) {
    return data.markus.fillColor;
  } else {
    return defaut;
  }
}

function getColorStroke(data, isSelected, defaut="#d9d0ff") {
    if ((data.markus.seatStatus === 'reserved' || data.markus.seatStatus === 'booked') && data.markus.fillColor) {
        return data.markus.fillColor;
    } else {
        return defaut;
    }
}

const Seat = props => {
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  const mode = props.mode || 'creation';
  var editIcon = Pencil;
  var deleteIcon = Trash;
  var cloneIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAWQAAAFkBqp2phgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAC6SURBVDiN3dMxagJBGAXgT1mwCsRW0qWNmNZC0NpzWOVG6XOAINYRcoEtLLyChegBVrTY2bCZrOOm9cHjhzf//3gzzM9vvKLA+Qq3GNQHupHBI77RaeAMT/iqm2Sh9rHAszR2IeEaY+yrBCO84SExnOMDS/QwrB9OQ7Sq3kLV+3OFJszxEmkbrOpCyiBTvk2yP2VQ4NCgtTa4gwSfgUnEX/nfiBMcMVEuTgqn0PvHIG/QWuHWGld8jwcvV/Es3+ygdpMAAAAASUVORK5CYII=";

  var editImg = document.createElement('img');
  editImg.src = editIcon;
  var deleteImg = document.createElement('img');
  deleteImg.src = deleteIcon;
  var cloneImg = document.createElement('img');
  cloneImg.src = cloneIcon;

  React.useEffect(() => {
    if (props.isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [props.isSelected]);

  let textColor = '#1d1d1b';

  return (
    <>
    <Group
      name={'seat'}
      id={props.data.markus.id}
      x={props.x}
      y={props.y}
      opacity={props.data.markus.opacity || 1}
      ref={shapeRef}
      draggable={props.isSelected && mode === 'creation' }
      onDragStart={(e) => {
        e.cancelBubble = true;
      }}
      onDragEnd={(e) => {
          e.cancelBubble = true;
          const newData = {...props.data, x: e.target.x(), left: e.target.x(), y: e.target.y(), top: e.target.y()};
          if (props.onUpdateSeat) {
            props.onUpdateSeat(newData, props.index);
          }
          props.onDragSeat(e);
      }}
      onDragMove={(e) => {
          e.cancelBubble = true;
          props.onDragSeat(e);
      }}
      onMouseEnter={e => {
        e.target._clearCache();
        props.onHover && props.onHover(props.data.name, e.target.getAbsolutePosition());
        const container = e.target.getStage().container();
        if (props.data.markus.hoverCursor) {
          container.style.cursor = props.data.markus.hoverCursor;
        } else {
          container.style.cursor = "pointer";
        }
      }}
      onMouseLeave={e => {
        props.onHover && props.onHover(null);
        const container = e.target.getStage().container();
        container.style.cursor = "";
      }}
      onClick={e => {
        if (!props.isGroupEditing) {
          return;
        }
        e.cancelBubble = true;
        let current = [e.currentTarget];
        let data = false;
        if (!props.uniqueSeat) {
          const exists = props.selectedSeats && props.selectedSeats.find((seat) => seat.attrs.id === props.data.markus.id);
          if (exists && e.evt.shiftKey) {
            current = []
          }
          if (e.evt.shiftKey) {
            if (exists) {
              current = props.selectedSeats && props.selectedSeats.filter((seat) => seat.attrs.id !== props.data.markus.id);
            } else if (props.selectedSeats){
              current = current.concat(props.selectedSeats)
            }
          }
        } else {
          data = {...props.data};
        }
        const currentIds = current.map((seat) => seat.attrs.id);
        props.onSelect && props.onSelect(current, currentIds, data);
      }}
      onTap={e => {
        if (!props.isGroupEditing) {
          return;
        }
        let current = [e.currentTarget];
        const exists = props.selectedSeats && props.selectedSeats.find((seat) => seat.attrs.id === props.data.markus.id);
        if (exists) {
          current = props.selectedSeats && props.selectedSeats.filter((seat) => seat.attrs.id !== props.data.markus.id);
        } else if (props.selectedSeats) {
          current =current.concat(props.selectedSeats)
        }
        const currentIds = current.map((seat) => seat.attrs.id);
        props.onSelect && props.onSelect(current, currentIds);
      }}
    >
        <Path
            x={0}
            y={0}
            data={seatPath}
            fill={getColor(props.data, props.isSelected)}
            opacity={(props.data.markus.seatStatus === 'reserved' || props.data.markus.seatStatus === 'booked') && props.data.markus.fillColor ? .3 : 1}
        />
        <Path
            x={0}
            y={0}
            data={seatPath}
            fill={'transparent'}
            stroke={getColorStroke(props.data, props.isSelected)}
            strokeWidth={3}
        />
        <Path
            x={0}
            y={0}
            data={seatUnderNumberPath}
            stroke={getColorStroke(props.data, props.isSelected)}
            strokeWidth={2} />

        <Label align="center" y={10} x={0} fill={'#7151F2'}>
          <Text 
              fill={textColor}
              fontSize={10}
              fontFamily={'Arial'}
              text={props.data.markus.place}
              fontStyle={600}
              padding={2}
              width={seatWidth}
              align="center" />
        </Label>

        {props.data && props.data.markus && props.data.markus.attendee &&
          <Label align="center" y={28} x={0}>
            <Tag></Tag>
            <Text text={props.data.markus.attendee.name}
              align={`center`} 
              width={seatWidth}
              ellipsis={true}
              padding={2}
              fontFamily={'Arial'}
              fontSize={8}
              fontStyle={600}
              wrap={`none`}></Text>
            <Text text={props.data.markus.attendee.firstname}
              align={`center`} 
              width={seatWidth}
              ellipsis={true}
              padding={2}
              y={10}
              fontFamily={'Arial'}
              fontSize={8}
              wrap={`none`}></Text>
          </Label>
        }
        {props.data && props.data.markus && props.data.markus.typeSeat &&
          <Label align="center" y={62} x={0}>
            <Tag fill={getColorStroke(props.data, props.isSelected)}></Tag>
            <Text text={props.data.markus.typeSeat}
              align={`center`} 
              ellipsis={true}
              width={seatWidth}
              padding={4}
              fontFamily={'Arial'}
              fontSize={8}
              fontStyle={800}
              wrap={`none`}></Text>
          </Label>
        }
    </Group>
    {props.isSelected && (
      <Transformer
        ref={trRef}
        resizeEnabled={false}
        rotateEnabled={false}
        borderStroke={getColorStroke(props.data, props.isSelected)}
        borderStrokeWidth={3}
        borderDash={[3, 3]}
        boundBoxFunc={(oldBox, newBox) => {
          return oldBox;
        }}
      />
    )}
    </>
  );
};

export default Seat;