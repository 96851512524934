import React, { useState, useEffect, useRef } from "react";
import { Tooltip } from 'react-tooltip';
import { Stage, Layer, Group } from 'react-konva';
import Konva from 'konva';
import { zoomHigh, zoomLow, zoomIncrement } from 'constants.js';
import {invertColor} from "lib/invertColor"
import Loading from "components/loading";
import ShowHelp from "components/infos/showHelp";
import SeatInfoAttendeeVisu from "components/infos/seatInfoAttendeeVisu";
import BackLinkButton from 'assets/backLinkButton.svg';
import ZoomIn from 'assets/zoomIn.svg';
import ZoomOut from 'assets/zoomOut.svg';
import Center from 'assets/center.svg';
import Stand from 'components/konva/Stand';
import Table from 'components/konva/Table';
import "App.css"; 

export default function Visualization(props) {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ seatInfo, setSeatInfo ] = useState(false);
  const [ seatInfoPerson, setSeatInfoPerson ] = useState(false);
  const [ seatInfosStyles, setSeatInfosStyles ] = useState({});
  const [ documentName, setDocumentName ] = useState('My new plan');
  const [ selectedSeatsIds, setSelectedSeatsIds ] = useState([])
  const [ stageX, setStageX ] = useState(0)
  const [ stageY, setStageY ] = useState(0)

  const [ help ] = useState('Please select an attendee in the left column or in the schema.');

  const [ searchAttendee, setSearchAttendee ] = useState('');
  const [ attendeeSelected, setAttendeeSelected ] = useState('');
  const [ assignedPlaces, setAssignedPlaces ] = useState([]);
  const [ zoomLevel, setZoomLevel ] = useState(1);
  const [ plan, setPlan ] = useState({objects:{stands: [], tables: []}, title: 'My new plan'});

  const stageRef = useRef();
  const layerRef = useRef();
  const groupRef = useRef();

  var lastDist = 0;

  useEffect(() => {
    // INIT
    _onReady()
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const reCenter = () => {
    setZoomLevel(1);
    stageRef.current.scale({ x: 1, y: 1 });
    const groupPosition = groupRef.current.getClientRect();
    var pointTo = {
      x: (stageRef.current.x() - groupPosition.x + (getWidth()/2) - (groupPosition.width / 2)),
      y: (stageRef.current.y() - groupPosition.y+ (getHeight()/2) - (groupPosition.height / 2))
    };
    stageRef.current.position(pointTo);
    if(selectedSeatsIds.length === 1) {
      const current = stageRef.current.find('#'+selectedSeatsIds[0]);
      if (current && current.length === 1) {
        const position = getAbsolutePosition_konva(current[0]);
        setSeatInfosStyles(position);
      }
    }
  }

  useEffect(() => {
    if (!isLoading) {
      reCenter();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleWheel = (e) => {
    e.evt.preventDefault();
    setZoom(e.target.getStage(), e.evt.deltaY, true, e.evt)
  }
  const handleTouchEnd = (e) => {
    lastDist = 0;
  }
  const handleTouchMove = (e) => {
    var touch1 = e.evt.touches[0];
    var touch2 = e.evt.touches[1];
    if (touch1 && touch2) {
      e.evt.preventDefault();
      const stage = e.target.getStage();
      if (stage.isDragging()) {
        stage.stopDrag();
      }
      var dist = touch1.clientX - touch2.clientX;
      if (touch1.clientX < touch2.clientX) {
        dist = touch2.clientX - touch1.clientX;
      }
      if (!lastDist) {
        lastDist = dist;
      }
      if (dist === lastDist )
        return;
      setZoom(e.target.getStage(), dist > lastDist ? 1 : 0, true, e.evt);
      lastDist = dist;
    } else {
      lastDist = 0;
    }
  }

  const handleResize = () => {
      stageRef.current.height(getHeight());
      stageRef.current.width(getWidth());
  }

  useEffect(() => {
    Konva.hitOnDragEnabled = true;
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const get_all_seats = (planLoaded) => {
    if (!planLoaded) {
      planLoaded = plan;
    }
    let AllSeats = [];
    planLoaded.objects.stands.map((stand) => {
      AllSeats = AllSeats.concat(stand.items);
      return stand.items;
    });
    planLoaded.objects.tables.map((table) => {
      AllSeats = AllSeats.concat(table.items);
      return table.items;
    });
    AllSeats = AllSeats.length > 0 ? AllSeats.filter((seat) => seat.typeObject === 'seat') : AllSeats;
    return AllSeats;
  }

  const getHeight = () => {
    return window.innerHeight;
  }

  const getWidth = () => {
    var list_width = document.querySelector('#list-persons') ? document.querySelector('#list-persons').offsetWidth : 0;
    var available_width = window.innerWidth - list_width;
    return available_width;
  }

  const setZoom = (stage, increment, zoomToPointer = false, evt) => {
    var scaleBy = zoomIncrement;
    var oldScale = stage.scaleX();
  
    // how to scale? Zoom in? Or zoom out?
    let direction = increment  > 0 ? 1 : -1;

    // when we zoom on trackpad, e.evt.ctrlKey is true
    // in that case lets revert direction
    if (evt && evt.ctrlKey) {
      direction = -direction;
    }

    var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    if (newScale > zoomHigh || newScale < zoomLow)
      return;
    
    stage.scale({ x: newScale, y: newScale });

    if (zoomToPointer) {
      var pointer = stage.getPointerPosition();

      var mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };
      var newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      stage.position(newPos);
    }
    setZoomLevel(newScale);
    setSeatInfosStyles({})
  }
  const zoomPlus = () => {
    setZoom(stageRef.current, 10, false)
  }

  const zoomMoins = () => {
    setZoom(stageRef.current, -10, false);
  }

  const onKeyDown = (e) => {
    if (e.target && e.target.nodeName === 'INPUT') {
      return
    }
    if (e.key === '+') {
      zoomPlus()
      return false;
    }
    if (e.key === '-') {
      zoomMoins()
      return false;
    }
  }

  const _onReady = () => {
    var plan = localStorage.getItem('plan_konva');
    if (props.planToLoad) {
      plan = props.planToLoad;
    }
    if (plan !== null) {
      try {
        if (typeof plan !== 'object') {
          plan = JSON.parse(plan);
        }
        setPlan(plan);
        if (plan.title) {
          setDocumentName(plan.title)
        }
        var places = JSON.parse(localStorage.getItem('assignedPlaces_konva')) || [];
        if (props.assignedPlaces) {
          places = props.assignedPlaces;
        }
        if (places.assigned) {
          places = places.assigned;
        }
        if (props.attendantsArray) {
          let AllSeats = get_all_seats(plan);
          props.attendantsArray.filter((attendee) => attendee.seat_id).map((attendee) => {
            const newUser = {
              user_id: attendee.id,
              seat_id: attendee.seat_id,
            }
            
            let seatName = AllSeats.filter((seat)=> seat.typeObject === 'seat').find((seat) => seat.markus.id === newUser.seat_id);
            if (seatName) {
              newUser.seat_name = seatName.markus.place;
            }
            places = places.filter((place) => place.user_id !== attendee.id);
            places.push(newUser);
            return newUser;
          });
        }
        if (places) {
          setAssignedPlaces(places);
        }

        let newPlan = plan;
        newPlan.objects.stands = plan.objects.stands.map((stand) => {
          stand.items = stand.items.map((item) => {
            if (item.typeObject === 'seat'){
              if (places) {
                const is_assigned = places.filter((place) => place.seat_id === item.markus.id);
                if (is_assigned && is_assigned.length > 0) {
                  if (!(item.typeObject === 'seat' && item.markus.seatStatus && item.markus.seatStatus === 'reserved')) {
                    item.markus.seatStatus = 'booked';
                  }
                  const user_id = is_assigned[0].user_id;
                  const attendee = props.attendantsArray && props.attendantsArray.find((attendee) => attendee.id === user_id);
                  if (props.attendantsCategories && attendee && !(item.typeObject === 'seat' && item.markus.seatStatus && item.markus.seatStatus === 'reserved')) {
                    const categorie = getCat(attendee.category_id);
                    if (categorie.color) {
                      item.markus.fillColor = categorie.color;
                      item.markus.lineColor = invertColor(categorie.color, true);
                      item.markus.textColor = invertColor(categorie.color, true);
                    }
                  }
                  item.markus.attendee = attendee;
                  item.markus.hoverCursor = 'pointer'
                } else {
                  item.markus.hoverCursor = 'not-allowed';
                }
              }
            }
            if (item.typeObject === 'seat' && item.markus.seatStatus && item.markus.seatStatus === 'reserved') {
              if (!item.markus.attendee) {
                item.markus.hoverCursor = 'not-allowed';
              }
            }
            return item;
          });
          return stand;
        });
        newPlan.objects.tables = plan.objects.tables.map((table) => {
          table.items = table.items.map((item) => {
            if (item.typeObject === 'seat'){
              if (places) {
                const is_assigned = places.filter((place) => place.seat_id === item.markus.id);
                if (is_assigned && is_assigned.length > 0) {
                  if (!(item.typeObject === 'seat' && item.markus.seatStatus && item.markus.seatStatus === 'reserved')) {
                    item.markus.seatStatus = 'booked';
                  }
                  const user_id = is_assigned[0].user_id;
                  const attendee = props.attendantsArray && props.attendantsArray.find((attendee) => attendee.id === user_id);
                  if (props.attendantsCategories && attendee && !(item.typeObject === 'seat' && item.markus.seatStatus && item.markus.seatStatus === 'reserved')) {
                    const categorie = getCat(attendee.category_id);
                    if (categorie.color) {
                      item.markus.fillColor = categorie.color;
                      item.markus.lineColor = invertColor(categorie.color, true);
                      item.markus.textColor = invertColor(categorie.color, true);
                    }
                  }
                  item.markus.attendee = attendee;
                  item.markus.hoverCursor = 'pointer'
                } else {
                  item.markus.hoverCursor = 'not-allowed';
                }
              }
            }
            if (item.typeObject === 'seat' && item.markus.seatStatus && item.markus.seatStatus === 'reserved') {
              if (!item.markus.attendee) {
                item.markus.hoverCursor = 'not-allowed';
              }
            }
            return item;
          });
          return table;
        });
        setPlan({...newPlan})
        setTimeout(function(){setIsLoading(false)}, 500);
      } catch(e) {
        console.log(e);
        setTimeout(function(){setIsLoading(false)}, 500);
      }
    }
  }

  const getCat = (category_id) => {
    let categorie = props.attendantsCategories.find((cat) => cat.id === category_id);
    if (!categorie) {
      categorie = {id: 0, name: 'N/A', alias: 'N/A', color: '#ccc'};
    }
    if (!categorie.name) {
      categorie.name = 'N/A';
    }
    return categorie;
  }

  const getAbsolutePosition_konva = (obj) => {
    if (!obj) {
      return;
    }
    const position = obj.absolutePosition();
    const rect = obj.getClientRect();
    let max = 250;
    if (position) {
      const styles = {
        left: position.x+(rect.width / 2),
        top: position.y-10,
      };
      if (styles.top < max+(window.scrollY || 0)) {
        styles.top = position.y;
        styles.transform = 'translate(-50%, 0px)';
        if ((obj.markus && obj.markus.type === 'seat') || (obj.attrs && obj.attrs.name === 'seat')) {
          styles.marginTop = '50px';
        }
      }
      return styles;
    } else {
      return false;
    }
  }

  const getPlaceUser = (attendee) => {
    let placeUser = false;
    if (attendee && Array.isArray(assignedPlaces)) {
      placeUser = assignedPlaces.find((place) => place.user_id === attendee);
      if (placeUser) {
        let seat = stageRef.current.find('#'+placeUser.seat_id)
        if (seat.length === 1) {
          placeUser = seat[0];
        } else {
          placeUser = false;
        }
      } else {
        placeUser = false;
      }
    }
    return placeUser;
  }

  const setAttendee = (id) => {
    const attendee = id === attendeeSelected ? '' : id;
    setAttendeeSelected(attendee); 
    let attendent = props.attendantsArray.find((attendent) => attendent.id === attendee);
    if (attendee && Array.isArray(assignedPlaces)) {
      const placeUser = getPlaceUser(attendee);
      if (placeUser) {
        const rectPosition = placeUser.getClientRect();
        var scale = stageRef.current.scaleX();
        var pointTo = {
          x: (stageRef.current.x() - rectPosition.x + (getWidth()/2)) / scale,
          y: (stageRef.current.y() - rectPosition.y+ (getHeight()/2)) / scale
        };
        stageRef.current.position(pointTo);
        setSelectedSeatsIds([placeUser.attrs.id]);
        setSeatInfoPerson(attendent);
        setSeatInfosStyles(getAbsolutePosition_konva(placeUser));
        let AllSeats = get_all_seats();
        const data = AllSeats.find((seat) => seat.typeObject === 'seat' && seat.markus.id === placeUser.attrs.id)
        setSeatInfo(data);
      } else {
        setSeatInfoPerson(false);
        setSeatInfo(false);
        setSeatInfosStyles({});
      }
    } else {
      setSeatInfoPerson(false);
      setSeatInfo(false);
      setSeatInfosStyles({});
    }
  }

  const handleDragMove = e => {
    setStageX(e.target.x());
    setStageY(e.target.y());
    followPopup();
  };

  const followPopup = () => {
    if(selectedSeatsIds.length === 1) {
      const current = stageRef.current.find('#'+selectedSeatsIds[0]);
      if (current && current.length === 1) {
        const position = getAbsolutePosition_konva(current[0]);
        setSeatInfosStyles(position);
      }
    }
  }

  const renderListPersonnes = () => {
    const { attendantsArray } = props;
    const places = get_all_seats();

    if (!attendantsArray || attendantsArray.length === 0) {
      return null;
    }

    let attendants = [...new Set(attendantsArray.map(item => item))]; // [ 'A', 'B']
    attendants = attendants.map((attendant) => {
      if (!attendants.name_child) {
        attendant.name_child = attendant.name+' '+attendant.firstname;
      }
      attendant.name_parent = attendant.name+' '+attendant.firstname;
      if (attendant.parent !== 0) {
        const parent = attendants.find((att) => att.id === attendant.parent);
        const parentIndex = attendants.findIndex((att) => att.id === attendant.parent);
        if (parent) {
          attendant.name_parent = parent.name+' '+parent.firstname;
          attendants[parentIndex].name_child = attendant.name+' '+attendant.firstname;
        }
        
      }
      return attendant;
    })
    return attendants
      .sort((a,b) => a.name_parent.toLowerCase().localeCompare(b.name_parent.toLowerCase()) || a.parent - b.parent)
      .filter((attendee) => {
        if (!assignedPlaces.find((place) => place.user_id === attendee.id)) {
            return false;
        }
        if (searchAttendee) {
          var regex = new RegExp( searchAttendee.toLowerCase(), 'g' );
          return (attendee.firstname && attendee.firstname.toLowerCase().match(regex)) || 
            (attendee.name && attendee.name.toLowerCase().match(regex)) || 
            (attendee.name_parent && attendee.name_parent.toLowerCase().match(regex)) || 
            (attendee.name_child && attendee.name_child.toLowerCase().match(regex)) || 
            (attendee.entity && attendee.entity.toLowerCase().match(regex));
        }
        return true;
      })
      .map((attendee, index) => {
        let classes = "grid grid-cols-6 gap-1 p-1 text-sm hover:bg-violet-940/10 cursor-pointer";
        let classesCheck = "rounded-full border-2 ml-2 text-center";
        let colorCheck = '#DBDADC';
        let classText = 'text-gray-300 '
        let placeUser = false;
        let placeFullUser = false;
        if (Array.isArray(assignedPlaces)) {
          placeUser = assignedPlaces.find((place) => place.user_id === attendee.id);
          if (placeUser) {
            placeFullUser = places.find((place) => place.markus.id === placeUser.seat_id && place.markus.type === 'seat');
            colorCheck = '#ffffff';
            classText = 'text-white ';
            classesCheck += " border-violet-940/20 bg-violet-940 border-3";
          }
        }

        if (attendeeSelected && attendeeSelected === attendee.id) {
          classes += " bg-violet-940/20";
          if (!placeUser) {
            classesCheck += " border-white bg-white/20";
            classText = 'text-white ';
            colorCheck = '#ffffff';
          }
        } else if (!placeUser){
          classesCheck += "  border[#DBDADC]";
        }

        const categorie = getCat(attendee.category_id);
        return (
          <div key={`attendee_`+attendee.id} className={classes} onClick={(e) => setAttendee(attendee.id)}>
            <div className="col-span-2 row-span-2 self-center text-xs">
              {!attendee.comment && <span>{attendee.parent > 0 ? '↳ ' : ''}{attendee.firstname + ' '+ attendee.name}</span>}
              {attendee.comment && // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <abbr
                  data-tooltip-id="tooltip-comment"
                  data-tooltip-content={attendee.comment}
                  data-tooltip-place="top"
                >{attendee.parent > 0 ? '↳ ' : ''}{attendee.firstname + ' '+ attendee.name}<sup className={classText+classesCheck +' w-6'}>?</sup></abbr>}
            </div>
            <div className="whitespace-nowrap text-xs self-center text-ellipsis overflow-hidden">
              <div className="rounded-full inline-block mr-1" style={{backgroundColor: categorie.color, width: '10px', height: '10px'}}></div>{categorie.name.replace('_', ' ')}
            </div>
            <div className="whitespace-nowrap text-xs self-center text-ellipsis overflow-hidden">
              <span className="p-2 pt-1 pb-1 text-[#98979A] rounded-sm bg-[#F8F7FA] inline-block whitespace-nowrap text-xs self-center text-ellipsis overflow-hidden">{attendee.confirmation_status ? attendee.confirmation_status[0].toUpperCase() + attendee.confirmation_status.slice(1) : `-`}</span>
            </div>
            <div className={props.types && props.types.length > 0 ? "grid grid-flow-col col-span-2 row-span-2 text-xs self-center text-right items-center justify-items-stretch" : "grid grid-flow-col col-span-2 row-span-2 text-xs w-3/12 self-center text-right items-center justify-items-stretch"}>
              <div className={classesCheck} style={{width:'20px', height: '20px'}}>
                <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginTop: '5px',marginLeft: '3px'}}>
                  <path d="M8.33268 1L3.74935 5.58333L1.66602 3.5" stroke={colorCheck} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
              <div>
                {placeFullUser && placeFullUser.markus.standName ? placeFullUser.markus.standName+' - ' : ''}
                {placeFullUser && placeFullUser.markus.tableName ? placeFullUser.markus.tableName+' - ' : ''}
                {placeUser && placeUser.seat_name ? placeUser.seat_name : '-'}
                {placeFullUser && placeFullUser.markus.typeSeat ? ' ('+placeFullUser.markus.typeSeat+')' : ''}
              </div>
            </div>
            {props.types &&
            <div className="col-start-3 whitespace-nowrap text-xs self-center text-ellipsis overflow-hidden" title={`Attendee entity`}>
              {attendee.entity || '-'}
            </div>}
            {props.types &&
            <div className="col-start-4 whitespace-nowrap text-xs self-center text-ellipsis overflow-hidden" title={`Attendee type`}>
              {attendee.type || '-'}
            </div>}
          </div>
        );
    });
  }

  return (
    <div className="seat-planner-app text-sm 2xl:text-base" id="mode-visualizaton" tabIndex={0} onKeyDown={onKeyDown}>
      <Tooltip id="tooltip-comment" />
      <Loading visible={isLoading} />
      <div className="flex">
        <div className="relative w-3/12 bg-white border-r border-gray-300 max-h-screen overflow-y-scroll" id="list-persons">
          <header className="p-2">
            <div className="flex items-center justify-between w-full">
              <div>
                {props.backLink &&
                <a
                  href={props.backLink}
                  rel="noreferer"
                  className="inline-block rounded-md border border-gray-300 shadow-sm p-3 leading-4 bg-white text-gray-700 hover:bg-violet-940  hover:text-white focus:outline-none align-bottom">
                  <img src={BackLinkButton} alt="Back" />
                </a>}
              </div>
              <div className="truncate font-bold" style={{'maxWidth': 'calc(100% - 175px)'}}>
                  {documentName}
              </div>
              <div>
              </div>
            </div>
          </header>
          <div className="p-2">
            {props.attendantsArray && props.attendantsArray.length > 0 &&
            <div>
              <div>
                <input type="search" className="
                  block
                  w-full
                  rounded-md
                  border-gray-300
                  shadow-sm
                  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  value={searchAttendee || ''}
                  placeholder={`Search an attendee`}
                  onChange={(e) => setSearchAttendee(e.target.value)} />
              </div>
              <div className="list">
                {renderListPersonnes()}
              </div>
            </div>
            }
          </div>
        </div>
        <div className="relative w-9/12">
          <div className="fixed z-10 bg-white m-6 p-2 bottom-0 right-0  border border-gray-200 rounded-md shadow-gray-500 shadow-2xl">
            <div
              title="Actual zoom level"
              className={"block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium cursor-default text-gray-700 text-center text-xs mt-0 sm:m-1 sm:w-auto sm:text-xs"}>
              {Math.round((zoomLevel)*10)/10}
            </div>
            <button 
              type="button"
              onClick={reCenter}
              className={"block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium text-gray-700 hover:bg-violet-940 hover:border-violet-940 hover:text-white mt-0 sm:m-1 sm:w-auto sm:text-sm"}>
                <img src={Center} alt="Recenter" />
            </button>
            <button 
              type="button"
              onClick={zoomPlus}
              className={zoomLevel < zoomHigh ? "block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium text-gray-700 hover:bg-violet-940 hover:border-violet-940 hover:text-white mt-0 sm:m-1 sm:w-auto sm:text-sm" : "block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium cursor-default mt-0"}>
                <img src={ZoomIn} alt="Zoom in" className={zoomLevel < zoomHigh ? '' : 'opacity-25'} />
            </button>
            <button 
              type="button"
              onClick={zoomMoins}
              className={zoomLevel > zoomLow ? "block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium text-gray-700 hover:bg-violet-940 hover:border-violet-940 hover:text-white mt-0 sm:m-1 sm:w-auto sm:text-sm" : "block m-1 rounded-md border border-gray-300 shadow-sm p-3 bg-white font-medium cursor-default mt-0"}>
                <img src={ZoomOut} alt="Zoom out" className={zoomLevel > zoomLow ? '' : 'opacity-25'} />
            </button>
        </div>
        {help && <ShowHelp help={help} />}
          {seatInfo && seatInfo.markus && 
           <SeatInfoAttendeeVisu
              infos={seatInfo.markus} 
              attendeeSelected={attendeeSelected}
              styles={seatInfosStyles}
              infoPerson={seatInfoPerson}
              attendantsCategories={props.attendantsCategories} />}
        <Stage width={getWidth()} height={getHeight()} 
            ref={stageRef}
            onClick={(e) => {
              setSeatInfoPerson(false);
              setSelectedSeatsIds([]);
              setSeatInfo(false);
              setSeatInfosStyles({});
              setAttendeeSelected(''); 
            }}
            onWheel={handleWheel}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            draggable={true}
            onDragMove={handleDragMove}
            x={stageX}
            y={stageY}>
          {!isLoading && plan && (plan.objects.stands.length > 0 || plan.objects.tables.length > 0) &&
          <Layer ref={layerRef}>
            <Group ref={groupRef}>
            {plan.objects.stands.map((stand, index) => {
              return (
                <Stand
                  x={stand.x || 200}
                  y={stand.y || 200}
                  key={'stand_'+index}
                  data={stand}
                  isEditing={true}
                  noControl={true}
                  selectedSeatsIds={selectedSeatsIds}
                  mode={'visualization'}
                  onSelectSeat={(current, selectedSeats, data) => {
                    if (data && (data.markus.seatStatus === 'booked' || (data.markus.seatStatus === 'reserved'&& data.markus.attendee))) {
                      const rectPosition = current[0].getClientRect();
                      var pointTo = {
                        x: (stageRef.current.x() - rectPosition.x + (getWidth()/2)),
                        y: (stageRef.current.y() - rectPosition.y+ (getHeight()/2))
                      };
                      stageRef.current.position(pointTo);
                      const position = getAbsolutePosition_konva(current[0]);
                      let place = assignedPlaces.find((place) => place.seat_id === data.markus.id);
                      let attendent = false;
                      if (place) {
                        attendent = props.attendantsArray.find((attendent) => attendent.id === place.user_id);
                      }
                      setSeatInfoPerson(attendent);
                      setSelectedSeatsIds(selectedSeats);
                      setSeatInfo(data);
                      setSeatInfosStyles(position);
                      setAttendeeSelected(place.user_id); 
                    }
                  }}
                  uniqueSeat={true}
                />
              );
          })}
          {plan.objects.tables.map((table, index) => {
              return (
                <Table
                  x={table.x || 200}
                  y={table.y || 200}
                  key={'table_'+index}
                  data={table}
                  isEditing={true}
                  noControl={true}
                  selectedSeatsIds={selectedSeatsIds}
                  mode={'visualization'}
                  onSelectSeat={(current, selectedSeats, data) => {
                    if (data && (data.markus.seatStatus === 'booked' || (data.markus.seatStatus === 'reserved'&& data.markus.attendee))) {
                      const rectPosition = current[0].getClientRect();
                      var pointTo = {
                        x: (stageRef.current.x() - rectPosition.x + (getWidth()/2)),
                        y: (stageRef.current.y() - rectPosition.y+ (getHeight()/2))
                      };
                      stageRef.current.position(pointTo);
                      const position = getAbsolutePosition_konva(current[0]);
                      let place = assignedPlaces.find((place) => place.seat_id === data.markus.id);
                      let attendent = false;
                      if (place) {
                        attendent = props.attendantsArray.find((attendent) => attendent.id === place.user_id);
                      }
                      setSeatInfoPerson(attendent);
                      setSelectedSeatsIds(selectedSeats);
                      setSeatInfo(data);
                      setSeatInfosStyles(position);
                      setAttendeeSelected(place.user_id); 
                    }
                  }}
                  uniqueSeat={true}
                />
              );
          })}
          </Group>
          </Layer>}
        </Stage>
      </div>
    </div>
    </div>
  );
}