import React from "react";
import Editor from "editor";
import Placement from "placement";
import Visualization from "visualization";

import "./App.css";

export default function App(props) {
    const mode = props.mode || 'editor';
    if (mode === 'placement_konva' || (mode === 'placement')) {
        return (
            <Placement {...props} />
        );
    }
    else if (mode === 'visualization_konva' || (mode === 'visualization')) {
        return (
            <Visualization {...props} />
        );
    }
    return (
        <Editor {...props} />
    );
}
