import React from "react";
import editPencil from '../../assets/editPencil.svg';

export default function EditTitle(props) {
    let classes = "hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full"
    if (props && props.visible) {
        classes = "flex items-center justify-center h-screen overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full bg-violet-940/20 cursor-pointer"
    }

    const setTitleName = (value) => {
        return props.editTitle(value);
    }   

    const close = () => {
        return props.close(false)
    }

    const notclose = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const onKeyDown = (e) => {
        if (e.target && e.target.nodeName === 'INPUT') {
          if (e.keyCode === 27 || e.keyCode === 13) {
            close();
          }
        }
      }
    return (
        <div id="modalEditTitle" tabIndex="-1" aria-hidden="true" className={classes} onKeyDown={onKeyDown} onClick={close}>
            <div className="relative p-4 w-full max-w-2xl h-full md:h-auto cursor-auto" onClick={notclose}>
                <div className="relative rounded-lg shadow bg-white">
                    <div className="flex justify-between items-start p-4 rounded-t border-b dark:border-violet-950">
                        <h3 className="text-xl font-semibold text-gray-900">
                            <span className="inline-block align-middle rounded-full bg-violet-940/10 mr-3 p-4 translate-y-50">
                                <img src={editPencil}  alt="" />
                            </span>
                            Edit plan informations
                        </h3>
                        <button type="button" className="text-violet-940 bg-transparent hover:bg-violet-940 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-violet-940 dark:hover:text-white" onClick={close}>
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>  
                        </button>
                    </div>
                    <div className="grid grid-cols-1 gap-6 p-4">
                        <label className="block">
                            <span className="block text-gray-700 translate-y-7 pl-3 text-sm ">Plan title *</span>
                            <input type="text" className="
                                mt-1
                                block
                                w-full
                                rounded-md
                                border-gray-300
                                shadow-sm
                                pt-7
                                focus:border-violet-950 focus:ring focus:ring-violet-950 focus:ring-opacity-80"
                                value={props.actualTitle}
                                onChange={(e) => setTitleName(e.target.value)} />
                        </label>
                        <div className="text-right">
                            <button 
                                type="button" 
                                className="text-white bg-violet-940 hover:bg-violet-940 focus:ring-4 focus:outline-none focus:ring-violet-950 font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={close}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}