import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
import "./index.css";

const widgetName = 'Markus-Seat-Planner';
const widgetConfigName = widgetName + '-Config'
const defaultconfig = {
    targetElementId: 'root',
    backLink: false,
    onSave: false,
    forceToLocalStorage: false,
    planToLoad: false,
    mode: 'creation', // placement, creation
    attendantsCategories: [], // array of categories with id, name, color
    attendantsArray: [], // array of attendants with : first name, last name, place, sub attendants, categoryId, status
    attendantsSource: 'array', // array, utilise attendantsArray comme source, Meilisearch, use, attendantsMeilisearchConfig as source
    attendantsMeilisearchConfig: {
      'host': false,
      'key': false,
      'index': false,
      'filters': false,
    },
    assignedPlaces: false, // false ou un tableau d'objet : [{id: user_id, plance: place_id}.]
};
//let widgetComponent = null;

function app(window) {
  let placeholder = window[window[widgetName]];

  // override temporary (until the app loaded) handler
  // for widget's API calls
  window[window[widgetName]] = apiHandler;
  window[widgetConfigName] = defaultconfig;

  if (placeholder) {
      console.log(`${widgetName} placeholder found`);

      let queue = placeholder.q;
      if (queue) {
          console.log(`${widgetName} placeholder queue found`);
          for (var i = 0; i < queue.length; i++) {
              apiHandler(queue[i][0], queue[i][1]);
          }
      }
  }
}

/**
  Method that handles all API calls
*/
function apiHandler(api, params) {
  if (!api) {
    console.error('API method required');
    return;
  }
  api = api.toLowerCase();
  let config = window[widgetConfigName];

  switch (api) {
      case 'init':
          config = Object.assign({}, config, params);
          window[widgetConfigName] = config;
          // get a reference to the created widget component so we can
          // call methods as needed
          //widgetComponent = React.createRef();
          const rootId = document.getElementById(config.targetElementId);
          if (rootId) {
            const root = ReactDOM.createRoot(rootId);
            root.render(
              <React.StrictMode>
                <App {...config} />
              </React.StrictMode>
            );
          } else {
            console.error('ERROR : element #'+config.targetElementId+' is not available');
          }
          //ReactDOM.render(<App ref={widgetComponent} />, document.getElementById(config.targetElementId));
          break;
      default:
          throw Error(`Method ${api} is not supported`);
  }
}

app(window);

export default app;